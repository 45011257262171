export class DateRequestStatus {
  static readonly REQUESTED: string = "REQUESTED";
  static readonly ACCEPTED: string = "ACCEPTED";
  static readonly WAIT_PAYMENT: string = "WAIT_PAYMENT";
  static readonly PAID: string = "PAID";
  static readonly REJECTED: string = "REJECTED";
  static readonly CANCELED: string = "CANCELED";
  static readonly DONE: string = "DONE";

  static readonly PAYMENT_EXPIRED: string = "PAYMENT_EXPIRED";
  static readonly EXPIRED: string = "EXPIRED"; 
}
