import { FC, useEffect, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { HelpResponse } from "../../model/HelpResponse";
import { HelpCategory } from "../../model/HelpCategory";
import { AccountInfo } from "../../model/AccountInfo";
import { AccountEmail } from "../../model/AccountEmail";
import toast, { Toaster } from "react-hot-toast";

export const InquiryPage: FC = () => {
  const [help, setHelp] = useState<HelpResponse | undefined>(undefined);
  const [category, setCategory] = useState<HelpCategory | undefined>(undefined);
  const [accountEmail, setAccountEmail] = useState(undefined as AccountEmail | undefined);
  const [body, setBody] = useState("");
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);

  async function sendInquiry() {
    if(processing){ return; }
    setProcessing(true);
    const param = {
      body: body,
      category: category?.categoryId ?? "",
      mailAddress: email,
    }
    const response = await postAsync("inquiry/SendInquiry", param);
    if(response.code === 1) {
      toast.success("問い合わせを受け付けました");
      await new Promise(resolve => setTimeout(resolve, 2000));
      window.location.href = "/home?tab=mypage"
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
    setProcessing(false);
  }
  
  async function fetchList() {
    const param = { platform : "ios" };
    const response = await fetchAsync<HelpResponse>("Help/CategoryAndDetails", param);
    setHelp(response);
  }

  async function getEmail() {
    const response = await fetchAsync<AccountEmail>("account/GetEmail", null);
    setAccountEmail(response);
    setEmail(response.email ?? "");
  }

  useEffect(() => {
    fetchList();
    getEmail();
  },[]);

  return <>
    <Toaster />
    <PageHeader title="お問い合わせ" />
    <div className="px-4 pb-8">
      <div className="mt-2">
        ヘルプを読んでも解決しない場合は、以下問い合わせフォームを入力の上、お問い合わせください。 運営事務局にて内容を確認の上、回答を随時お送りさせていただきます。 場合によっては回答まで3営業日程度お頂く場合がございますので、あらかじめご了承ください。
      </div>
      <div className="mt-4 text-lg font-bold">お問い合わせカテゴリ</div>
      <div className="mt-2">
        { help?.categories?.map((e) => 
          <div className="flex pb-2" onClick={(eve) => setCategory(e) } >
            <img src={ e.categoryId === category?.categoryId ? "/images/radio_selected.svg" : "/images/radio_not_selected.svg"}></img>
            <span className="ml-2">{e.categoryName}</span>
          </div>
        )}
      </div>
      <div className="text-lg font-bold mt-4">お問い合わせ内容</div>
      <textarea onChange={(e) => setBody(e.target.value)} value={body} className="w-full border-all rouded-[4px] h-[200px] mt-2 py-2 px-2" placeholder="発生している状況の詳細・ご不明店などをご記入ください。" style={{resize: "none"}}>
      </textarea>
      <div className="text-lg font-bold mt-4">返信用メールアドレス</div>
      <div>
        <input type="text" className="w-full py-2 px-2 border-all rounded-[4px]" value={email} onChange={(e) => setEmail(e.target.value)}></input>
      </div>
      <button disabled={processing} onClick={sendInquiry} className="w-full py-2 rounded-[40px] bg-main mb-8 text-white text-center text-lg font-bold mt-8 hover-button">送信</button>
      <div className="whitespace-pre-line text-sm">
        運営事務局にて内容を確認の上、回答を随時お送りさせていただきます
        場合によっては回答まで3営業日程度お頂く場合がございますので、あらかじめご了承ください。
      </div>
    </div>
  </>
}