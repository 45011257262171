import { FC } from "react";
import { Colors } from "../Colors";

export interface NoItemAreaProps {
  title: string
  description: string
  linkUrl: string
  linkText: string
}

export const NoItemArea: FC<NoItemAreaProps> = ( { title, description, linkUrl, linkText }) => {
  return <>
    <div id="no-item-area" className="text-center mt-6 w-full">
      <div className="text-lg color-dim">{title}</div>
      <div className="text-sm mt-2 color-dim px-4 whitespace-pre-line">{description}</div>
      <a href={linkUrl} className="flex w-full justify-center">
        <div className="mt-6 py-2 px-6 rounded-[4px] font-bold" style={{ border: `1px solid ${Colors.Border}` }}>
          {linkText}
        </div>
      </a>
    </div>
  </>
}