import { FC, useEffect, useState } from "react";
import { DateRequest, DateRequestAndAccount, DateRequestSet } from "../../model/DateRequest";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { getWeekString } from "../../services/DateUtil";
import dayjs from "dayjs";
import { Colors } from "../../Colors";
import { PageHeader } from "../../ui_parts/PageHeader";
import { getAccountId } from "../../services/CookieHandler";
import { NewDateRequestPage } from "../chat/NewDateRequestDialog";
import { AcceptDateRequestManPage } from "../chat/AcceptDateRequestManDialog";
import { AcceptDateRequestWomanPage } from "../chat/AcceptDateRequestWomanDialog";
import { DateRequestStatus } from "../../DateRequestStatus";
import { OptionSelector } from "../../ui_parts/OptionSelector";
import { OptionItem } from "../../model/OptionItem";
import { WarningText } from "../../model/WarningText";
import toast, { Toaster } from "react-hot-toast";
import { IsNullOrEmpty } from "../../services/StringUtil";

export const DateListPage: FC = () => {
  const myAccountId = getAccountId();
  const [futureDateList, setFutureDateList] = useState(undefined as DateRequestAndAccount[] | undefined);
  const [oldDateList, setOldDateList] = useState(undefined as DateRequestAndAccount[] | undefined);
  const [selectedDate, setSelectedDate] = useState(undefined as DateRequestAndAccount | undefined);
  const [acceptDialogVisible, setAcceptDialogVisible] = useState(false);
  const [newDateRequestDialogVisible, setNewDateRequestDialogVisible] = useState(false);
  const [canclConfirmDialogVisible, setCanclConfirmDialogVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('' as string);
  const [cancelReasonVisible, setCancelReasonVisible] = useState(false);
  const [warningText, setWarningText] = useState(undefined as string | undefined);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    fetchDateList();
  },[]);

  async function cancelRequest() {
    if(isSending) { return; }
    setIsSending(true);
    var body = {
      DateRequestId: selectedDate?.dateRequest?.dateRequestId,
      CancelReason: cancelReason,
    }
    var response = await postAsync("DateRequest/CancelRequest", body);
    setIsSending(false);
    if(response.code === 1) {
      setSelectedDate(undefined);
      toast.success("キャンセルしました");
      setCanclConfirmDialogVisible(false);
      fetchDateList();
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  async function fetchDateList() {
    var response = await fetchAsync<DateRequestSet>("DateRequest/GetAllList", null);
    setFutureDateList(response.futureList);
    setOldDateList(response.oldList);
  }

  async function fetchWarningText() {
    setWarningText(undefined);
    var param = {
      DateRequestId: selectedDate?.dateRequest?.dateRequestId!
    };
    var response = await fetchAsync<WarningText>("DateRequest/CheckPenaltyTarget", param);
    setWarningText(response.warning);
  }


  function getStatusText(status: string) : string {
    if (status == DateRequestStatus.REQUESTED) { return "確認待ち";}
    else if (status == DateRequestStatus.ACCEPTED) { return "確定";}
    else if (status == DateRequestStatus.WAIT_PAYMENT) { return "お支払い待ち";}
    else if (status == DateRequestStatus.CANCELED) { return "キャンセル";}
    else if (status == DateRequestStatus.REJECTED) { return "キャンセル"; }
    else if (status == DateRequestStatus.DONE) { return "終了";}
    else if (status == DateRequestStatus.PAID) { return "終了";}
    else { return ""; }
  }

  function onRejectingRequest() {
    setNewDateRequestDialogVisible(true);
  }

  function onAcceptingRequest(){
    setAcceptDialogVisible(true);
  }

  function hideNewDateRequestDialog(isSent: boolean) {
    fetchDateList();
    setNewDateRequestDialogVisible(false);
    setSelectedDate(undefined);
  }

  function hideAcceptDateRequestDialog(isSent: boolean) {
    fetchDateList();
    setAcceptDialogVisible(false);
    setSelectedDate(undefined);
  }

  function getDayOfDateText(request: DateRequest) : string {
    const timeText = DateRequest.getTimeText(request);
    const dayOfdate = dayjs(request.dayOfDate);
    const dayText = dayOfdate.format("M/D");
    return `日時: ${dayText}(${getWeekString(dayOfdate)}) ${timeText}`;
  }

  function selectAccount(accountId: string){
    window.location.href = `/profile?hide-action=1&id=${accountId}`
  }

  function buildCancelConfirmDialog() {
    return <>
      <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
        <div className='dialog bg-white overflow-auto max-h-[500px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
          <div className="text-center pt-6 pb-4 font-bold">キャンセル確認</div>
          <div>お約束の取り消しは必ずお相手にご確認を取るようお願いいたします。</div>
          <div className="mt-2">本当にこのお約束をキャンセルしてもよろしいでしょうか。</div>
          <div className="color-dim text-sm mt-2">キャンセル理由を選択</div>
          { cancelReasonVisible &&
          <OptionSelector title="キャンセル理由" value={cancelReason} optionItems={OptionItem.cancelReasonTypes}
            onCancel={() => setCancelReasonVisible(false)}
            onSelected={(e) => { setCancelReason(e); setCancelReasonVisible(false);} } ></OptionSelector>
          }
          <input className="mt-2 rounded-[2px] w-full py-2 px-2" type="text" style={{ border: `1px solid ${Colors.Dim}`}} placeholder="未選択" readOnly onClick={(e) => setCancelReasonVisible(true)} value={OptionItem.getOptionName(cancelReason, OptionItem.cancelReasonTypes)}></input>
          <div onClick={(e) => cancelRequest()} className="text-center py-1 rounded-[2px] mt-8 mb-4" style={{ border: `1px solid ${Colors.Dim}`}} >約束を取り消す</div>
          <div onClick={(e) => setCanclConfirmDialogVisible(false)} className="text-center py-1 rounded-[2px] mt-4 mb-6 font-bold" style={{ border: `1px solid ${Colors.Main}`, color: Colors.Main}}>閉じる</div>
          { !IsNullOrEmpty(warningText) &&
          <div className='mt-4 text-sm px-2 py-2 mb-4 rounded-[2px]' style={{ backgroundColor: Colors.WarningArea, color: Colors.WarningText }}>
            {warningText}
          </div>
          }
        </div>
        
      </div>
    </>;
  }

  function buildRow(request: DateRequestAndAccount) {
    var isNeedToResponse = request.dateRequest!.senderAccountId != myAccountId && request.dateRequest!.status == "REQUESTED";
    return <>
      <div className="flex text-sm">
        <img onClick={(e) => selectAccount(request.targetAccount!.accountId!)} src={request.targetAccount?.profileImageList![0].url} className="w-[80px] h-[80px] rounded-[40px]" />
        <div className="ml-4" onClick={(e) => setSelectedDate(request)}>
          <div>
            <span>{request.targetAccount?.nickname}</span>
          </div>
          <div>{ getDayOfDateText(request.dateRequest!) }</div>
          { request.dateRequest?.location !== '' &&
          <div>場所: {request.dateRequest?.location}</div>
          }
          <div>ステータス: {getStatusText(request.dateRequest?.status!)}</div>
          { isNeedToResponse &&
            <div className="color-main">
              返信期限: {DateRequest.getExpiryText(request.dateRequest!)}
            </div>
          }
        </div>
      </div>
      <div className="mb-2 mt-2 h-[1px] w-[full]" style={{ backgroundColor: Colors.Border }}></div>
    </>;
  }

  function buildDetailDialog() {
    if(selectedDate === undefined || acceptDialogVisible || newDateRequestDialogVisible) {
      return <></>;
    }

    var dateRequest =  selectedDate.dateRequest!;
    var isCancellable = dateRequest.status == "ACCEPTED" ||
    (dateRequest.status == "REQUESTED" && dateRequest.senderAccountId == myAccountId);

    var isAcceptable = dateRequest.status == "REQUESTED" && dateRequest.senderAccountId != myAccountId;
    
    return <>
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
        <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
          <div>
            <div className="text-center font-bold pt-4 pb-2">デートの内容</div>
            <div className="flex text-sm">
              <img onClick={(e) => selectAccount(selectedDate.targetAccount?.accountId!)} src={selectedDate.targetAccount?.profileImageList![0].url} className="w-[80px] h-[80px] rounded-[40px]" />
              <div className="ml-4">
                <div>
                  <span>{selectedDate.targetAccount?.nickname}</span>
                </div>
                <div>{ getDayOfDateText(selectedDate.dateRequest!) }</div>
                { selectedDate.dateRequest?.location !== '' &&
                <div>場所: {selectedDate.dateRequest?.location}</div>
                }
                <div>ステータス: {getStatusText(selectedDate.dateRequest?.status!)}</div>
              </div>
            </div>
            { isCancellable &&
              <div className="color-dim text-center mt-2" onClick={(e) => {setCanclConfirmDialogVisible(true); fetchWarningText(); }} >
                約束を取り消す
              </div>
            }
            { isAcceptable &&
              <div className='flex w-full justify-evenly py-2 mt-2' style={{ borderTop: `1px solid ${Colors.Border}` }}>
                <div className='flex items-center' onClick={(e) => onRejectingRequest()}>
                  <span color='color-dim'>変更</span>
                  <img src="/images/close-gray.svg" className='w-[22px]'></img>
                </div>
                <div className='w-[1px] bg-gray-300 h-[32px]'></div>
                <div className='flex items-center' onClick={(e) => onAcceptingRequest()}>
                  <span className='color-main'>OK</span>
                  <img src="/images/icon/icons8-love-main-48.svg" className='w-[22px]'></img>
                </div>
              </div>
            }
            <div onClick={(e) => setSelectedDate(undefined)} className="text-center py-1 rounded-[2px] mt-4 mb-4 font-bold" style={{ border: `1px solid ${Colors.Main}`, color: Colors.Main}}>閉じる</div>
          </div>
        </div>
      </div>
    </>;
  }

  return <>
    <Toaster></Toaster>
    <PageHeader title="デートの約束"></PageHeader>
    { newDateRequestDialogVisible &&
      <NewDateRequestPage 
        chatRoomId={selectedDate?.dateRequest?.chatRoomId!}
        targetAccount={selectedDate?.targetAccount!}
        onBack={(e) => hideNewDateRequestDialog(e)}
        rejectindDateRequestId={selectedDate?.dateRequest?.dateRequestId!} />
    }
    { canclConfirmDialogVisible &&
      buildCancelConfirmDialog()
    }
    { (acceptDialogVisible && selectedDate?.targetAccount?.gender === 'woman') &&
      <AcceptDateRequestManPage targetAccount={selectedDate.targetAccount} request={selectedDate.dateRequest!} onBack={(e) => hideAcceptDateRequestDialog(e)} />
    }
    { (acceptDialogVisible && selectedDate?.targetAccount?.gender === 'man') &&
      <AcceptDateRequestWomanPage targetAccount={selectedDate.targetAccount} request={selectedDate.dateRequest!} onBack={(e) => hideAcceptDateRequestDialog(e)} />
    }
    { futureDateList === undefined &&
      <ProgressIndicator />
    }
    { (selectedDate !== undefined && !canclConfirmDialogVisible) &&
      buildDetailDialog()
    }
    { futureDateList !== undefined &&
      <>
      <div className="px-4">
        <div className="color-main text-lg mb-2">これからのデート</div>
        { futureDateList.map((e) => 
          buildRow(e)
        )}
      </div>
      { futureDateList?.length === 0 &&
        <div className="ml-4">これからのデートは現在ありません。</div>
      }
      </>
    }
    { oldDateList !== undefined &&
      <>
      <div className="px-4 mt-4">
        <div className="text-lg mb-2">過去のデート</div>
        { oldDateList.map((e) => 
          buildRow(e)
        )}
      </div>
      { oldDateList?.length === 0 &&
        <div className="ml-4">過去のデートはありません。</div>
      }
      </>
    }
  </>
}



