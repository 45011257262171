import { FC, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { fetchAsync, postWithoutSign } from "../../services/ApiHandler";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router";
import { LineAuthResponse } from "../../model/LineAuthResult";
import { saveApiKey, setIsProfileComplete } from "../../services/CookieHandler";
import { AccountInfo } from "../../model/AccountInfo";
import Cookies from "js-cookie";

export const EmailConfirmPage: FC = () => {
  const [code, setCode] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const email = Cookies.get('email_signin');
  const [processing, setProcessing] = useState(false);
  
  async function confirm() {
    if(processing) {
      return;
    }

    setProcessing(true);
    const param = { token: code, email: email, utmSource: Cookies.get('utm_source') ?? "" };
    const response = await postWithoutSign<LineAuthResponse>("Account/VerifyTokenForWeb", param);
    setProcessing(false);
    if(response.code !== 1 && response.code !== 10002) {
      toast.error(response.message!);
      return;
    } else {
      const data = response.data!;
      saveApiKey(data.key!, data.apiSecret!, data.accountId!);
      var accountInfo = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
      if (accountInfo!.isProfileComplete == 1) {
        setIsProfileComplete();
        window.location.href = '/home';
      } else {
        window.location.href = "/register-account";
      }
    }
  }

  return <>
  <PageHeader title=""></PageHeader>
  <Toaster></Toaster>
  <div className="px-6">
    <div className="text-lg font-bold">6桁の認証コードを入力してください</div>
    <div className="mt-2">認証コードをメールアドレスにお送りしました。届いたコードを入力してください。</div>
    <div className="mt-4">
      <input onChange={(e) => setCode(e.target.value)} type="text" className="py-3 px-2 border-all w-full rounded-[4px]" placeholder="6桁の数字" value={code}></input>
    </div>
    <button disabled={processing} onClick={confirm} className="text-center w-full rounded-[24px] bg-main text-white font-bold mt-4 py-2 hover-button">次へ</button>
  </div>
  </>
}