import { AccountEmail } from "../model/AccountEmail";
import { fetchAsync } from "./ApiHandler";
import { getAccountId } from "./CookieHandler";

export async function openTelecomCardPage() {
  const response = await fetchAsync<AccountEmail>("account/GetEmail", null);
  const clientip = "31330";
  const accountId = getAccountId().replaceAll("rpd_", "");
  const origin = window.location.origin; //
  const redirectUrl = `${origin}/card-complete`;
  var url = `https://secure.telecomcredit.co.jp/inetcredit/adult/order.pl?clientip=${clientip}&sendid=${accountId}&redirect_url=${redirectUrl}&usrmail=${response.email}`;
  window.location.href = url;
}