import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import { SearchTab } from "./homeTab/SearchTab";
import { Colors } from "../Colors";
import { MyPageTab } from "./homeTab/MyPageTab";
import { ChatListTab } from "./homeTab/ChatListTab";
import { MatchingTab } from "./homeTab/MatchingTab";
import { GoodTab } from "./homeTab/GoodTab";
import { BadgeCount } from "../model/BadgeCount";
import { fetchAsync } from "../services/ApiHandler";
import { BonusResponse } from "../model/BonusResponse";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { AccountInfo } from "../model/AccountInfo";
import { ProfileDialog } from "./ProfileDialog";
import { PickupDialog } from "./PickupDialog";

export const HomePage: FC = () => {
  let navigate = useNavigate();
  const [goodCount, setGoodCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [matchCount, setMatchCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [bonusGoodCount, setBonusGoodCount] = useState(0);
  const [bonusDialogVisible, setBonusDialogVisible] = useState(false);
  const [pickupList, setPickupList] = useState<AccountInfo[] | undefined>(undefined);
  const [pickup, setPickup] = useState<AccountInfo | undefined>(undefined);
  const [pickupIndex, setPickupIndex] = useState(0);

  const query = new URLSearchParams(useLocation().search);
  const [selectedTab, setSelectedTab] = useState(query.get('tab') === null ? 'search' : query.get('tab') as string);

  interface MenuIconProps {
    title: string;
    svgPath: string;
    name: string;
    selectedTab: string;
    badgeCount: number;
  }

  function onSelectTab(tab: string){
    if(selectedTab === 'good') { fetchGoodCount(); }
    if(selectedTab === 'matching') { fetchMathingCount(); }
    if(selectedTab === 'chat') { fetchMessageCount(); }
    if(selectedTab === 'mypage') { fetchNotificationCount(); }
    setSelectedTab(tab);
    navigate(`/home?tab=${tab}`);
  }

  async function fetchBonus() {
    var lastTimeString = Cookies.get("lastBonusTime");
    if(lastTimeString === undefined) {
      lastTimeString = '2000/1/1 00:00';
    }

    const lastTime = dayjs(lastTimeString, "YYYY/M/D HH:mm");
    const yesterday = dayjs().add(-1, 'd');
    if (lastTime.isBefore(yesterday)) {
      const response = await fetchAsync<BonusResponse>("Good/ReceiveDalyGood", null);
      setBonusGoodCount(response.count ?? 0);
      const fetchDateString =  dayjs().format('YYYY/M/D HH:mm');
      Cookies.set("lastBonusTime", fetchDateString);
    }
  }

  async function fetchDailyPickup() {
    var lastTimeString = Cookies.get("lastDailyPickupTime");
    if(lastTimeString === undefined) {
      lastTimeString = '2000/1/1 00:00';
    }

    const lastTime = dayjs(lastTimeString, "YYYY/M/D HH:mm");
    const yesterday = dayjs().add(-1, 'd');
    if (lastTime.isBefore(yesterday)) {
      const response = await fetchAsync<AccountInfo[]>("pickup/GetPickup", null);
      setPickupList(response);

      if(response.length >= 1) {
        setPickup(response[0]);
      }
    
      const fetchDateString =  dayjs().format('YYYY/M/D HH:mm');
      Cookies.set("lastDailyPickupTime", fetchDateString);
    }
  }
  
  async function fetchGoodCount() {
    var response = await fetchAsync<BadgeCount>("Good/GetUncheckedCount", null);
    setGoodCount(response.count!);
  }

  async function fetchMathingCount() {
    var response = await fetchAsync<BadgeCount>("ChatRoom/GetUnCheckedChatRoomCount", null);
    setMatchCount(response.count!);
  }

  async function fetchMessageCount() {
    var response = await fetchAsync<BadgeCount>("ChatRoom/GetUnreadRoomCount", null);
    setMessageCount(response.count!);
  }

  async function fetchNotificationCount() {
    var response = await fetchAsync<BadgeCount>("Notification/GetUnreadCount", null);
    setNotificationCount(response.count!);
  }

  const MenuIcon: React.FC<MenuIconProps> = ({ title, svgPath, name, badgeCount }) => {
    return (
      <div onClick={() => onSelectTab(name) } className="text-center flex flex-col items-center w-[20%] relative">
        <img src={`${name === selectedTab ? svgPath.replaceAll('.svg', '-color.svg') : svgPath }`} className="w-[24px]"></img>
        <div className="text-center text-xs" style={{ color: name === selectedTab ? Colors.Main : Colors.TextBlack }}>{title}</div>
        { badgeCount >= 1 &&
        <div className="absolute flex right-[14px] top-[-8px] rounded-[24px] items-center justify-center font-bold bg-main" style={{ width: "20px", height: "20px", fontSize: "11px", color: "white"}}>{badgeCount}</div>
        }
      </div>
    );
  };

  useEffect(() => {
    fetchBonus();
    fetchDailyPickup();
    fetchGoodCount();
    fetchMathingCount();
    fetchMessageCount();
    fetchNotificationCount();
  },[]);

  function onSorry() {
    if(pickupIndex + 1 >= pickupList!.length - 1) {
      setPickup(undefined);
      return;
    }

    setPickup(pickupList![pickupIndex + 1]);
    setPickupIndex(pickupIndex + 1);
  }

  function onGood() {
    if(pickupIndex + 1 >= pickupList!.length - 1) {
      console.log("test1");
      setPickup(undefined);
      return;
    }

    console.log("test2");
    setPickup(pickupList![pickupIndex + 1]);
    setPickupIndex(pickupIndex + 1);
  }

  return <>
    { pickup !== undefined &&
    <PickupDialog onClose={() => setPickup(undefined)} onSorry={onSorry} onGood={onGood} account={pickup} totalCount={pickupList?.length!} index={pickupIndex + 1} />
    }
    <div className="w-full relative">
      { bonusGoodCount >= 1 &&
        <div id="selector" className="dialog-overlay" style={{zIndex: 300}}>
          <div className='dialog bg-white overflow-auto max-h-[350px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
            <div className="text-center font-bold text-xl pt-6">デイリーボーナス</div>
            <div className="flex justify-center mt-2">
              <img src="/images/icon/icons8-gift.gif"></img>
            </div>
            <div className="text-center mt-2">
              <span className="color-main text-xl font-bold">{bonusGoodCount}</span>
              <span>回分のいいねが追加されました！</span>
            </div>
            <div className="text-center mb-6">
              気になる人にいいねしてみましょう！
            </div>
            <div onClick={() => setBonusGoodCount(0)} className="button mb-6">OK</div>
          </div>
       </div>
      }
      { selectedTab === 'search' && <SearchTab/> }
      { selectedTab === 'good' && <GoodTab/> }
      { selectedTab === 'matching' && <MatchingTab/> }
      { selectedTab === 'chat' && <ChatListTab/> }
      { selectedTab === 'mypage' && <MyPageTab/> }
      <div className="fixed w-[100%] max-w-[450px] pb-4 pt-2" style={{ bottom: '0px',backgroundColor: '#fff' }}>
        <div className="flex flex-row w-full justify-around">
          <MenuIcon title="探す" name="search" selectedTab={selectedTab} svgPath="/images/icon/icons8-search-48.svg" badgeCount={0}></MenuIcon>
          <MenuIcon title="いいね" name="good" selectedTab={selectedTab} svgPath="/images/icon/icons8-good-64.svg" badgeCount={goodCount} ></MenuIcon>
          <MenuIcon title="マッチング" name="matching" selectedTab={selectedTab} svgPath="/images/icon/icons8-heart-50.svg" badgeCount={matchCount}></MenuIcon>
          <MenuIcon title="メッセージ" name="chat" selectedTab={selectedTab} svgPath="/images/icon/icons8-message-50.svg" badgeCount={messageCount} ></MenuIcon>
          <MenuIcon title="マイページ" name="mypage" selectedTab={selectedTab} svgPath="/images/icon/icons8-account-64.svg" badgeCount={notificationCount} ></MenuIcon>
        </div>
      </div>
    </div>
  </>

  
}