import { FC, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { Navigate } from "react-router-dom";
import { getIsLoggedIn, getIsProfileComplete } from "./services/CookieHandler";
import { NotFound } from "./pages/NotFound";
import { SignInPage } from "./pages/SignIn";
import { LineAuthPage } from "./pages/LineAuth";
import { HomePage } from "./pages/HomePage";
import { SplashPage } from "./pages/SplashPage";
import { EditProfilePage } from "./pages/account/EditProfilePage";
import { DateListPage } from "./pages/account/DateListPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import { CardCompletePage } from "./pages/CardCompletePage";
import { ItemListPage } from "./pages/account/ItemListPage";
import { EmailInputPage } from "./pages/signup/EmaiInputPage";
import { EmailConfirmPage } from "./pages/signup/EmailConfirmPage";
import { SettingPage } from "./pages/account/SettingPage";
import { FootprintPage } from "./pages/account/FootprintPage";
import { PaymentRequestPage } from "./pages/payment/PaymentRequestPage";
import { BankAccountPage } from "./pages/payment/BankAccountPage";
import { BankoutPage } from "./pages/payment/BankoutPage";
import { NotificationListPage } from "./pages/account/NotificationListPage";
import { HiddbenListPage } from "./pages/account/HiddenListPage";
import { HelpPage } from "./pages/account/HelpPage";
import { InquiryPage } from "./pages/account/InquiryPage";
import { RegisterAccountPage } from "./pages/signup/RegisterAccountPage";
import { AccountClosePage } from "./pages/account/AccountClosePage";
import { AccountCloseConfirmPage } from "./pages/account/AccountCloseConfirmPage";
import { SearchConditionPage } from "./pages/homeTab/SearchConditionPage";
import { ChatPage } from "./pages/chat/ChatPage";
import { ProfilePage } from "./pages/ProfilePage";
import { SubscriptionStatusPage } from "./pages/account/SubscriptionStatusPage";
import { AccountReportPage } from "./pages/AccountReportPage";
import { BackoutReportPage } from "./pages/BackoutReportPage";
import { BackoutCounterPage } from "./pages/BackoutCounterPage";
import { TelecomPage } from "./pages/TelecomPage";

const useAuth = () => {
  const isAuthenticated = getIsLoggedIn();
  const isProfileComplete = getIsProfileComplete();
  return { isAuthenticated, isProfileComplete };
};

const PrivateRoute = () => {
  const { isAuthenticated, isProfileComplete } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  } else if(!isProfileComplete) {
    return <Navigate to="/register-account" />;
  } else {
    return <Outlet />; 
  }
};

const RegisterRoute = () => {
  const { isAuthenticated, isProfileComplete } = useAuth();

  if(isAuthenticated && isProfileComplete) {
    return <Navigate to="/home" />;
  } else if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  } else {
    return <Outlet />; 
  }
};

export const Router: FC = () => {
  let [loggedIn, setIsLoggedIn] = useState(getIsLoggedIn());
  const loginPage = '/member/sign-in';
  const redirectPage = '/member/redirect';

  return (
    <Routes>
      <Route path="/telecom.html" element={<TelecomPage />} />

      {/* app page */}
      <Route path="/" element={<SplashPage />} />
      <Route path="/index.html" element={<SplashPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/line-auth" element={<LineAuthPage />} />
      <Route path="/email-input" element={<EmailInputPage />} />
      <Route path="/email-confirm" element={<EmailConfirmPage />} />
      
      <>
      <Route element={<RegisterRoute />}>
        <Route path="/register-account" element={<RegisterAccountPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/edit-profile" element={<EditProfilePage />} />
        <Route path="/date-list" element={<DateListPage />} />
        <Route path="/card-complete" element={<CardCompletePage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/item-list" element={<ItemListPage />} />
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/footprint" element={<FootprintPage />} />
        <Route path="/payment-request" element={<PaymentRequestPage />} />
        <Route path="/bank-account" element={<BankAccountPage />} />
        <Route path="/bankout" element={<BankoutPage />} />
        <Route path="/notification-list" element={<NotificationListPage />} />
        <Route path="/hidden-list" element={<HiddbenListPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/inquiry" element={<InquiryPage />} />
        <Route path="/account-close" element={<AccountClosePage />} />
        <Route path="/account-close-confirm" element={<AccountCloseConfirmPage />} />
        <Route path="/search-condition" element={<SearchConditionPage />} />
        <Route path="/subscription-status" element={<SubscriptionStatusPage />} />
        <Route path="/account-report" element={<AccountReportPage />} />
        <Route path="/backout-report" element={<BackoutReportPage />} />
        <Route path="/backout-counter" element={<BackoutCounterPage />} />
        
      </Route>
      </>
      
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}