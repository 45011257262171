import { FC, useEffect, useState } from "react";
import { AccountInfo } from "../../model/AccountInfo";
import { fetchAsync } from "../../services/ApiHandler";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { Colors } from "../../Colors";
import { ProfileRate } from "../../model/ProfileRate";
import { GoodInventory } from "../../model/GoodInventory";
import { BadgeCount } from "../../model/BadgeCount";
import { DateRequestAndAccount, DateRequestSet } from "../../model/DateRequest";

export interface MyPageMenuIconProps {
  iconPath: string,
  name: string,
  linkUrl: string,
  badgeCount?: number,
}

export const MyPageMenuIcon: FC<MyPageMenuIconProps> = ({ iconPath, name, linkUrl, badgeCount }) => {
  return <>
    <div>
      <a href={linkUrl}>
        <div className="flex flex-col w-[80px] text-center relative">
          <img src={iconPath} className="h-[48px]"></img>
          <div className="text-sm">{name}</div>
          { (badgeCount !== undefined && badgeCount >= 1) &&
          <div className="absolute flex right-[14px] top-[-8px] rounded-[24px] items-center justify-center font-bold bg-main"
              style={{ width: "20px", height: "20px", fontSize: "11px", color: "white"}}>{badgeCount}</div>
          }
        </div>
      </a>
    </div>
  </>
}

export const MyPageTab: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [profileRate, setProfileRate] = useState(undefined as number | undefined);
  const [goodInventory, setGoodInventory] = useState(undefined as GoodInventory | undefined);
  const [futureDateList, setFutureDateList] = useState(undefined as DateRequestAndAccount[] | undefined);
  const [notificationCount, setNotificationCount] = useState(0);

  async function fetchDateList() {
    var response = await fetchAsync<DateRequestSet>("DateRequest/GetAllList", null);
    setFutureDateList(response.futureList);
  }

  async function fetchNotificationCount() {
    var response = await fetchAsync<BadgeCount>("Notification/GetUnreadCount", null);
    setNotificationCount(response.count!);
  }

  async function fetchAccountInfo() {
    var response = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
    setAccount(response);
  }

  async function fetchProfileRate() {
    var response = await fetchAsync<ProfileRate>("Profile/GetRate", null);
    setProfileRate(response.rate);
  }

  async function fetchGoodInventory() {
    var response = await fetchAsync<GoodInventory>("Good/Inventory", null);
    setGoodInventory(response);
  }

  useEffect(() => {
    fetchDateList();
    fetchNotificationCount();
    fetchAccountInfo();
    fetchProfileRate();
    fetchGoodInventory();
  },[]);

  function openEditProfilePage(){
    window.location.href = "/edit-profile";
  }

  return <>
  { account === undefined &&
    <ProgressIndicator></ProgressIndicator>
  }
  { account &&
  <div className="pt-6 mx-4">
    <div onClick={openEditProfilePage} className="flex">
      <img src={account.profileImageList![0].url} className="h-[88px] rounded-[50%]"></img>
      <div className="flex flex-col ml-4 w-full">
        <div className="font-bold text-xl">{account.nickname}</div>
        <div className="font-bold" style={{ color: Colors.Main }}>プロフィールの変更</div>
        <div>
          <span className="text-sm">プロフィール充実度</span>
          <span className="mx-2 font-bold" style={{ color: Colors.Main }} >{profileRate}</span>
          <span className="text-xs">%</span>
        </div>
        <div>
          <div className="w-full h-[8px] bg-gray-500 rounded-[4px]"></div>
          <div className="h-[8px] bg-gray-500 rounded-[4px] mt-[-8px]" style={{ width: `${profileRate}%`, backgroundColor: Colors.Main}}></div>
        </div>
      </div>
    </div>
    <div  onClick={openEditProfilePage} className="mt-2 font-bold text-white w-full text-center text-sm py-1 rounded-[4px]" style={{ backgroundColor: Colors.Main }}>
      プロフィールを充実させてマッチング率アップ！
    </div>
    <div className="mt-3 bg-gray-200 text-center py-3">
      <div className="text-xl">{ account.gender === 'man' ? account.datePoint : account.moneyInventory }</div>
      <div className="text-sm">所有ポイント</div>
      <div className="bg-white mx-3 py-2 mt-2">
        <span className="text-sm">いいね残回数</span>
        <span className="ml-2 mr-1">{goodInventory?.count} </span>
        <span className="text-sm">回</span>
      </div>
    </div>

    <div className="mt-6">
        <div className="flex justify-between mx-6">
          <MyPageMenuIcon iconPath="/images/icon/icons8-schedule-48.svg" name="デートの約束" linkUrl="/date-list" badgeCount={futureDateList?.length}></MyPageMenuIcon>
          <MyPageMenuIcon iconPath="/images/icon/icons8-bell-64.svg" name="お知らせ" linkUrl="/notification-list" badgeCount={notificationCount} />
          <MyPageMenuIcon iconPath="/images/icon/icons8-config-48.svg" name="各種設定" linkUrl="/setting"></MyPageMenuIcon>
        </div>
        <div className="flex justify-between mx-6 mt-6">
          { account.gender === 'woman' &&
          <MyPageMenuIcon iconPath="/images/bank.svg" name="振込申請" linkUrl="/payment-request"></MyPageMenuIcon>
          }
          { account.gender === 'man' &&
          <MyPageMenuIcon iconPath="/images/icon/icons8-shopping-cart-64.svg" name="アイテム購入" linkUrl="/item-list"></MyPageMenuIcon>
          }
          <MyPageMenuIcon iconPath="/images/icon/footprint.svg" name="足あと" linkUrl="/footprint"></MyPageMenuIcon>
          <MyPageMenuIcon iconPath="/images/icon/icons8-help-64.svg" name="ヘルプ" linkUrl="/help"></MyPageMenuIcon>
        </div>
    </div>
    
  </div>
  }
  </>
}