import { FC, useEffect } from "react";
import { getApiKey } from "../services/CookieHandler";

export const SplashPage: FC = () => {

  function goToNextPage() {
    const key = getApiKey();
    if(key.accountId === undefined || !key.accountId === null || key.accountId === '') {
      window.location.href = '/sign-in';
    } else {
      window.location.href = '/home';
    }
  }

  useEffect(() => {
    goToNextPage();
  },[]);

  return <>
  <div className="flex flex-col h-[100vh] items-center justify-center">
    <img src="images/logo-side.png" className="w-[60%]"></img>
  </div>
  </>
}