import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { BankAccount } from "../../model/BankAccount";
import { PageHeader } from "../../ui_parts/PageHeader";
import { AccountInfo } from "../../model/AccountInfo";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { OptionSelector } from "../../ui_parts/OptionSelector";
import { OptionItem } from "../../model/OptionItem";
import { TextEditor } from "../../ui_parts/TextEditor";
import { Bank } from "../../model/Bank";
import { BankSelector } from "../../ui_parts/BankSelector";
import toast, { Toaster } from "react-hot-toast";
import { IsNullOrEmpty } from "../../services/StringUtil";
import { UnaryExpression } from "typescript";

export const BankAccountPage: FC = () => {
  const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(undefined);
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [typeDialogVisible, setTypeDialogVisible] = useState(false);
  const [branchVisible, setBranchVisible] = useState(false);
  const [accountNumberVisible, setAccountNumberVisible] = useState(false);
  const [lastNameVisible, setLastNameVisible] = useState(false);
  const [firstNameVisible, setFirstNameVisible] = useState(false);
  const [bankSelectorVisible, setBankSelectorVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  async function fetchAccountInfo() {
    var response = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
    setAccount(response);
  }

  async function fetchBankAccount() {
    var response = await fetchAsync<BankAccount>("BankAccount/BankAccount", null);
    setBankAccount(response);
  }

  function updateBankAccount(name:string, value:string){
    setBankAccount(prevState => ({
      ...prevState!,
      [name]: value
    }));
  }

  useEffect(() => {
    fetchAccountInfo();
    fetchBankAccount();
  },[]);


  function onUpdateBankAccount(bank: Bank){
    setBankAccount(prevState => ({ ...prevState!, ["bankName"]: bank.bankName}));
    setBankAccount(prevState => ({ ...prevState!, ["bankCode"]: bank.bankCode}));
    setBankSelectorVisible(false);
  }

  async function register(){
    if(processing) { return; }
    setProcessing(true);
    const response = await postAsync("BankAccount/RegisterBankAccount", bankAccount);
    setProcessing(false);
    if(response.code !== 1) {
      toast.error(response.message ?? "Error");
    } else {
      toast.success("登録しました");
      window.history.back();
    }
  }

  function fillText(value : string | undefined) {
    return IsNullOrEmpty(value) ? "未入力" : value;
  }


  const rowClass = "flex px-4 w-full justify-between py-2 border-bottom";
  
  return <>
  <Toaster />
  <PageHeader title="口座情報登録"></PageHeader>
    { (bankAccount === undefined || account === undefined) ?
      <ProgressIndicator></ProgressIndicator> : 
      <>
      <div>
        <div className="border-bottom"></div>
        <BankSelector visible={bankSelectorVisible} onSelected={(b) => onUpdateBankAccount(b)} onCancel={() => setBankSelectorVisible(false)} />
        <div className={rowClass} onClick={() => setBankSelectorVisible(true)}>
          <span>銀行</span>
          <span className="color-main">{ fillText(bankAccount.bankName) }</span>
        </div>

        { typeDialogVisible &&
        <OptionSelector title='口座種別' 
          value={bankAccount.bankAccountType ?? ""}
          optionItems={OptionItem.bankAccountTypes}
          onSelected={(e) => { (updateBankAccount("bankAccountType", e)); setTypeDialogVisible(false); }}
          onCancel={() => setTypeDialogVisible(false)}></OptionSelector>
        }
        
        <div className={rowClass} onClick={() => setTypeDialogVisible(true)}>
          <span>口座種別</span>
          <span className="color-main">{ fillText(OptionItem.getOptionName(bankAccount.bankAccountType, OptionItem.bankAccountTypes)) }</span>
        </div>
        <TextEditor onCancel={() => setBranchVisible(false)} title="支店コード" value={bankAccount?.branchCode ?? ""} isNumber={true} isSingleLine={true}
          onUpdate={(e) => {updateBankAccount("branchCode", e); setBranchVisible(false); }} visible={branchVisible} />
        <div className={rowClass} onClick={() => setBranchVisible(true)}>
          <span>支店コード</span>
          <span className="color-main">{fillText(bankAccount.branchCode)}</span>
        </div>

        <TextEditor onCancel={() => setAccountNumberVisible(false)} title="口座番号" value={bankAccount?.accountNumber ?? ""} isNumber={true} isSingleLine={true}
          onUpdate={(e) => {updateBankAccount("accountNumber", e); setAccountNumberVisible(false); }} visible={accountNumberVisible} />
        <div className={rowClass} onClick={() => setAccountNumberVisible(true)}>
          <span>口座番号</span>
          <span className="color-main">{fillText(bankAccount.accountNumber)}</span>
        </div>
        <div className="text-sm ml-3 mt-2 color-dim">※口座番号が7桁未満の場合は先頭に0をつけてください。</div>

        <div className="border-bottom mt-2"></div>
        <TextEditor onCancel={() => setLastNameVisible(false)} title="口座名義(セイ)" value={bankAccount?.lastNameKana ?? ""} isNumber={false} isSingleLine={true}
          onUpdate={(e) => {updateBankAccount("lastNameKana", e); setLastNameVisible(false); }} visible={lastNameVisible} />
        <div className={rowClass} onClick={() => setLastNameVisible(true)}>
          <span>口座名義（セイ）</span>
          <span className="color-main">{fillText(bankAccount.lastNameKana)}</span>
        </div>

        <TextEditor onCancel={() => setFirstNameVisible(false)} title="口座名義(メイ)" value={bankAccount?.firstNameKana ?? ""} isNumber={false} isSingleLine={true}
          onUpdate={(e) => {updateBankAccount("firstNameKana", e); setFirstNameVisible(false); }} visible={firstNameVisible} />
        <div className={rowClass} onClick={() => setFirstNameVisible(true)}>
          <span>口座名義（メイ）</span>
          <span className="color-main">{fillText(bankAccount.firstNameKana)}</span>
        </div>
        <div className="text-sm ml-3 mt-1 color-dim">※振込先が間違っている場合、再度振込手数料が発生します。ご注意ください。</div>
        <button disabled={processing} onClick={register} className="w-full py-2 rounded-[40px] bg-main mx-4 text-white text-center text-lg font-bold mt-4 hover-button">登録</button>




      </div>

      </>
    }
    
    
  </>;
}