import { FC, useEffect, useState } from "react";
import { AccountInfo } from "../../model/AccountInfo";
import { GoodWithAccount } from "../../model/GoodWithAccount";
import { GoodListResponse } from "../../model/GoodListResponse";
import { fetchAsync } from "../../services/ApiHandler";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { NoItemArea } from "../../ui_parts/NoItemArea";
import { ProfilePage } from "../ProfilePage";
import { ProfileDialog } from "../ProfileDialog";

export const GoodToMeTab: FC = () => {
  const [accountList, setAccountList] = useState(undefined as GoodWithAccount[] | undefined);
  const [account, setAccount] = useState(undefined as GoodWithAccount | undefined);
  
  useEffect(() => {
    fetchGoodList();
  },[]);

  async function fetchGoodList() {
    var param = { isFromMe: 0, isToMe: 1 };
    var response = await fetchAsync<GoodListResponse>("Good/GoodList", param);
    setAccountList(response.goodListToMe!);

    if(response.goodListToMe!.length >= 1) {
      setAccount(response.goodListToMe![0]!);
    }
  }

  async function goToNextAccount() {
    if(accountList!.length <= 1) {
      setAccount(undefined);
      setAccountList([]);
      return;
    }

    accountList!.shift();
    const newArray =[...accountList!];
    setAccountList(newArray);
    setAccount(newArray[0]!);
  }

  return <>
  { accountList === undefined &&
    <ProgressIndicator></ProgressIndicator>
  }
  { accountList?.length === 0 &&
      <NoItemArea title="現在いいねはありません" description="プロフィールを充実させて
あなたのことを知ってもらいましょう" linkUrl="/edit-profile" linkText="プロフィール画面を開く" />
  }
  { account &&
    <ProfileDialog account={account.account!} onBack={() => null} onGood={goToNextAccount} onSorry={goToNextAccount} isGoodPage={true} />
  }
  </>;
}