import { FC, useEffect, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { OptionItem } from "../../model/OptionItem";
import { AccountSearchCondition } from "../../model/AccountSearchCondition";
import { IsNullOrEmpty } from "../../services/StringUtil";
import { OptionSelector } from "../../ui_parts/OptionSelector";
import { Prefecture } from "../../model/Prefecture";
import { MultiOptionSelector } from "../../ui_parts/MultiOptionSelector";
import Cookies from "js-cookie";
import { SearchInnnerTab } from "./SearchInnerTab";

interface SingleProps {
  title: string;
  value: string | undefined;
  options: OptionItem[];
  onSelected: (value: string) => void;
}

const SingleConditionRow:FC<SingleProps> = ({ title, value, options, onSelected }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);

  function convertText() {
    if(IsNullOrEmpty(value)) {
      return "未設定";
    }

    return OptionItem.getOptionName(value, options);
  }

  return <>
  { selectorVisible &&
  <OptionSelector title={title} value={value ?? ""} optionItems={options}
    onCancel={() => setSelectorVisible(false)}
    onSelected={(e) => { setSelectorVisible(false); onSelected(e); }}
    withEmpty={true}></OptionSelector>
  }
  <div onClick={() => setSelectorVisible(true)} className="flex justify-between px-4 py-2 border-bottom">
    <span className="color-dim font-lg">{title}</span>
    <span className="color-main">{convertText()}</span>
  </div>
  </>;
}


interface MultiProps {
  title: string;
  values: string[] | undefined;
  options: OptionItem[];
  maxCount?: number;
  onSelected: (value: string[]) => void;
}

const MultiConditionRow:FC<MultiProps> = ({ title, values, options, onSelected, maxCount }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);

  function convertText() {
    if(values == undefined || values.length === 0) {
      return "未設定";
    }

    var names = values.map((e) => OptionItem.getOptionName(e, options));
    return names.join(",");
  }

  return <>
  { selectorVisible &&
  <MultiOptionSelector title={title}
    values={values ?? []} optionItems={options}
    onCancel={() => setSelectorVisible(false)}
    onSelected={(e) => { setSelectorVisible(false); onSelected(e); }}
    maxCount={maxCount} />
  }
  <div onClick={() => setSelectorVisible(true)} className="flex justify-between px-4 py-2 border-bottom">
    <span className="color-dim font-lg">{title}</span>
    <span className="color-main">{convertText()}</span>
  </div>
  </>;
}

export const SearchConditionPage: FC = () => {
  const [condition, setCondition] = useState<AccountSearchCondition | undefined>(undefined);

  useEffect(() => {
    const json = Cookies.get("search-condition") ?? "{}";
    const searchCondition = JSON.parse(json) as AccountSearchCondition;
    setCondition(searchCondition);
  },[]);


  function update(name: string, value: any) {
    setCondition(prevState => ({
      ...prevState!,
      [name]: value
    }));
  }

  function search(){
    const json = JSON.stringify(condition);
    Cookies.set("search-condition", json, { expires: 3000 });
    window.location.href = "/home";
  }

  function clear() {
    setCondition(undefined);
  }

  return <>
  <PageHeader title="絞り込み検索"></PageHeader>
  <div className="border-bottom"></div>
  <SingleConditionRow title="年齢(以上)" value={condition?.ageUnderLimit?.toString()} options={OptionItem.ageTypes} onSelected={(e) => update("ageUnderLimit", e)} />
  <SingleConditionRow title="年齢(以下)" value={condition?.ageUpperLimit?.toString()} options={OptionItem.ageTypes} onSelected={(e) => update("ageUpperLimit", e)} />
  <SingleConditionRow title="身長(以上)" value={condition?.heightUnderLimit?.toString()} options={OptionItem.heightOptions} onSelected={(e) => update("heightUnderLimit", e)} />
  <SingleConditionRow title="身長(以下)" value={condition?.heightUpperLimit?.toString()} options={OptionItem.heightOptions} onSelected={(e) => update("heightUpperLimit", e)} />
  <MultiConditionRow title="都道府県" values={condition?.prefectures} options={Prefecture.getOptionItems()} onSelected={(e) => update("prefectures", e)} maxCount={5} />
  <MultiConditionRow title="休日" values={condition?.holidayList} options={OptionItem.holidayOptions} onSelected={(e) => update("holidayList", e)} />
  <MultiConditionRow title="タバコ" values={condition?.smokingList} options={OptionItem.smokingTypes} onSelected={(e) => update("smokingList", e)} />
  <MultiConditionRow title="お酒" values={condition?.alcoholList} options={OptionItem.alcoholTypes} onSelected={(e) => update("alcoholList", e)} />
  <MultiConditionRow title="スタイル" values={condition?.bodyStyleList} options={OptionItem.bodyStyleAllTypes} onSelected={(e) => update("bodyStyleList", e)} />

  <div className="flex justify-around mt-6 pb-4">
    <span onClick={clear} className="py-1 px-10 rounded-[24px]" style={{ border: "1px solid gray" }}>クリア</span>
    <span onClick={search} className="bg-main py-1 px-12 text-white rounded-[24px] font-bold">検索</span>
  </div>
  </>
}