
import { FC, useState } from "react";
import { OptionItem } from "../model/OptionItem";
import { Colors } from "../Colors";


export interface SingleOptionSelectorProps {
  title: string;
  value: string;
  optionItems: OptionItem[];
  onSelected: (value: string) => void;
  onCancel: () => void;
  withEmpty?: boolean;
}

export const OptionSelector: FC<SingleOptionSelectorProps> = ({ title, value, optionItems, onSelected, onCancel, withEmpty }) => {
  const [selectedValue, setSelectedValue] = useState(value as string);

  return <> 
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
      <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
        <div className="sticky top-0 justify-between bg-white pt-4">
          <span className="text-lg">{title}</span>
        </div>
        <div className="mt-2"></div>
        { withEmpty &&
          <div className="flex py-2 justify-start" onClick={(elem) => setSelectedValue("")}>
            <img className="w-[24px] mr-2" src={selectedValue === "" ? "/images/icon/icons8-checked-checkbox.svg": "/images/icon/icons8-unchecked-checkbox.svg"}></img>
            <span style={{ color: selectedValue === "" ? Colors.Main : Colors.TextBlack, fontWeight: selectedValue === "" ? "700" : "400" }  }>未選択</span>
          </div>
        }
        { optionItems.map((e) =>
          <div className="flex py-2 justify-start" onClick={(elem) => setSelectedValue(e.code!)}>
            <img className="w-[24px] mr-2" src={e.code === selectedValue ? "/images/icon/icons8-checked-checkbox.svg": "/images/icon/icons8-unchecked-checkbox.svg"}></img>
            <span style={{ color: e.code === selectedValue ? Colors.Main : Colors.TextBlack, fontWeight: e.code === selectedValue ? "700" : "400" }  }>{e.label}</span>
          </div>
        )}
        <div className="sticky flex bottom-0 justify-end bg-white pb-4 items-center">
          <span onClick={onCancel} className="text-sm color-dim">キャンセル</span>
          <span onClick={(e) => onSelected(selectedValue)} className="ml-9">決定</span>
        </div>
      </div>
    </div>
  </>
}