import { FC, useEffect, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { GoodInventory } from "../../model/GoodInventory";
import { profileEnd } from "console";
import { sleep } from "../../services/SleepService";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { logout } from "../../services/CookieHandler";

export const AccountClosePage: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [goodInventory, setGoodInventory] = useState(undefined as GoodInventory | undefined);
  const [hasFutureDate, setFasFutureDate] = useState(false);
  const [processing, setProcessing] = useState(false);

  async function suspend() {
    if(processing){ return; }

    if (!window.confirm("一時休止します。よろしいですか")) {
      return;
    }

    setProcessing(true);
    const response = await postAsync("suspend/Suspend", null);

    if (response.code === 1) {
      toast.success("一時休止しました");
      logout();
      await sleep(2000);
      window.location.href = "/sign-in";
    } else {
      setProcessing(false);
      toast.error(response.message ?? "エラーが発生しました");
    }
  }


  async function fetchFutureDate() {
    var response = await fetchAsync<AccountInfo>("DateRequest/HasFutureDate", null);
    setAccount(response);
  }
  
  async function fetchGoodInventory() {
    var response = await fetchAsync<GoodInventory>("Good/Inventory", null);
    setGoodInventory(response);
  }

  async function fetchAccountInfo() {
    var response = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
    setAccount(response);
  }

  useEffect(() => {
    fetchAccountInfo();
    fetchGoodInventory();
    fetchFutureDate();
  },[]);


  return <>
    <Toaster />
    <PageHeader title="退会手続き"></PageHeader>
    { account &&
    <div className="pt-2 pb-10 px-4">
      <div className="font-bold text-xl">退会手続きのご確認</div>
      <div className="mt-2">いつもRapidDateをご利用いただきました、誠にありがとうございます。下記注意点をご確認後退会手続きへお進みください。</div>
      <div className="mt-4 font-bold text-lg">現在保有するポイントは失効します</div>
      <div className="mt-2">
        現在保有する獲得ポイント、購入ポイントは退会と同時に失効します。退会後いかなる理由においてもポイントをお戻しすることはできません。
      </div>
      <div className="flex justify-between mt-4 items-center border-bottom pb-2">
        <span className="font-bold">所有ポイント</span>
        <span className="text-xl font-bold">{ account.gender === 'man' ? account.datePoint : account.moneyInventory } P</span>
      </div>
      <div className="flex justify-between mt-2 items-center border-bottom pb-2">
        <span className="font-bold">いいね残回数</span>
        <span className="text-xl font-bold">{ goodInventory?.count } 回</span>
      </div>
      <div className="mt-4 font-bold text-lg">再登録しても、過去のデータは引き継げません</div>
      <div className="mt-2">
        再登録された場合、過去の自己紹介文や写真などのデータやポイントを引き継ぐことはできません。
      </div>
      <div className="mt-4 font-bold text-lg">一時休止について</div>
      <div className="mt-2">
        一時休止すると他の全てのユーザーから見えなくなります。<br/><br/>
あなたが保有しているいいねや、自己紹介のデータやチャットの履歴などは削除されず、いつでも再開することができます。<br/><br/>
退会するといいねやチャットの履歴などが全て見れなくなるため、RapidDateでは一時休止をすることをおすすめしております。
      </div>
      { hasFutureDate ?
        <div className="mt-2 font-bold text-red-400">未実施のデート予定があるため一時休止出来ません。</div> : 
        <button onClick={suspend} disabled={processing} className="w-full button mt-2 hover-button">一時休止する</button>
      }
      <div className="mt-4">
        以上をご確認した上で、退会手続きを続けられる方は下のボタンから退会手続きを続けてください。
      </div>
      { hasFutureDate ?
        <div className="mt-2 font-bold text-red-400">未実施のデート予定があるため退会出来ません。</div> :
        <a href="/account-close-confirm">
        <span className="button mt-4">退会続きを続ける</span>
        </a>
      }
      
    </div>
    }
  </>;
}