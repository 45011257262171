import { FC, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { Colors } from "../../Colors";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { TranResponse } from "../../model/TranResponse";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { TranStatus } from "../../model/TranStatus";
import toast, { Toaster } from "react-hot-toast";

class ItemInfo {
  name: string | undefined;
  description: string | undefined;
  price: number | undefined;
  planId: string | undefined;
}

export const ItemListPage: FC = () => {
  const [processing, setProcessing] = useState(false);
  const [planName, setPlanName] = useState(undefined as string | undefined);
  const [planId, setPlanId] = useState(undefined as string | undefined);
  const [price, setPrice] = useState(undefined as string | undefined);

  const items = [
    { name: "いいね10回券", description: "いいね10回分のチケット", price: 980, planId: "good_10" },
    { name: "いいね30回券", description: "いいね30回分のチケット", price: 2600, planId: "good_30" },
    { name: "いいね50回券", description: "いいね50回分のチケット", price: 4200, planId: "good_50" },
    { name: "プライベートモード", description: "他のユーザーから見えなくなります", price: 980, planId: "hide" },
    { name: "ベーシックプラン(1ヶ月)", description: "メッセージのやり取りが可能になります", price: 4900, planId: "basic_plan_01" },
    { name: "ベーシックプラン(3ヶ月)", description: "メッセージのやり取りが可能になります", price: 12900, planId: "basic_plan_03" },
    { name: "ベーシックプラン(6ヶ月)", description: "メッセージのやり取りが可能になります", price: 23800, planId: "basic_plan_06" },
  ] as ItemInfo[];

  function buildConfirmDialog() {
    return <>
      <div id="ageConfirmDialog" className="dialog-overlay">
        <div className='dialog text-center'>
          { processing &&
            <div className="text-center mb-6">
              <div>購入処理中です</div>
              <div>しばらくおまちください...</div>
              <ProgressIndicator></ProgressIndicator>
            </div>
          }
          { !processing &&
          <>
            <div className="font-bold text-lg mb-4">アイテム購入</div>
            <div className="flex w-full justify-between pb-2 items-end" style={{ borderBottom : `1px solid ${Colors.Border}` }}>
              <span className="text-sm">購入アイテム</span>
              <span>{planName}</span>
            </div>
            <div className="mt-2 flex w-full justify-between pb-2 items-end" style={{ borderBottom : `1px solid ${Colors.Border}` }}>
              <span className="text-sm">価格</span>
              <span>{price} 円</span>
            </div>
            <div onClick={purchase} className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-6'>
              購入する
            </div>
            <div className="text-sm color-dim text-left">
            本サービスで販売する商品・サービスについては、当社が別途定める場合を除き、購入手続き完了後の返品又はキャンセルをお受けいたしません。
なお、サービスに欠陥・不良がある場合は、利用規約の定めに従って対応します。
            </div>
            <div onClick={onCancel} className="color-dim text-sm mt-4 mb-2">
              キャンセル
            </div>
          </>
          }
        </div>
      </div>
    </>
  }

  function setItem(price: string, name: string, planId: string) {
    setPlanName(name);
    setPrice(price);
    setPlanId(planId);
  }
  
  function onCancel(){
    setPlanName(undefined);
    setPrice(undefined);
    setPlanId(undefined);
  }

  async function purchase(){
    if(processing) {
      return;
    }

    try{
      setProcessing(true);
      const url = planId!.startsWith("basic") ? 'subscription/PurchaseSubscription' : 'subscription/PurchaseItem'
      const param = { planName: planId };
      var response = await postAsync<TranResponse>(url, param);
      if(response.code === 1) {
        // 購入完了通知は非同期で行われるためポーリングする
        const tranParam = { tranId: response.data!.tranId };
        for(var i = 0; i < 15; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));

          var tranResponse = await fetchAsync<TranStatus>("subscription/GetTranStatus", tranParam);
          if(tranResponse.status === 'complete') {
            toast.success("購入が完了しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            break;
          }

          if(tranResponse.status === 'failed') {
            toast.error("購入に失敗しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            setProcessing(false);
            break;
          }
        }
        onCancel();
        setProcessing(false);
      } else {
        setProcessing(false);
        toast.error(response.message ?? 'エラーが発生しました');
      }
    } catch(e) {
      toast.error("エラーが発生しました");
      setProcessing(false);
    }
  }

  return <>
    <PageHeader title="アイテム購入"></PageHeader>
    <Toaster></Toaster>
    { planId &&
      buildConfirmDialog()
    }
    <div className="px-4">
      <div className="border-bottom"></div>
    { items?.map((e) => 
      <div onClick={() => setItem(e.price!.toString(), e.name!, e.planId!) } className="flex justify-between py-2" style={{ borderBottom: `1px solid ${Colors.Border}` }}>
        <div>
          <div>{e.name}</div>
          <div className="text-sm color-dim">{e.description}</div>
        </div>
        <div className="py-2 bg-main w-[90px] self-center text-center text-white font-bold rounded-[32px] text-sm">{e.price}円</div>
      </div>
    )}
    </div>

    
  </>;
}