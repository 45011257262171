import { FC, useEffect, useRef, useState } from "react";
import { fetchAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { ProfilePage } from "../ProfilePage";
import { Prefecture } from "../../model/Prefecture";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import Cookies from "js-cookie";
import { AccountSearchCondition } from "../../model/AccountSearchCondition";
import debounce from "@mui/material/utils/debounce";

export interface SearchInnnerTab { 
  tabType: string;
}

export const SearchInnnerTab: FC<SearchInnnerTab> = ({ tabType }) => {
  const accountList = useRef(null as AccountInfo[] | null);
  const noNextData = useRef(false);
  const pageCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRecommendList();

    const handleScroll = debounce(() => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - 100) {
        fetchRecommendList();
        setTimeout(() => {
          // 連打を避けるために一時的に切る
          window.removeEventListener('scroll', handleScroll);
        }, 1000);
        window.removeEventListener('scroll', handleScroll);
      } else {
      }
    }, 100); //イベント切ってるならdebouce不要かも

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  function joinCondition(values: string[] | undefined) {
    if(values === undefined || values.length === 0) {
      return "";
    } else {
      return values.join("|");
    }
  }

  function addArrayCondition(condition: any, name: string, values: string[] | undefined) {
   if(values === undefined || values.length === 0){
    return;
   }
   condition[name] = joinCondition(values);
  }

  function addNumberCondition(condition: any, name: string, value: number | undefined) {
    if(value === undefined) { return; }
    condition[name] = value;
  }


  async function fetchRecommendList() {
    if(noNextData.current === true) {
      return;
    }

    if(isLoading === true) {
      return;
    }

    setIsLoading(true);
    const serchCondition = Cookies.get("search-condition") ?? "{}";
    const condition = JSON.parse(serchCondition) as AccountSearchCondition;
    var param = {
      orderByCreatedDate: tabType === 'new' ? 1 : 0,
      page: pageCount.current,
      count: 40,
    };

    addNumberCondition(param, "ageUnderLimit", condition.ageUnderLimit);
    addNumberCondition(param, "ageUpperLimit", condition.ageUpperLimit);
    addNumberCondition(param, "heightUnderLimit", condition.heightUnderLimit);
    addNumberCondition(param, "heightUpperLimit", condition.heightUpperLimit);
    addArrayCondition(param, "prefectures", condition.prefectures);
    addArrayCondition(param, "smokingList", condition.smokingList);
    addArrayCondition(param, "alcoholList", condition.alcoholList);
    addArrayCondition(param, "bodyStyleList", condition.bodyStyleList);
    addArrayCondition(param, "holidayList", condition.holidayList);
    
    const list = accountList.current ?? [];
    var response = await fetchAsync<AccountInfo[]>("Recommend/RecommendList", param);

    console.log(`len: ${list.length}`);
    const newList = [...list, ...response];
    accountList.current = newList;
    pageCount.current += 1;

    if(response.length === 0) {
      noNextData.current = true;
    }

    setIsLoading(false);
  }

  function selectAccount(selectedAccount: AccountInfo) {
    //　dialogを出してる間はスクロールを切る
    // document.body.style.overflow = 'hidden'; 
    window.location.href = `/profile?id=${selectedAccount.accountId}`
  }
  
  return <>
    <div className="flex flex-wrap w-full px-2 justify-between box-border" style={{ }}>
    { accountList === null &&
      <ProgressIndicator />
    }
    { accountList.current?.length === 0 &&
      <div className="text-center margin-center mt-8 text-sm">
        該当のユーザーはいませんでした。<br/>
        検索条件を変更してみてください。
      </div>
    }
    { accountList.current !== null && accountList.current!.map((e) => (
      <div onClick={() => selectAccount(e)} style={{ padding: "4px", boxSizing: "border-box", flex: '0 0 50%'  }}>
        <img src={e.profileImageList![0].url} className="box-border" style={{ borderRadius: "12px" }}></img>
        <div className="flex mt-1 text-xs">
          <span className="mr-2">{ Prefecture.getPrefectureName(e.prefecture) }</span>
          <span>{e.age}</span>
        </div>
        <div className="text-xs color-dim">{e.shortMessage}</div>
      </div>
    ))}
    </div>
    <div className="fixed bottom-[80px] text-center max-w-[450px] w-full">
      <span onClick={() => window.location.href = "/search-condition"} className="bg-white py-2 px-12 rounded-[24px] font-bold" style={{ border: "1px solid gray" }}>
        <img className="inline mr-1 h-[20px]" src="/images/search.svg"></img>
        <span>絞り込む</span>
      </span>
    </div>
    <div className="h-[140px]"></div>
  </>
}