import { FC, useEffect, useState } from "react";
import { ChatPage } from "../chat/ChatPage";
import { fetchAsync } from "../../services/ApiHandler";
import { AccountWithChatRoom } from "../../model/ChatRoomSummary";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { Colors } from "../../Colors";
import dayjs from "dayjs";
import { NoItemArea } from "../../ui_parts/NoItemArea";

export const MatchingTab: FC = () => {
  const [roomList, setRoomList] = useState(undefined as AccountWithChatRoom[] | undefined);
  // const [chatRoom, setChatRoom] = useState(undefined as AccountWithChatRoom | undefined);

  useEffect(() => {
    fetchChatRoomList();
  },[]);

  function parseDate(date: string): string {
    const day = dayjs(date, "YYYYMMDDHHmmss");
    return day.format('M/D HH:mm');
  }

  function selectChatRoom(room: AccountWithChatRoom) {
    // setChatRoom(room);
    window.location.href = `/chat?inside=1&id=${room.account?.accountId!}`;
  }

  // function onBackFromChatRoom() {
  //   setChatRoom(undefined);
  // }

  async function fetchChatRoomList() {
    var param = {  isMessaging: 0 };
    var list = await fetchAsync<AccountWithChatRoom[]>("ChatRoom/ChatRoomList", param);
    setRoomList(list);
  }

  return <>  
  {/* { chatRoom &&
    <ChatPage room={chatRoom} onBack={onBackFromChatRoom}></ChatPage>
  } */}
  <div className="mx-4 pt-4">
    <div className="text-xl ml-4 font-bold mb-2">マッチング</div>
    { roomList === undefined &&
      <ProgressIndicator></ProgressIndicator>
    }
    { roomList?.length === 0 &&
      <NoItemArea title="現在マッチングありません" description="いいねを送ってアプローチしてみましょう！" linkUrl="/home" linkText="お相手を探す" />
    }
    { roomList?.map((e) => (
      <div className="flex w-full justify-between mb-2" onClick={(c) => selectChatRoom(e) }>
        <img src={e.account!.profileImageList![0].url} className="w-[80px] h-[70px] rounded-[40px]"></img>
        <div className="flex justify-between w-full" style={{ overflow: "hidden"}}>
          <div className="ml-3" style={{ overflow: "hidden"}}>
            <div className="font-bold">
              <span>{e.account!.nickname}</span>
              <span className="ml-2">{e.account!.age}</span>
            </div>
            <div className="text-sm" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              マッチングしました。
            </div>
          </div>
          <div className="flex flex-col items-end w-[80px] min-w-[80px]">
            <div className="text-sm" style={{ color: Colors.Dim}}>{ parseDate(e.chatRoomSummary?.lastMessageDate!) }</div>
            { e.chatRoomSummary!.latestMessageUid! >= e.chatRoomSummary!.myReadId! && 
              <div className="mt-1 bg-main h-[10px] w-[10px] rounded-[6px]">
              </div>
            }
          </div>
        </div>
      </div>
    ))}
  </div>
  </>
}
