export class OptionItem {
  code: string | undefined;
  label: string | undefined;
  displayOrder: number | undefined;

  constructor(code: string, label: string) {
    this.code = code;
    this.label = label;
  }

  static getOptionName(code: string | undefined, items: OptionItem[]) : string {
    const item = items.filter((e) => e.code === code);
    if(item.length >= 1) {
      return item[0].label!;
    } else {
      return "";
    }
  }

  static genderOptions : OptionItem[] = [
    new OptionItem("man", "男性"),
    new OptionItem("woman", "女性"),
  ];

  static bankAccountTypes : OptionItem[] = [
    new OptionItem("hutuu", "普通"),
    new OptionItem("touza", "当座"),
  ];

  static holidayOptions : OptionItem[] = [
    new OptionItem("weekend", "土日"),
    new OptionItem("weekday", "平日"),
    new OptionItem("irregular", "不定期"),
    new OptionItem("other", "その他"),
  ];

  static bodyStyleAllTypes : OptionItem[] = [
    new OptionItem("little_thin", "やや細め"),
    new OptionItem("thin", "細め"),
    new OptionItem("normal", "普通"),
    new OptionItem("glamor", "グラマー"),
    new OptionItem("muscle", "筋肉質"),
    new OptionItem("little_fat", "ややぽっちゃり"),
    new OptionItem("fat", "ぽっちゃり"),
  ];

  static bodyStyleManTypes : OptionItem[] = [
    new OptionItem("little_thin", "やや細め"),
    new OptionItem("thin", "細め"),
    new OptionItem("normal", "普通"),
    new OptionItem("muscle", "筋肉質"),
    new OptionItem("little_fat", "ややぽっちゃり"),
    new OptionItem("fat", "ぽっちゃり")
  ]

  //static List<OptionItem> getSmoking() {
  static smokingTypes : OptionItem[] = [
    new OptionItem("no", "吸わない"),
    new OptionItem("depends", "非喫煙者の前では吸わない"),
    new OptionItem("yes", "吸う"),
  ];

  static alcoholTypes : OptionItem[] = [
    new OptionItem("yes", "飲む"),
    new OptionItem("no", "飲まない"),
    new OptionItem("sometime", "たまに飲む"),
  ]

  static schoolTypes : OptionItem[] = [
    new OptionItem("highSchool", "高校卒"),
    new OptionItem("short", "短大/専門学校卒"),
    new OptionItem("university", "大学卒"),
    new OptionItem("master", "大学院卒(修士)"),
    new OptionItem("doctor", "大学院卒(博士)"),
    new OptionItem("highSchool", "高専卒")
  ];

  static personalityTypes : OptionItem[] = [
    new OptionItem("ISTJ", "ISTJ - 管理者型"),
    new OptionItem("ISFJ","ISFJ - 擁護者型"),
    new OptionItem("INFJ","INFJ - 提唱者型"),
    new OptionItem("INTJ","INTJ - 建築家型"),
    new OptionItem("ISTP","ISTP - 巨匠型"),
    new OptionItem("ISFP","ISFP - 冒険家型"),
    new OptionItem("INFP","INFP - 仲介者型"),
    new OptionItem("INTP","INTP - 論理学者型"),
    new OptionItem("ESTP","ESTP - 起業家型"),
    new OptionItem("ESFP","ESFP - エンターテイナー型"),
    new OptionItem("ENFP","ENFP - 広報運動家型"),
    new OptionItem("ENTP","ENTP - 討論者型"),
    new OptionItem("ESTJ","ESTJ - 幹部型"),
    new OptionItem("ESFJ","ESFJ - 領事官型"),
    new OptionItem("ENFJ","ENFJ - 主人公型"),
    new OptionItem("ENTJ","ENTJ - 指揮官型"),
  ];

  static bloodTypes : OptionItem[] = [
    new OptionItem("a", "A型"),
    new OptionItem("b", "B型"),
    new OptionItem("o", "O型"),
    new OptionItem("ab", "AB型"),
    new OptionItem("unknown", "不明"),
  ];

  static datePriceList : OptionItem[] = [
    new OptionItem("3000", "3000円"),
    new OptionItem("4000", "4000円"),
    new OptionItem("5000", "5000円"),
    new OptionItem("6000", "6000円"),
    new OptionItem("7000", "7000円"),
    new OptionItem("8000", "8000円"),
    new OptionItem("9000", "9000円"),
    new OptionItem("10000", "10000円"),
  ];

  static incomeTypes : OptionItem[] = [
    new OptionItem("199", "200万円未満"),
    new OptionItem("200", "200万～300万円"),
    new OptionItem("300", "300万～400万円"),
    new OptionItem("400", "400万～500万円"),
    new OptionItem("500", "500万～600万円"),
    new OptionItem("600", "600万～700万円"),
    new OptionItem("700", "700万～800万円"),
    new OptionItem("800", "800万～900万円"),
    new OptionItem("900", "900万～1000万円"),
    new OptionItem("1000", "1000万～1200万円"),
    new OptionItem("1200", "1200万～1500万円"),
    new OptionItem("1500", "1500万～2000万円"),
    new OptionItem("2000", "2000万～3000万円"),
    new OptionItem("3000", "3000万～5000万円"),
    new OptionItem("5000", "5000万円～1億円"),
    new OptionItem("10000", "1億円以上"),
  ];

  static closeReasonTypes : OptionItem[] = [
    new OptionItem("not_match", "デートのマッチングができない"),
    new OptionItem("no_one", "マッチングしたい相手がいない"),
    new OptionItem("not_fun", "デートしたが楽しくなかった"),
    new OptionItem("un_useful", "サイトが使いづらい",),
    new OptionItem("too_high", "料金が高い"),
    new OptionItem("privacy", "プライバシーに不安がある"),
    new OptionItem("communication", "相手とのやり取りで不快な思いをした"),
    new OptionItem("bad_members", "悪質なユーザーが多かった"),
    new OptionItem("other", "その他"),
  ];
  
  static ageTypes : OptionItem[] = [
    new OptionItem("15", "10代後半"),
    new OptionItem("20", "20代前半"),
    new OptionItem("25", "20代後半"),
    new OptionItem("30", "30代前半"),
    new OptionItem("35", "30代後半"),
    new OptionItem("40", "40代前半"),
    new OptionItem("45", "40代後半"),
    new OptionItem("50", "50代前半"),
    new OptionItem("55", "50代後半"),
    new OptionItem("60", "60代前半"),
    new OptionItem("65", "60代後半"),
    new OptionItem("70", "70代前半"),
    new OptionItem("75", "70代後半"),
  ];

  static cancelReasonTypes : OptionItem[] = [
    new OptionItem("myself", "自身の都合によるキャンセル"),
    new OptionItem("partner", "お相手の都合によるキャンセル"),
  ];

  static timeOptions : OptionItem[] = [
    new OptionItem("23", "23時"),
    new OptionItem("22", "22時"),
    new OptionItem("21", "21時"),
    new OptionItem("20", "20時"),
    new OptionItem("19", "19時"),
    new OptionItem("18", "18時"),
    new OptionItem("17", "17時"),
    new OptionItem("16", "16時"),
    new OptionItem("15", "15時"),
    new OptionItem("14", "14時"),
    new OptionItem("13", "13時"),
    new OptionItem("12", "12時"),
    new OptionItem("11", "11時"),
    new OptionItem("10", "10時"),
    new OptionItem("9", "9時"),
    new OptionItem("8", "8時"),
    new OptionItem("7", "7時"),
    new OptionItem("6", "6時"),
    new OptionItem("5", "5時"),
    new OptionItem("4", "4時"),
    new OptionItem("3", "3時"),
    new OptionItem("2", "2時"),
    new OptionItem("1", "1時"),
    new OptionItem("0", "0時"),
  ];

  


  static minutesOptions : OptionItem[] = [
    new OptionItem("0", "0分"),
    new OptionItem("15", "15分"),
    new OptionItem("30", "30分"),
    new OptionItem("45", "45分"),
  ];

  static heightOptions : OptionItem[] = [
    new OptionItem("130", "130cm"),
    new OptionItem("131", "131cm"),
    new OptionItem("132", "132cm"),
    new OptionItem("133", "133cm"),
    new OptionItem("134", "134cm"),
    new OptionItem("135", "135cm"),
    new OptionItem("136", "136cm"),
    new OptionItem("137", "137cm"),
    new OptionItem("138", "138cm"),
    new OptionItem("139", "139cm"),
    new OptionItem("140", "140cm"),
    new OptionItem("141", "141cm"),
    new OptionItem("142", "142cm"),
    new OptionItem("143", "143cm"),
    new OptionItem("144", "144cm"),
    new OptionItem("145", "145cm"),
    new OptionItem("146", "146cm"),
    new OptionItem("147", "147cm"),
    new OptionItem("148", "148cm"),
    new OptionItem("149", "149cm"),
    new OptionItem("150", "150cm"),
    new OptionItem("151", "151cm"),
    new OptionItem("152", "152cm"),
    new OptionItem("153", "153cm"),
    new OptionItem("154", "154cm"),
    new OptionItem("155", "155cm"),
    new OptionItem("156", "156cm"),
    new OptionItem("157", "157cm"),
    new OptionItem("158", "158cm"),
    new OptionItem("159", "159cm"),
    new OptionItem("160", "160cm"),
    new OptionItem("161", "161cm"),
    new OptionItem("162", "162cm"),
    new OptionItem("163", "163cm"),
    new OptionItem("164", "164cm"),
    new OptionItem("165", "165cm"),
    new OptionItem("166", "166cm"),
    new OptionItem("167", "167cm"),
    new OptionItem("168", "168cm"),
    new OptionItem("169", "169cm"),
    new OptionItem("170", "170cm"),
    new OptionItem("171", "171cm"),
    new OptionItem("172", "172cm"),
    new OptionItem("173", "173cm"),
    new OptionItem("174", "174cm"),
    new OptionItem("175", "175cm"),
    new OptionItem("176", "176cm"),
    new OptionItem("177", "177cm"),
    new OptionItem("178", "178cm"),
    new OptionItem("179", "179cm"),
    new OptionItem("180", "180cm"),
    new OptionItem("181", "181cm"),
    new OptionItem("182", "182cm"),
    new OptionItem("183", "183cm"),
    new OptionItem("184", "184cm"),
    new OptionItem("185", "185cm"),
    new OptionItem("186", "186cm"),
    new OptionItem("187", "187cm"),
    new OptionItem("188", "188cm"),
    new OptionItem("189", "189cm"),
    new OptionItem("190", "190cm"),
    new OptionItem("191", "191cm"),
    new OptionItem("192", "192cm"),
    new OptionItem("193", "193cm"),
    new OptionItem("194", "194cm"),
    new OptionItem("195", "195cm"),
    new OptionItem("196", "196cm"),
    new OptionItem("197", "197cm"),
    new OptionItem("198", "198cm"),
    new OptionItem("199", "199cm"),
    new OptionItem("200", "200cm"),
    new OptionItem("201", "201cm"),
    new OptionItem("202", "202cm"),
    new OptionItem("203", "203cm"),
    new OptionItem("204", "204cm"),
    new OptionItem("205", "205cm"),
    new OptionItem("206", "206cm"),
    new OptionItem("207", "207cm"),
    new OptionItem("208", "208cm"),
    new OptionItem("209", "209cm"),
    new OptionItem("210", "210cm"),
    new OptionItem("211", "211cm"),
    new OptionItem("212", "212cm"),
    new OptionItem("213", "213cm"),
    new OptionItem("214", "214cm"),
    new OptionItem("215", "215cm"),
    new OptionItem("216", "216cm"),
    new OptionItem("217", "217cm"),
    new OptionItem("218", "218cm"),
    new OptionItem("219", "219cm"),
    new OptionItem("220", "220cm")
]

  /*
  static List<OptionItem> getMinutes() {
    var list = List<OptionItem>.empty(growable: true,
    for (int i = 0; i <= 45; i += 15) {
      new OptionItem("$i", "$i 分"),
    }
    return list;
  }

  static List<OptionItem> getHeight() {
    var list = List<OptionItem>.empty(growable: true,
    for (int i = 130; i <= 200; i++) {
      new OptionItem("$i", "$i cm"),
    }
    return list;
  }

  static String getText(List<OptionItem> items, String? value){
    if(value == null || value == "")
      return "";

    return items.firstWhereOrNull((e) => e.code == value)?.label ?? "";
  }
  */

}