import { FC, useEffect, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { logout } from "../../services/CookieHandler";
import { openTelecomCardPage } from "../../services/TelecomService";
import { AccountInfo } from "../../model/AccountInfo";
import { fetchAsync } from "../../services/ApiHandler";

export const SettingPage: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);

  function buildRow(title:string, onSelect: () => void) {
    return <div onClick={onSelect} className="flex justify-between items-center border-bottom py-3 px-3">
      <span className="">{title}</span>
      <img src="/images/direction_right.svg" />
    </div>
  }
  
  async function fetchAccountInfo() {
    var response = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
    setAccount(response);
  }
  
  function signout() {
    logout();
    window.location.href = "/sign-in";
  }

  function buildLogoutDialog() {
    return <>
      <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
        <div className='dialog bg-white overflow-auto max-h-[450px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
          <div className="pt-6">ログアウトします。よろしいですか？</div>
          <div className="flex justify-around w-full mt-6 mb-6">
            <span className="color-dim text-sm" onClick={() => setLogoutDialogVisible(false)} >キャンセル</span>
            <span onClick={signout}>ログアウト</span>
          </div>
        </div>
      </div>
    </>
  }

  useEffect(() => {
    fetchAccountInfo();
  },[]);

  return <>
    <PageHeader title="設定"></PageHeader>
    { logoutDialogVisible &&
      buildLogoutDialog()
    }
    <div className="w-full border-bottom"></div>
    <div className="">
      { account !== undefined && 
      <>
      { buildRow("通知設定", () => null) }
      { buildRow("非表示ユーザー", () => window.location.href = "/hidden-list?type=hidden") }
      { buildRow("ブロックユーザー", () => window.location.href = "/hidden-list?type=block") }
      { buildRow("お支払い方法変更", () => openTelecomCardPage()) }
      { account.gender === 'man' &&
        <>
        { buildRow("サブスクリプション", () => window.location.href = "/subscription-status") }
        </>
      }
      { buildRow("利用規約", () => window.location.href = "https://rapid-date.com/terms.html" ) }
      { buildRow("プライバシーポリシー", () => window.location.href = "https://rapid-date.com/privacy-policy.html") }
      { buildRow("特定商取引法に基づく表示", () => window.location.href = "https://rapid-date.com/tokushoho.html") }
      { buildRow("ヘルプ", () => window.location.href = "/help") }
      { buildRow("ログアウト", () => setLogoutDialogVisible(true)) }
      { buildRow("退会", () => window.location.href = "/account-close") }
      </>
      }
    </div>
  </>;
}