import { FC, useState } from "react";
import { PageHeader } from "../ui_parts/PageHeader";
import { Colors } from "../Colors";
import { SubscriptionStatus } from "../model/SubscriptionStatus";
import { openTelecomCardPage } from "../services/TelecomService";
import { fetchAsync, postAsync } from "../services/ApiHandler";
import toast, { Toaster } from "react-hot-toast";
import { ProgressIndicator } from "../ui_parts/ProgressIndicator";
import { TranResponse } from "../model/TranResponse";
import { TranStatus } from "../model/TranStatus";

export const SubscriptionPage: FC = () => {
  const [planName, setPlanName] = useState(undefined as string | undefined);
  const [planId, setPlanId] = useState(undefined as string | undefined);
  const [price, setPrice] = useState(undefined as string | undefined);
  const [processing, setProcessing] = useState(false);

  function buildConfirmDialog() {
    return <>
      <div id="ageConfirmDialog" className="dialog-overlay">
        <div className='dialog text-center'>
          { processing &&
            <div className="text-center mb-6">
              <div>購入処理中です</div>
              <div>しばらくおまちください...</div>
              <ProgressIndicator></ProgressIndicator>
            </div>
          }
          { !processing &&
          <>
            <div className="font-bold text-lg mb-4">アイテム購入</div>
            <div className="flex w-full justify-between pb-2 items-end" style={{ borderBottom : `1px solid ${Colors.Border}` }}>
              <span className="text-sm">購入アイテム</span>
              <span>{planName}</span>
            </div>
            <div className="mt-2 flex w-full justify-between pb-2 items-end" style={{ borderBottom : `1px solid ${Colors.Border}` }}>
              <span className="text-sm">価格</span>
              <span>{price} 円</span>
            </div>
            <div onClick={purchase} className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-6'>
              購入する
            </div>
            <div onClick={onCancel} className="color-dim text-sm mt-4 mb-2">
              キャンセル
            </div>
          </>
          }
        </div>
      </div>
    </>
  }

  async function purchase(){
    if(processing) {
      return;
    }

    try{
      setProcessing(true);
      const param = { planName: planId };
      var response = await postAsync<TranResponse>('subscription/PurchaseSubscription', param);
      if(response.code === 1) {
        // 購入完了通知は非同期で行われるためポーリングする
        const tranParam = { tranId: response.data!.tranId };
        for(var i = 0; i < 15; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));

          var tranResponse = await fetchAsync<TranStatus>("subscription/GetTranStatus", tranParam);
          if(tranResponse.status === 'complete') {
            toast.success("購入が完了しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.href = "/home";
            return;
          }

          if(tranResponse.status === 'failed') {
            toast.error("購入に失敗しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            setProcessing(false);
            break;
          }
        }
        setProcessing(false);
        onCancel();
      } else {
        setProcessing(false);
        toast.error(response.message ?? 'エラーが発生しました');
      }
    } catch(e) {
      toast.error("エラーが発生しました");
      setProcessing(false);
      onCancel();
    }
  }

  function setItem(price: string, name: string, planId: string) {
    setPlanName(name);
    setPrice(price);
    setPlanId(planId);
  }
  
  function onCancel(){
    setPlanName(undefined);
    setPrice(undefined);
    setPlanId(undefined);
  }

  return<>
    <Toaster></Toaster>
    <PageHeader title="ベーシックプラン"></PageHeader>
    { planName !== undefined &&
      buildConfirmDialog()
    }
    <div className="px-6">
      <div className="text-sm">マッチングしたすべてのお相手とメッセージのやりとりができます。</div>
      <div onClick={() => setItem("23,800", "ベーシックプラン(6ヶ月)", "basic_plan_06")} className="flex rounded-[4px] mt-2" style={{ border: `1px solid ${Colors.Border}` }}>
        <div className="py-2 px-4 text-center w-[132px]" style={{backgroundColor : '#25405A', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}>
          <div className="text-white text-lg font-bold">6ヶ月</div>
          <div className="text-white text-sm">プラン</div>
          <div className="text-[#DF3266] py-1 px-2 bg-white rounded-[24px] text-sm font-bold">21%OFF</div>
        </div>
        <div className="w-full self-center text-center">
          <div className="text-center align-middle">
            <span className="text-lg font-bold">¥3,967/月</span>
            <span className="text-center text-sm color-dim ml-2">¥23,800</span>
          </div>
          <div className="py-1 px-2 text-sm rounded-[24px] bg-main text-white w-fit margin-center mt-1">¥5,600のお得</div>
        </div>
      </div>
      <div onClick={() => setItem("12,900", "ベーシックプラン(3ヶ月)", "basic_plan_03")} className="flex rounded-[4px] mt-4" style={{ border: `1px solid ${Colors.Border}` }}>
        <div className="py-2 px-4 text-center w-[132px]" style={{backgroundColor : '#25405A', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'}}>
          <div className="text-white text-lg font-bold">3ヶ月</div>
          <div className="text-white text-sm">プラン</div>
          <div className="text-[#DF3266] py-1 px-2 bg-white rounded-[24px] text-sm font-bold">11%OFF</div>
        </div>
        <div className="w-full self-center text-center">
          <div className="text-center align-middle">
            <span className="text-lg font-bold">¥4,300/月</span>
            <span className="text-center text-sm color-dim ml-2">¥12,900</span>
          </div>
          <div className="py-1 px-2 text-sm rounded-[24px] bg-main text-white w-fit margin-center mt-1">¥1,800のお得</div>
        </div>
      </div>
      <div onClick={() => setItem("4,900", "ベーシックプラン(1ヶ月)", "basic_plan_01")} className="flex rounded-[4px] mt-4" style={{ border: `1px solid ${Colors.Border}` }}>
        <div className="flex px-4 w-[132px] h-[76px] self-center justify-center" style={{ backgroundColor : '#F4F5F7', color: '#25405A',borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}>
          <div className="self-center">
            <div className="text-lg font-bold">1ヶ月</div>
            <div className="text-sm">プラン</div>
          </div>
        </div>
        <div className="w-full self-center text-center">
          <div className="text-center ">
            <span className="text-lg font-bold">¥4,900</span>
          </div>
        </div>
      </div>
      <div className="text-sm color-dim mt-4">
        デートの確定は保証しておりません。デートをリクエストする際の料金は別途発生いたします。
      </div>
      <div className="text-sm color-dim mt-4">
        決済に使用するクレジットカードの情報を変更する場合は<span className="color-main underline" onClick={openTelecomCardPage}>こちら</span>から
      </div>
      <div className="whitespace-pre-line text-sm mt-6 font-light bg-[#F4F5F7] py-2 px-2 mb-6 rounded-[4px]">
        注意事項
        <ul className="ml-4">
          <li className="list-disc">ベーシックプランは、マッチング後のメッセージが送り放題になるオプションサービスです。</li>
          <li className="list-disc">ご購入時のお支払いはご登録頂いた支払い方法に請求されます。</li>
          <li className="list-disc">更新日前に解約のお手続きをなさった場合、残りの期間についての日割りでの返金はいたしません。</li>
          <li className="list-disc">自動継続課金は期間の期限が切れる24時間以内に自動で行われます。</li>
        </ul>
      </div>

    </div>
    
  </>;
}
