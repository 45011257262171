import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { FootPrint } from "../../model/FootprintItem";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { PageHeader } from "../../ui_parts/PageHeader";
import { AccountInfo } from "../../model/AccountInfo";
import dayjs from "dayjs";
import { GoodStatus } from "../../model/GoodStatus";
import toast, { Toaster } from "react-hot-toast";
import { ProfilePage } from "../ProfilePage";

export const FootprintPage: FC = () => {
  const [footprintList, setFootprintList] = useState(undefined as FootPrint[] | undefined);

  async function fetchFootptint() {
    var response = await fetchAsync<FootPrint[]>("Footprint/FootprintList", null);
    setFootprintList(response);
  }

  function formatDate(dateString: string | undefined) : string {
    if(dateString === undefined) {
      return "";
    }

    var date = dayjs(dateString!);
    return date.format("M/D HH:mm");
  }

  async function sendGood(accountId: string) {
    var param = { AccountTo: accountId };
    var response = await postAsync<GoodStatus>("Good/SendGood", param);
    if(response.code === 1) {
      toast.success("いいねをおくりました");
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }

    fetchFootptint();
  }

  function onClickGood(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, footprint: FootPrint) {
    e.stopPropagation();
    sendGood(footprint.account?.accountId!);
  }

  function buildButton(footprint: FootPrint) {
    const classText = "bg-main text-white rounded-[40px] py-2 px-6 font-bold";
    if(footprint.hasGood && footprint.sentGood) {
      // あえて表示してないはず
      return <></>;
    } else if(footprint.hasGood) {
      return <span onClick={(e) => onClickGood(e, footprint)} className={classText}>ありがとう</span>
    } else if(footprint.sentGood === false) {
      return <span onClick={(e) => onClickGood(e, footprint)} className={classText}>いいね</span>
    } else {
      return <></>;
    }
  }

  useEffect(() => {
    fetchFootptint();
  },[]);

  
  function selectAccount(accountId: string){
    window.location.href = `/profile?id=${accountId}`
  }


  return <>
  <Toaster />
  <PageHeader title="足あと"></PageHeader>
  { footprintList === undefined &&
    <ProgressIndicator></ProgressIndicator>
  }
  { footprintList?.map((e) => (
    <>
    <div onClick={() => selectAccount(e.account?.accountId!)} className="flex px-6 py-2 border-bottom">
      <img className="w-[60px] h-[60px] rounded-[30px]" src={e.account?.profileImageList![0].url} />
      <div className="flex justify-between items-center w-full">
        <div className="ml-2">
          <div className="text-sm">{formatDate(e.time)}</div>
          <div className="font-bold">{e.account?.nickname}</div>
          <div className="text-sm">{e.account?.prefecture}・{e.account?.age}</div>
        </div>
        <div>
          {buildButton(e)}
        </div>
      </div>
    </div>
    </>
  ))}
  </>
}