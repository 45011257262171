import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../services/ApiHandler";
import { PageHeader } from "../ui_parts/PageHeader";
import { useLocation } from "react-router";
import { AccountInfo } from "../model/AccountInfo";
import toast, { Toaster } from "react-hot-toast";
import { sleep } from "../services/SleepService";
import { DateRequest } from "../model/DateRequest";
import dayjs from "dayjs";

export const BackoutReportPage: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [dateRequest, setDateRequest] = useState(undefined as DateRequest | undefined);

  const [processing, setProcessing] = useState(false);
  const [detail, setDetail] = useState("");
  const query = new URLSearchParams(useLocation().search);

  const targetAccountId = query.get('id') as string;
  const dateRequestId = query.get('date-request-id') as string;

  async function fetchAccountInfo() {
    const param = { accountId: targetAccountId };
    var response = await fetchAsync<AccountInfo>("Recommend/SingleAccount", param);
    setAccount(response);
  }

  async function fetchDateRequset() {
    const param = { dateRequestId: dateRequestId };
    var response = await fetchAsync<DateRequest>("DateRequest/GetSingle", param);
    setDateRequest(response);
  }

  async function sendReport(){
    if(processing){
      return;
    }

    setProcessing(true);

    const param = { 
      dateRequestId: dateRequestId,
      body: detail,
    };

    var response = await postAsync<AccountInfo>("BackoutReport/SendReport", param);
    if (response.code === 1) {
      toast.success("送信しました");
      await sleep(1000);
      window.history.back();
    } else {
      setProcessing(false);
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  useEffect(() => {
    fetchAccountInfo();
    fetchDateRequset();
  },[]);

  return <>
    <Toaster />
    <PageHeader title="ドタキャン報告" />
    { (account !== undefined && dateRequest !== undefined) &&
    <div className="px-4 mt-2">
      <div className="text-lg">ドタキャン報告</div>
      <div className="mt-4">報告するユーザー</div>
      <div className="flex mt-2 items-center">
        <img src={account.profileImageList![0].url} className="circle-60"></img>
        <div className="ml-2">
          <div>{account.nickname}</div>
          <div>{account.age}</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="text-lg mb-2">対象のデート</div>
        <div>日時: { dayjs(dateRequest.dayOfDate).format("YYYY/M/D")  }</div>
      </div>
      <div className="mt-4">
        <div className="text-lg mb-2">備考</div>
        <textarea value={detail} onChange={(e) => setDetail(e.target.value)} className="border-all h-[160px] w-full rounded-[4px] py-2 px-2"></textarea>
      </div>
      <div className="mt-2 text-sm px-4 py-4 rounded-[4px] whitespace-pre-line" style={{ backgroundColor: "#E5E5E5" }}>
      {`ドタキャン報告を頂いてから弊社のサポートチームからお相手に確認を取ります。
    お相手からの異議申し立てが無い、あるいは一定期間返答が無い場合に${account.gender === 'woman' ? 'デートポイントは返還されます' : 'ペナルティが免除されます' }。
    お相手からの異議申しててがあった場合は内容のやりとりを当社にて確認させて頂いた上で判断させて頂きます。`}
      </div>
      <div className="mt-6 pb-16">
        <button onClick={sendReport} disabled={processing} className="button w-full hover-button">この内容で報告する</button>
      </div>
    </div>
    }

  </>;
}