import { FC, useEffect, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { GoodInventory } from "../../model/GoodInventory";
import { OptionItem } from "../../model/OptionItem";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import { sleep } from "../../services/SleepService";
import { logout } from "../../services/CookieHandler";

export const AccountCloseConfirmPage: FC = () => {
  const [reason, setReason] = useState("");
  const [improvePoint, setImprovePoint] = useState("");
  const [additionalFunction, setAdditionalFunction] = useState("");
  const [otherComment, setOtherComment] = useState("");
  const [processing, setProcessing] = useState(false);
  
  async function close(){
    var param = {
      reasonCategory: reason,
      improvePoint: improvePoint,
      additionalFunction: additionalFunction,
      otherComment: otherComment
    };

    const response = await postAsync("AccountCloseRequest/CloseAccount", param);
    if(response.code === 1) {
      logout();
      toast.success("退会処理が完了しました");
      await sleep(2000);
      window.location.href = "/sign-in";
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  return <>
    <PageHeader title="退会手続き" />
    <Toaster/>
    <div className="pt-2 pb-10 px-4">
      <div className="font-bold text-lg">退会手続きのご確認</div>
      <div className="mt-2">退会すると現在保有する全てのポイントを失効します。また再登録された場合には、自己紹介文や写真、いいねなどのデータを引き継ぐことはできません。</div>
      <div className="font-bold text-lg mt-4 mb-2">退会手続き</div>
      { OptionItem.closeReasonTypes.map((e) => 
        <div className="flex py-1" onClick={() => setReason(e.code!)}>
          <img src={reason === e.code ? "/images/radio_selected.svg" : "/images/radio_not_selected.svg"}></img>
          <span className="ml-2">{e.label}</span>
        </div>
      )}
      
      <div className="mt-4">RapidDateに改善して欲しいポイントを教えてください</div>
      <textarea className="border-all w-full rounded-[4px] mrt-2 h-[100px] py-2 px-2" style={{resize: "none"}} value={improvePoint} onChange={(e) => setImprovePoint(e.target.value)}></textarea>
      <div className="mt-4">RapidDataに欲しかった機能などあれば教えてください</div>
      <textarea className="border-all w-full rounded-[4px] mrt-2 h-[100px] py-2 px-2" style={{resize: "none"}} value={additionalFunction} onChange={(e) => setAdditionalFunction(e.target.value)}></textarea>
      <div className="mt-4">そのほか、ご意見ご感想</div>
      <textarea className="border-all w-full rounded-[4px] mrt-2 h-[100px] py-2 px-2" style={{resize: "none"}} value={otherComment} onChange={(e) => setOtherComment(e.target.value)}></textarea>
      <button disabled={processing} onClick={close}  className="button mt-6 w-full hover-button">退会する</button>
    </div>
  </>;
}