import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { PageHeader } from "../../ui_parts/PageHeader";

export const HiddbenListPage: FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const [type, setType] = useState(query.get('type') === null ? 'hidden' : query.get('type') as string);
  const [list, setList] = useState<AccountInfo[]>([]);

  async function fetchList(){
    const api = type === 'hidden' ? 'HiddenAccountList' : 'BlockedAccountList';
    const url = `BlockAndHidden/${api}`;
    const response = await fetchAsync<AccountInfo[]>(url, null);
    setList(response);
  }

  async function removeHiddenAccount(accountId: string) {
    const param = { AccountId : accountId };
    await postAsync("BlockAndHidden/RemoveHiddenAccount", param);
    fetchList();
  }

  useEffect(() => {
    fetchList();
  },[]);
  
  return <>
    <PageHeader title={ type === "hidden" ? "非表示ユーザー" : "ブロックユーザー" } />
    { list.map((e) =>
    <>
      <div className="flex w-full border-bottom justify-between py-2 px-2 items-center">
        <div className="flex">
          <img className="circle-60" src={e.profileImageList![0].url}></img>
          <div className="ml-3 self-center">
            <div>{e.nickname}</div>
            <div>{e.age}</div>
          </div>
        </div>
        { type === 'hidden' &&
          <div onClick={(i) => removeHiddenAccount(e.accountId!)} className="mr-4 bg-main text-white font-white font-bold py-1 px-4 rounded-[24px] h-[32px]">非表示解除</div>
        }
      </div>
    </>
    )}

  </>
}