import { Colors } from "../Colors";

export interface InnerTabProps {
    title: string;
    name: string;
    selectedItem: string;
    onClick: (name: string) => void;
 }

export const InnerTab: React.FC<InnerTabProps> = ({ title, name, selectedItem, onClick }) => {
  return (
    <span
      style={{
        flex: '0 0 50%',
        boxSizing: "border-box",
        color: selectedItem === name ? Colors.Main : "black",
        borderBottom: selectedItem === name ? `3px solid ${Colors.Main}`: "none",
        fontWeight: selectedItem === name ? "700" : "400",
      }}
      onClick={() => onClick(name)}
      className="w-[50%] text-center pb-2 px-8">{title}
    </span>
  );
};