import { FC, useEffect, useState } from "react";
import { Colors } from "../../Colors";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import { Prefecture } from "../../model/Prefecture";
import { base64ToFile } from "../../services/ImageService";
import { postAsync, postFileAsync } from "../../services/ApiHandler";
import { ImageSelector } from "../../ui_parts/ImageSelector";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { getAccountId, setIsProfileComplete } from "../../services/CookieHandler";
import { OptionItem } from "../../model/OptionItem";
import { read } from "fs";

export const RegisterAccountPage: FC = () => {
  const [step, setStep] = useState("gender");
  const [gender, setGender] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [nickname, setNickname] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [processing, setProcessing] = useState(false);
  const [imageSelectorVisible, setImageSelectorVisible] = useState(false);
  const [reward, setReward] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [accountId, setAccountId] = useState(getAccountId());
  let isFirstTime = true;

  const AffiliateScript = () => {
    var shortAccountId = accountId.replace("rpd_", "");
    useEffect(() => {
      // スクリプトタグを動的に作成
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `(function acsTrack(){
var PV = "phs0cgb0sj7q";
var _ARGSV = "${shortAccountId}";
var KEYS = {cid : ["CL_", "ACT_", "cid_auth_get_type"], plid : ["PL_", "APT_", "plid_auth_get_type"]};
var turl = "https://matching-affi.jp/track.php?p=" + PV + "&args=" + _ARGSV;
var cks = document.cookie.split("; ").reduce(function(ret, s){ var kv = s.split("="); if(kv[0] && kv[1]) ret[kv[0]] = kv[1]; return ret; }, []);
turl = Object.keys(KEYS).reduce(function(url, k){ var vk = KEYS[k][0] + PV; var tk = KEYS[k][1] + PV; var v = "", t = ""; if(cks[vk]){ v = cks[vk]; if(cks[tk]) t = cks[tk]; }else if(localStorage.getItem(vk)){ v = localStorage.getItem(vk); t = "ls"; } if(v) url += "&" + k + "=" + v; if(t) url += "&" + KEYS[k][2] + "=" + t; return url; }, turl);
var xhr = new XMLHttpRequest(); xhr.open("GET", turl); xhr.send(); })();`;
  
      // bodyの最後にスクリプトを追加
      document.body.appendChild(script);

      // meta conversion
      const metaScript = document.createElement('script');
      metaScript.type = 'text/javascript';
      metaScript.innerHTML = `fbq('track', 'ViewContent', {
  value: 'meta-cv-20240808',
});`;
  
      // bodyの最後にスクリプトを追加
      document.body.appendChild(metaScript);
  
      // クリーンアップ関数でスクリプトタグを削除
      return () => {
        // document.body.removeChild(script);
      };
    }, []);
  
    return null; // このコンポーネント自体は何もレンダリングしない
  };
  
  
  async function uploadProfileImage(imageData: string){
    if(processing) { return; }
    setProcessing(true);
    const params = new FormData();
    var file = base64ToFile(imageData, "filename");
    
    params.append("file", file!);

    setImageSelectorVisible(false);
    const url = "Account/UploadProfileImage";
    const response = await postFileAsync(url, params);
    if(response.code === 1) {
      setIsImageUploaded(true);
      await registerAccount();
    } else {
      setProcessing(false);
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  async function registerAccount() {
    const param = {
      nickname: nickname,
      birthday: `${year}/${month}/01`,
      gender: gender,
      prefecture: prefecture,
      reward: reward === '' ? null : parseInt(reward),
    };
    
    const response = await postAsync("Account/RegisterAccountProfile", param);
    if(response.code === 1) {
      setIsProfileComplete();
      window.location.href = "/home";
    } else {
      setProcessing(false);
      toast.error(response.message ?? "エラーが発生しました");
    }
  }
  
  function setBirthday() {
    const intYear = parseInt(year);
    const intMonth = parseInt(month);
    if(isNaN(intYear) || isNaN(intMonth)) {
      toast.error("入力が正しくありません");
      return;
    }
    if(intMonth < 1 || intMonth > 12){
      toast.error("月の入力が正しくありません");
      return;
    }

    const now = dayjs();
    const yearMax = now.year() - 18;
    const yearMin = now.year() - 80;
    if (intYear < yearMin || intYear > yearMax) {
      toast.error("年齢の制限を超えています");
      return;
    }

    setYear(intYear.toString());
    setMonth(intMonth.toString());
    setStep("nickname");
  }

  useEffect(() => {
  },[]);

  function goToNextFromGender(){
    if(gender === '') {
      return;
    }

    if(gender === 'man') {
      setStep('birthday');
    } else {
      setStep('reward');
    }
  }


  return <>
  <Toaster/>
  <AffiliateScript></AffiliateScript>
  <div className="pt-4 px-4">
    { step === "gender" &&
      <>
        <div className="text-xl font-bold">あなたの性別を教えてください</div>
        <div className="mt-2">プロフィールに表示されます。<br/>マッチングの参考にする情報です。</div>
        <div className="flex justify-between mt-8">
          <span className="button" onClick={() => setGender("man")} style={{ width: "47%", backgroundColor: gender === 'man' ? Colors.Main : Colors.Dim }}>男性</span>
          <span className="button" onClick={() => setGender("woman")} style={{ width: "47%", backgroundColor: gender === 'woman' ? Colors.Main : Colors.Dim }}>女性</span>
        </div>
        <div className="mt-8">
          <span onClick={e => goToNextFromGender() } className="button w-full">次へ</span>
        </div>
      </>
    }
    { step === "reward" &&
      <>
        <div className="text-xl font-bold">デートの報酬を設定してください</div>
        <div className="mt-2">ラピッドデートではデートに行く度に報酬が発生します。</div>
        <div className="mt-4">
          { OptionItem.datePriceList.map((e) => 
            <div className="flex pb-1" onClick={() => setReward(e.code!)}>
              <img className="mr-2" src={ reward === e.code ? "/images/radio_selected.svg" : "/images/radio_not_selected.svg" }></img>
              <span className="text-lg font-bold" style={{ color: reward === e.code ? Colors.Main : Colors.TextBlack } } >{e.label}</span>
            </div>
          )}
        </div>
        <div className="mt-8">
        <span onClick={e => {if(reward !== '') { setStep("birthday") }} } className="button w-full">次へ</span>
        </div>
      </>
    }
    { step === "birthday" &&
      <>
        <div className="text-xl font-bold">あなたの誕生日を教えてください</div>
        <div className="mt-2">誕生日から割り出したあなたの年齢が<br/>プロフィールに表示されます。</div>
        <div className="mt-4 inline-flex items-end">
          <input type="number" value={year} onChange={(e) => setYear(e.target.value)} className="py-2 px-2 w-[160px] mr-1" style={{ borderBottom: `2px solid ${Colors.Main}` }}></input><span>年</span>
          <input type="number" value={month} onChange={(e) => setMonth(e.target.value)} className="ml-4 py-2 px-2 w-[100px] mr-1" style={{ borderBottom: `2px solid ${Colors.Main}` }}></input><span>月</span>
          
        </div>
        <div className="mt-8">
          <span onClick={e => setBirthday() } className="button w-full">次へ</span>
        </div>
      </>
    }
    { step === "nickname" &&
      <>
        <div className="text-xl font-bold">あなたのニックネームを教えてください</div>
        <div className="mt-2">プロフィールに表示する名前です</div>
        <div className="mt-4">
          <input type="text" placeholder="ニックネーム" value={nickname} onChange={(e) => setNickname(e.target.value)} className="py-2 px-2 w-full mr-1" style={{ borderBottom: `2px solid ${Colors.Main}` }}></input>
        </div>
        <div className="mt-8">
        <span onClick={e => {if(nickname !== '') { setStep("area") }} } className="button w-full">次へ</span>
        </div>
      </>
    }
    { step === "area" &&
      <>
        <div className="text-xl font-bold">あなたがデートで行きたい<br/>エリアを選んでください</div>
        <div className="mt-2">エリアが近い人をマッチングすることが可能です。</div>
        <div className="mt-4 pb-24">
          { Prefecture.prefectureOptions.map((e) => 
            <div className="flex pb-1" onClick={() => setPrefecture(e.code!)}>
              <img className="mr-2" src={ prefecture === e.code ? "/images/radio_selected.svg" : "/images/radio_not_selected.svg" }></img>
              <span className="text-lg font-bold" style={{ color: prefecture === e.code ? Colors.Main : Colors.TextBlack } } >{e.label}</span>
            </div>
          )}
        </div>
        <div className="fixed bottom-0 w-[92%] max-w-[420px] mx-0 bg-white">
          <div className="border-bottom"></div>
          <div className="pt-4 pb-6">
          <span onClick={e => {if(prefecture !== '') { setStep("picture") }} } className="button w-full">次へ</span>
          </div>
        </div>
      </>
    }
    { step === "picture" &&
      <>
        <div className="text-xl font-bold">あなたの写真を設定してください</div>
        <div className="mt-2">プロフィールに表示されます。<br/>ご自身の顔が写っている写真をご登録ください。</div>
        <div onClick={() => setImageSelectorVisible(true)} className="flex mt-6 font-bold border-all h-[160px] w-[160px] rounded-[4px] justify-center items-center self-center margin-center">
          <span>写真を登録する</span>
        </div>
        <div className="mt-8">以下のようなあなたの雰囲気が伝わるお写真だとマッチしやすい傾向にあります。</div>
        <div className="flex justify-around mt-8">
          <img className="w-[140px]" src="/images/signup-sample-woman.jpg" style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}></img>
          <img className="w-[140px]" src="/images/signup-sample-man.jpg" style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}></img>
        </div>
        { !processing && imageSelectorVisible &&
          <ImageSelector onCancel={() => setImageSelectorVisible(false)} onSelected={(e) => uploadProfileImage(e)} />
        }
        { processing &&
          <ProgressIndicator />
        }

        <div className="mt-4">
        </div>

      </>
    }
  </div>
  <script></script>
  </>
}