// import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import dayjs from "dayjs";
import { ApiKey } from '../model/ApiKey';
let cookie = require('cookie');

class AccountToken {
  AccountId: string | undefined;
  Role : string | undefined;
  exp: number | undefined;
}

export function getIsLoggedIn() : boolean {
  return  Cookies.get('secret') !== undefined;
}

export function setIsProfileComplete() {
  Cookies.set('profile-complete', "true", { expires: 3000 } );
}

export function getIsProfileComplete() : boolean {
  return  Cookies.get('profile-complete') !== undefined;
}

export function logout() {
  Cookies.remove('key');
  Cookies.remove('secret');
  Cookies.remove('accountId');
  Cookies.remove('profile-complete');
}

export function saveApiKey(key:string, secret: string, accountId: string) {
  Cookies.set('key', key, { expires: 3000 } );
  Cookies.set('secret', secret, { expires: 3000 } );
  Cookies.set('accountId', accountId, { expires: 3000 } );
}

export function getApiKey() : ApiKey {
  return {
    key: Cookies.get('key'),
    secret: Cookies.get('secret'),
    accountId: Cookies.get('accountId')
  };
}

export function getAccountId() : string {
  return Cookies.get('accountId')!;
}

export function getEamil(): string | undefined {
  return Cookies.get('email');
}

export function saveEmail(email: string): void {
  Cookies.set('email', email, { expires: 720 } );
}
