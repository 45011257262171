import { FC, useState } from "react";
import { SearchInnnerTab } from "./SearchInnerTab";
import { InnerTab } from "../../ui_parts/Tab";
import { GoodFromMeTab } from "./GoodFromMeTab";
import { GoodToMeTab } from "./GoodToMeTab";

export const GoodTab: FC = () => {
  const [selectedTab, setSelectedTab] = useState('from-me');

  function onSelect(name: string) {
    setSelectedTab(name);
  }

  return <>
  <div className="flex fixed pt-4 box-border px-4 bg-[#fff] w-full max-w-[450px]">
    <InnerTab title="相手から" onClick={(e) => onSelect("from-me")} name="from-me" selectedItem={selectedTab} />
    <InnerTab title="自分から" onClick={(e) => onSelect("to-me")} name="to-me" selectedItem={selectedTab} />
  </div>
    { selectedTab === 'from-me' &&
        <>
        <div className="h-[56px]"></div>
        <GoodToMeTab></GoodToMeTab>
        </>
    }
    { selectedTab === 'to-me' &&
        <>
        <div className="h-[56px]"></div>
        <GoodFromMeTab></GoodFromMeTab>
        </>
    }
  </>
}