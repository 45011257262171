
import { FC, useState } from "react";
import { OptionItem } from "../model/OptionItem";
import { Colors } from "../Colors";
import { selectClasses } from "@mui/material";

export interface MultiOptionSelectorProps {
  title: string;
  values: string[] | undefined;
  optionItems: OptionItem[];
  onSelected: (values: string[]) => void;
  onCancel: () => void;
  maxCount?: number;
}

export const MultiOptionSelector: FC<MultiOptionSelectorProps> = ({ title, values, optionItems, onSelected, onCancel, maxCount }) => {
  const [selectedValues, setSelectedValues] = useState(values as string[]);

  function onSelect(value: string) {
    const contains = selectedValues.filter((e) => e === value).length >= 1;
    if(contains) {
      const newValues = selectedValues.filter((e) => e !== value);
      setSelectedValues(newValues);
    } else {
      if (maxCount !== undefined && selectedValues.length >= maxCount) {
        return;
      }

      const newValues = [...selectedValues];
      newValues.push(value);
      setSelectedValues(newValues);
    }
  }

  function contains(value: string) {
    return selectedValues.filter((e) => e === value).length >= 1;
  }

  return <> 
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
      <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
        <div className="sticky top-0 justify-between bg-white pt-4">
          <span className="text-lg">{title}</span>
        </div>
        <div className="mt-2"></div>
        { optionItems.map((e) =>
          <div className="flex py-2 justify-start" onClick={(elem) => onSelect(e.code!)}>
            <img className="w-[24px] mr-2" src={contains(e.code!) ? "/images/icon/icons8-checked-checkbox.svg": "/images/icon/icons8-unchecked-checkbox.svg"}></img>
            <span style={{ color: contains(e.code!) ? Colors.Main : Colors.TextBlack, fontWeight: contains(e.code!) ? "700" : "400" }  }>{e.label}</span>
          </div>
        )}
        <div className="sticky flex bottom-0 justify-end bg-white pb-4 items-center">
          <span onClick={onCancel} className="text-sm color-dim">キャンセル</span>
          <span onClick={(e) => onSelected(selectedValues)} className="ml-9">決定</span>
        </div>
      </div>
    </div>
  </>
}