import dayjs, { Dayjs } from "dayjs";
import { AccountInfo } from "./AccountInfo";

export class DateRequestSet{
  futureList: DateRequestAndAccount[] | undefined;
  oldList: DateRequestAndAccount[] | undefined;
}

export class DateRequest {
  dateRequestId: string | undefined;
  senderAccountId: string | undefined;
  dayOfDate: string | undefined;
  createdDate: string | undefined;
  dateStartTime: number | undefined;
  dateStartMinutes: number | undefined;
  status: string | undefined;
  isPaymentTried: number | undefined;
  chatRoomId: string | undefined;
  location: string | undefined;
  expiry: string | undefined;
  paymentExpiry: string | undefined;
  datePoint: number | undefined;
  datePrice: number | undefined;
  datePriceWithTax: number | undefined; 

 static getExpiryText(request: DateRequest) : string {
    console.log(request.expiry);
    var date = dayjs(request.expiry);
    return `${date.format("YYYY/MM/DD HH:mm")} まで`;
  }

  static getTimeText(request: DateRequest) : string {
    return request.dateStartTime == null ? "" : `${request.dateStartTime}:${request.dateStartMinutes!.toString().padEnd(2, '0')} ~`;
  }

  /*
  getDateTimeTextNoTitle() : string {
    var timeText = this.dateStartTime == null ? "" : "$dateStartTime:${dateStartMinutes.toString().padLeft(2, '0')} ~";
    var dateText = DateUtil.toMdJpWithWeekDay(dayOfDate!);
    return "$dateText $timeText";
  }


  getDateTimeText() : string {
    var timeText = dateStartTime == null ? "" : "$dateStartTime:${dateStartMinutes.toString().padLeft(2, '0')} ~";
    var dateText = DateUtil.toMdJpWithWeekDay(dayOfDate!);
    return "日時 : $dateText $timeText";
  }

  getStatusText() : string {
    if (status == DateRequestStatus.REQUESTED) {
      return "確認待ち";
    } else if (status == DateRequestStatus.ACCEPTED) {
      return "確定";
    } else if (status == DateRequestStatus.CANCELED) {
      return "キャンセル";
    } else if (status == DateRequestStatus.REJECTED) {
      return "キャンセル";
    } else if (status == DateRequestStatus.DONE) {
      return "終了";
    } else if (status == DateRequestStatus.PAID) {
      return "終了";
    } else {
      return "";
    }
  }
  */

  static hasPlace(request: DateRequest) : boolean{
    return request.location != null && request.location !== '';
  }

  static getPlaceText(request: DateRequest) : string {
    return request.location == null || request.location === "" ? "" : `場所: ${request.location}`;
  }

}

export class DateRequestAndAccount {
  dateRequest: DateRequest | undefined;
  targetAccount: AccountInfo | undefined;
}