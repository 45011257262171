import dayjs from 'dayjs';
import { FC, useState, useEffect, useRef, useDebugValue } from "react";
import { ChatMessage } from "../../model/ChatMessage";
import { fetchAsync, postAsync, postFileAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { ActiveChatRoom } from "../../model/ActiveChatRoom";
import { Colors } from "../../Colors";
import { getAccountId } from "../../services/CookieHandler";
import { DateRequest } from "../../model/DateRequest";
import { NewDateRequestPage } from './NewDateRequestDialog';
import toast, { Toaster } from "react-hot-toast";
import { getWeekString } from '../../services/DateUtil';
import { AcceptDateRequestManPage } from './AcceptDateRequestManDialog';
import { AcceptDateRequestWomanPage } from './AcceptDateRequestWomanDialog';
import { SubscriptionStatus } from '../../model/SubscriptionStatus';
import { ProgressIndicator } from '../../ui_parts/ProgressIndicator';
import { InputImage, useGetImageUrl } from '../../ui_parts/InputImage';
import { openTelecomCardPage } from '../../services/TelecomService';
import { DateRequestStatus } from '../../DateRequestStatus';
import { useLocation } from 'react-router';
import { AccountMenuDialog } from '../../ui_parts/AccountMenuDialog';
import { IsNullOrEmpty } from '../../services/StringUtil';
import { UnaryExpression } from 'typescript';

export const ChatPage: FC = () => {
  const myAccountId = getAccountId();
  const [subscriptionStatus, setSubscriptionStatus] = useState(undefined as SubscriptionStatus | undefined);
  const [chatMessage, setChatMessage] = useState("" as string);
  const [chatRoom, setChatRoom] = useState(null as ActiveChatRoom | null);
  const [dateaRequestDialogVisible, setDateaRequestDialogVisible] = useState(false);
  const [rejectindDateRequestId, setRejectindDateRequestId] = useState('');
  const [selectedDateRequest, setSelectedDateRequest] = useState(undefined as DateRequest | undefined);
  const [targetAccount, setTargetAccount] = useState<AccountInfo | undefined>(undefined);
  const [amIMan, setAmIMan] = useState<boolean | undefined>(undefined);
  const [menuVisible, setMenuVisible] = useState(false);


  const query = new URLSearchParams(useLocation().search);
  const targetAccountId = useRef(query.get('id') === null ? '' : query.get('id') as string);
  const isInside = useRef(query.get('inside') !== null);

  var isSending = false;
  
  useEffect(() => {
    fetchSingleAccount();
    fetchSubscriptionStatus();
    fetchChatRoom();
  }, []);

  function thereIsActiveRoundRequest(): boolean {
    if(chatRoom?.dateRequestList?.length === 0) {
      return false;
    }
    if (chatRoom?.latestDateRequestStatus === "REQUESTED" || chatRoom?.latestDateRequestStatus === "ACCEPTED" || chatRoom?.latestDateRequestStatus === "WAIT_PAYMENT") {
      return true;
    } else {
      return false;
    }
  }

  function autoGrow(element: React.FormEvent<HTMLTextAreaElement>) {
    const elem = element.target as HTMLTextAreaElement;
    elem.style.height = "5px";
    elem.style.height = (elem.scrollHeight) + "px";
  }

  function markAsChecked(chatRoomId: string) {
    const param = { chatRoomId: chatRoomId };
    postAsync<ActiveChatRoom>("ChatRoom/MarkAsChecked", param);
  }

  async function fetchSingleAccount() {
    const param = { accountId: targetAccountId.current };
    var response = await fetchAsync<AccountInfo>("Recommend/SingleAccount", param);
    setTargetAccount(response);
    setAmIMan(response.gender === 'woman');
  }
  
  async function fetchSubscriptionStatus() {
    var response = await fetchAsync<SubscriptionStatus>("subscription/GetStatus", null);
    setSubscriptionStatus(response);
  }

  async function fetchChatRoom() {
    var param = {
      TargetAccountId: targetAccountId.current,
    };

    var response = await fetchAsync<ActiveChatRoom>("ChatRoom/ActiveChatRoom", param);

    markAsChecked(response.chatRoomId!);

    var lastDate = ""
    var index = 0;
    for(const item of response.chatMessageList!) {
      const date = item.createdDate?.substring(0,10);
      if(lastDate !== date) {
        var message = { messageType: 'day', message: date?.replaceAll('-', '/') } as ChatMessage;
        response.chatMessageList?.splice(index, 0, message);
        lastDate = date!;
      }
      index += 1;
    }

    setChatRoom(response);
    setTimeout(() => {
      window.scrollTo(0,1000000);
    }, 10);
  }

  async function sendChatMessage() {
    if(isSending) { return; }
    if(chatMessage === '') { return; }
    
    isSending = true;
    var body = {
      'ChatRoomId': chatRoom?.chatRoomId,
      'Message': chatMessage
    };

    try{
      var result = await postAsync("Chat/SendMessage", body);
      setChatMessage('');
      fetchChatRoom();
      isSending = false;
      if (result.code !== 1) {
        toast.error(result.message ?? "エラーが発生しました");
      }
    } catch(e) {
      console.log(e);
      isSending = false;
      toast.error("エラーが発生しました");
    }
  }

  function pageBack() {
    if(isInside.current === true) {
      window.history.back();
    } else {
      window.location.href = "/home?tag=chat";
    }
  }

  function onBack(){
    if(isInside) {
      window.history.back();
    } else {
      window.location.href = "/home?tag=chat";
    }
  }

  function hideDateRequestDialog(isUpdated: boolean) {
    setDateaRequestDialogVisible(false);
    if(isUpdated) {
      fetchChatRoom();
    }
  }

  function showDateRequestDialog() {
    setDateaRequestDialogVisible(true);
  }

  function showDateRequestDialogToReject(dateRequestId: string) : void {
    setRejectindDateRequestId(dateRequestId);
    setDateaRequestDialogVisible(true);
  }

  function hideAccepDialog(isUpdated: boolean) {
    setSelectedDateRequest(undefined);
    if(isUpdated) {
      fetchChatRoom();
    }
  }

  function getActiveDateRequest() : DateRequest | undefined {
    if (IsNullOrEmpty(chatRoom?.latestDateRequestId))
      return undefined;

    return chatRoom?.dateRequestList?.filter((e) => e.dateRequestId == chatRoom.latestDateRequestId)[0];
  }

  const textAreaPadding = thereIsActiveRoundRequest() ? "56px" : "100px";

  return <>
    <Toaster />
    <AccountMenuDialog dateRequest={getActiveDateRequest()} visible={menuVisible} account={targetAccount!} onClose={() => setMenuVisible(false)} />
    { (subscriptionStatus === undefined || chatRoom === undefined) &&
      <ProgressIndicator></ProgressIndicator>
    }
    { dateaRequestDialogVisible &&
      <NewDateRequestPage
        chatRoomId={chatRoom?.chatRoomId!}
        targetAccount={targetAccount!}
        onBack={(e) => hideDateRequestDialog(e)}
        rejectindDateRequestId={rejectindDateRequestId} />
    }
    { (selectedDateRequest && amIMan) &&
      <AcceptDateRequestManPage targetAccount={targetAccount!} request={selectedDateRequest} onBack={(e) => hideAccepDialog(e)} />
    }
    { (selectedDateRequest && !amIMan) &&
      <AcceptDateRequestWomanPage targetAccount={targetAccount!} request={selectedDateRequest} onBack={(e) => hideAccepDialog(e)} />
    }
    { subscriptionStatus?.ageConfirmStatus === 'PENDING' && 
      <PendingDialog onBack={onBack} ></PendingDialog>
    }
    { (targetAccount !== undefined  && subscriptionStatus?.ageConfirmStatus === 'UNCONFIRMED' && !amIMan ) && 
      <AgeConfirmDialogForWoman room={chatRoom!} targetAccount={targetAccount!} onBack={onBack} ></AgeConfirmDialogForWoman>
    }
    { (targetAccount !== undefined  && subscriptionStatus?.ageConfirmStatus === 'UNCONFIRMED' && amIMan ) && 
      <AgeConfirmDialogForMan room={chatRoom!} targetAccount={targetAccount!} onBack={onBack} ></AgeConfirmDialogForMan>
    }
    { (targetAccount !== undefined  && subscriptionStatus?.isActive === false && subscriptionStatus.ageConfirmStatus === 'CONFIRMED') && 
      <SubscriptionDialog room={chatRoom!} targetAccount={targetAccount!} onBack={onBack}></SubscriptionDialog>
    }
    { (subscriptionStatus?.isActive === true && subscriptionStatus.ageConfirmStatus === 'CONFIRMED') && 
    <div id="chatRoom" className="bg-[#f3f3f3] w-full top-0 max-w-[450px] min-h-[100%] overflow-scroll z-10">
      <div id="header" className="py-2 flex fixed top-0 items-center justify-center bg-[#fff] w-full max-w-[450px]">
        <img onClick={pageBack} src="/images/icon/icons8-back.svg" className="absolute left-[24px] h-[24px]" />
        <img src={targetAccount?.profileImageList![0].url} className="w-[40px] h-[40px] rounded-[30px]"></img>
        <span className="ml-2 text-lg">{targetAccount?.nickname}</span>
        <img onClick={() => setMenuVisible(true)} className="absolute right-4 top-4 w-[20px]" src="/images/other.svg"></img>
      </div>
      { (chatRoom && targetAccount) &&
        <div id="messageArea" className="mt-16 pt-2 min-h-[90vh]" style={{ paddingBottom: textAreaPadding }}>
          { chatRoom.chatMessageList!.map((e) =>
            <ChatBubble chat={e}
              myAccountId={myAccountId}
              targetAccount={targetAccount!}
              chatRoom={chatRoom} 
              onRejectingRequest={(requestId) => showDateRequestDialogToReject(requestId)}
              onAcceptingRequest={(request) => setSelectedDateRequest(request)} />
          )}
        </div>
      }
      {
        <>
        <div id="textArea" className="fixed w-full bottom-0 bg-white max-w-[450px] z-20">
          { !thereIsActiveRoundRequest() &&
            <div className="flex bg-main text-white justify-center py-2" onClick={showDateRequestDialog}>
              <img src="/images/icon/icons8-love-white.svg" className="h-[24px]"></img>
              <span className="ml-1 font-bold">デートの提案をする</span>
            </div>
          }
          <div className="flex py-4">
            <textarea value={chatMessage} onChange={(e) => setChatMessage(e.target.value)} onInput={(e) => autoGrow(e)} className="min-h-[32px] px-3 ml-2 w-[100%] rounded-[32px] h-[32px]" style={{ fontSize: '16px', border: '1px solid gray', resize: 'none' }}>
            </textarea>
            <img onClick={sendChatMessage} className="ml-2 mr-4 h-[32px]" src="/images/icon/icons8-send.svg"></img>
          </div>
        </div>
        </>
      }
    </div>
    }
  </>
}

export interface SubscriptionDialogProps {
  room: ActiveChatRoom;
  targetAccount: AccountInfo;
  onBack: () => void;
}

function goToSubscriptionPage(){
  window.location.href = "/subscription";
}

export const SubscriptionDialog: FC<SubscriptionDialogProps> = ({ room, targetAccount, onBack }) => {
  return <>
    <div id="ageConfirmDialog" className="dialog-overlay">
      <div className='dialog text-center'>
        <div className='text-lg font-bold mb-2'>サブスクリプションのご案内</div>
        <div>{targetAccount.nickname}さんが</div>
        <div>あなたのメッセージを待っています</div>
        <div className='flex justify-center mt-2 mb-4'>
          <img src={targetAccount.profileImageList![0].url} className='h-[80px] w-[80px] rounded-[40px]' />
        </div>
        <div>メッセージを送るには<br/>サブスクリプションへの加入が必要です。</div>
        <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-8' onClick={goToSubscriptionPage}>
          プラン内容を見る
        </div>
        <div className='color-dim text-sm' onClick={onBack}>キャンセル</div>
      </div>
    </div>
  </>;
}


export interface PendingDialogProps {
  onBack: () => void;
}

export const PendingDialog: FC<PendingDialogProps> = ({ onBack }) => {
  return <>
    <div id="ageConfirmDialog" className="dialog-overlay">
    <div className='dialog text-center'>
      <div>現在書類の確認中です。<br/>もうしばらくお待ちください。</div>
      <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={onBack}>閉じる</div>
    </div>
  </div>
  </>;
}

export interface AgeConfirmDialogProps {
  room: ActiveChatRoom;
  targetAccount: AccountInfo;
  onBack: () => void;
}

export const AgeConfirmDialogForWoman: FC<AgeConfirmDialogProps> = ({ room, targetAccount, onBack }) => {
 const [documentAreaVisible, setDocumentAreaVisible] = useState(false);
 const [imageSelectVisible, setImageSelectVisible] = useState(false);
 const [uploading, setUploading] = useState(false);
 
 const fileInputRef = useRef<HTMLInputElement>(null);
 const [imageFile, setImageFile] = useState<File | null>(null);
 const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
  if (e.currentTarget?.files && e.currentTarget.files[0]) {
    const targetFile = e.currentTarget.files[0];
    setImageFile(targetFile);
  }
};

const { imageUrl } = useGetImageUrl({ file: imageFile });

function showImageDialog() {
  setImageSelectVisible(true);
}

async function uploadImage() {
  if(uploading) {
    return;
  }
  try{
    setUploading(true);
    const url = "AgeConfirm/UploadAccountDocument";
    const params = new FormData();
    params.append("file", imageFile!);
    var result = await postFileAsync(url, params);
    if(result.code !== 1) {
      toast.error(result.message ?? "アップロードに失敗しました");
    } else {
      toast.success("アップロードしました。確認までしばらくお待ちください");
      onBack();
    }
    setUploading(false);
  } catch(e) {
    toast.error("エラーが発生しました");
  }
}

 return <>
 { imageSelectVisible &&
  <>
    <div id="ageConfirmDialog" className="dialog-overlay">
     <div className='dialog text-center'>
     <div className='mb-3 font-bold text-lg'>年齢確認書類を選択</div>
     <div className="flex justify-center">
     <label
        htmlFor="account-document"
        className='h-[300px] w-[300px] flex rounded-[12px] justify-center'
        style={{ border: "1px dotted gray",  alignItems: "center", overflow: "hidden", cursor: "pointer" }}>
        { imageUrl && imageFile ? (
          <img src={imageUrl} style={{ objectFit: "contain", width: "100%", height: "100%" }}/>
        ) : ( <div>画像を選択</div> )}
        <InputImage ref={fileInputRef} id="account-document" onChange={handleFileChange}
     />
      </label>
    </div>
    <div style={{ height: 24 }} />
    { !uploading &&
      <>
      { imageFile  &&
        <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mb-6' onClick={uploadImage}>アップロードする</div>
      }
      <div>
        <button className='color-dim tex-sm' onClick={() => {setImageSelectVisible(false); setImageFile(null); }}>キャンセル</button>
      </div>
      </>
    }
    { uploading &&
      <div>
        <div>アップロード中....</div>
        <ProgressIndicator></ProgressIndicator>
      </div>
    }
    </div>
  </div>
  </>
 }
 { (imageSelectVisible === false && documentAreaVisible) &&
   <div id="ageConfirmDialog" className="dialog-overlay">
     <div className='dialog text-center'>
      <div>アップロードする書類を選択してください</div>
      <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={showImageDialog}>運転免許証</div>
       <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={showImageDialog}>保険証</div>
       <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={showImageDialog}>パスポート</div>
       <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={showImageDialog}>マイナンバーカード</div>
       <div className="mt-4">氏名・生年月日 がはっきり見える画像の<br/>アップロードをお願いしております。</div>
       <div className='color-dim text-sm mt-8' onClick={(e) => setDocumentAreaVisible(false)}>キャンセル</div>
     </div>
   </div>
 }
 { ( targetAccount !== undefined && imageSelectVisible === false && !documentAreaVisible) &&
   <div id="ageConfirmDialog" className="dialog-overlay">
     <div className='dialog text-center'>
       <div className='text-lg font-bold mb-2'>年齢確認が必要です</div>
       <div>{targetAccount.nickname}さんが</div>
       <div>あなたのメッセージを待っています</div>
       <div className='flex justify-center mt-2 mb-4'>
         <img src={targetAccount!.profileImageList![0].url} className='h-[80px] w-[80px] rounded-[40px]' />
       </div>
       <div onClick={(e) => setDocumentAreaVisible(true)} className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1'>
        書類で年齢確認
       </div>
       <div className='color-dim text-sm'>確認に少しお時間がかかります</div>

       <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-1' onClick={openTelecomCardPage}>
        カードで年齢確認
       </div>
       <div className='color-dim text-sm'>すぐに確認が完了します</div>
       <div className='color-dim mb-6 text-sm'>※請求は発生しません</div>
       <div className='color-dim text-sm' onClick={onBack}>キャンセル</div>
     </div>
   </div>
  }
 </>
}

export const AgeConfirmDialogForMan: FC<AgeConfirmDialogProps> = ({ room, targetAccount, onBack }) => {
  return <>
    <div id="ageConfirmDialog" className="dialog-overlay">
      <div className='dialog text-center'>
        <div className='text-lg font-bold mb-2'>年齢確認が必要です</div>
        <div>{targetAccount!.nickname}さんが</div>
        <div>あなたのメッセージを待っています</div>
        <div className='flex justify-center mt-2 mb-4'>
          <img src={targetAccount!.profileImageList![0].url} className='h-[80px] w-[80px] rounded-[40px]' />
        </div>
        <div className='text-sm'>RapidDateではクレジットカードの登録により<br/>18才以上であることを確認しています。</div>
        <div className='bg-main text-white font-bold py-2 px-4 w-[200px] margin-center rounded-[40px] mt-4 mb-2' onClick={openTelecomCardPage}>
          カードで年齢確認
        </div>
        <div className='color-dim mb-6 text-sm'>※年齢確認時点では請求は発生しません</div>
        <div className='color-dim text-sm' onClick={onBack}>キャンセル</div>
      </div>
    </div>
  </>
}

// chatBubble
export interface ChatBubbleProps {
  chat: ChatMessage;
  myAccountId: string;
  targetAccount: AccountInfo;
  chatRoom: ActiveChatRoom;
  onRejectingRequest: (requestId: string) => void;
  onAcceptingRequest: (request: DateRequest) => void;
}

export const ChatBubble: FC<ChatBubbleProps> = ({ chat, myAccountId, targetAccount, chatRoom, onAcceptingRequest, onRejectingRequest }) => {
  const isMyMessage = chat.accountId === myAccountId;
  const bubbleColor = isMyMessage ? Colors.Main : Colors.White;
  const textColor =  isMyMessage ? Colors.White : Colors.TextBlack;
  const iconUrl = chat.accountId === 'bot' ? "https://rapid-date.com/app/rapid-icon-white.png" : targetAccount.profileImageList![0].url;
  const timeString = dayjs(chat.createdDate).format("H:mm");
  const icon = isMyMessage ? <></> : 
    <img src={iconUrl} className="h-[40px] w-[40px] rounded-[40px]"></img>

  function getExpiryText(request: DateRequest) : string {
    var expiry = dayjs(request.expiry);
    return `${expiry.format("M/D H:mm")}まで`;
  }

  function getPaymentExpiryText(request: DateRequest) : string {
    var expiry = dayjs(request.paymentExpiry);
    return `${expiry.format("M/D H:mm")}まで`;
  }

  function getDayOfDateText(request: DateRequest) : string {
    const timeText = DateRequest.getTimeText(request);
    const dayOfdate = dayjs(request.dayOfDate);
    const dayText = dayOfdate.format("M/D");
    return `日時: ${dayText}(${getWeekString(dayOfdate)}) ${timeText}`;
  }

  function openCounterPage(dateRequest: DateRequest) {
    window.location.href = `/backout-counter?id=${targetAccount.accountId!}&date-request-id=${dateRequest.dateRequestId}`;
  }

  if(chat.accountId === 'system') {
    return <>
    <div className="flex justify-center mb-2">
      <div className="text-center bg-[#E9ECEF] px-6 py-1 rounded-[4px] w-[80%] font-bold text-sm">{chat.message}</div>
    </div>
    </>
  }

  var contents = <></>;
  if(chat.messageType === 'text' || chat.messageType === 'complete_payment') {
    contents = <div className='py-1 px-2'>{chat.message}</div>;
  } else if(chat.messageType === 'offer') {
    
    const dateRequest = chatRoom.dateRequestList?.filter((e) => e.dateRequestId === chat.dateRequestId)[0]!;
    var actionButtonVisible = dateRequest.status === 'REQUESTED' && dateRequest.senderAccountId !== myAccountId;

    var actionArea = <></>;
    if(actionButtonVisible) {
      actionArea = <>
        <div className='flex w-full justify-evenly py-2' style={{ borderTop: `1px solid ${Colors.Border}` }}>
          <div className='flex items-center' onClick={(e) => onRejectingRequest(chat.dateRequestId!)}>
            <span color='color-dim'>変更</span>
            <img src="/images/close-gray.svg" className='w-[22px]'></img>
          </div>
          <div className='w-[1px] bg-gray-300 h-[32px]'></div>
          <div className='flex items-center' onClick={(e) =>  onAcceptingRequest(dateRequest)}>
            <span className='color-main'>OK</span>
            <img src="/images/icon/icons8-love-main-48.svg" className='w-[22px]'></img>
          </div>
        </div>
      </>;
    }

    contents = <>
      <div className="pb-2 py-1 px-2" style={{ borderBottom: `1px solid ${isMyMessage ? '#fff' : Colors.Border}` }}>
        {chat.message}
      </div>
      <div className="pt-2 pl-2">
        <div className="text-lg">{getDayOfDateText(dateRequest)}</div>
        { dateRequest?.location !== '' &&
          <div>{`場所: ${dateRequest.location}`}</div>
        }
        <div className='pb-2'>
          <span>返信期限: </span>
          <span>{getExpiryText(dateRequest)}</span>
        </div>
        {actionArea}
      </div>
    </>;
  } else if (chat.messageType == 'payment_request') {
    const dateRequest = chatRoom.dateRequestList?.filter((e) => e.dateRequestId === chat.dateRequestId)[0]!;
    var actionButtonVisible = dateRequest.status === DateRequestStatus.WAIT_PAYMENT;


    var actionArea = <></>;
    if(actionButtonVisible) {
      actionArea = <>
        <div className='flex w-full justify-evenly py-2' style={{ borderTop: `1px solid ${Colors.Border}` }}>
          <div className='flex items-center' onClick={(e) =>  onAcceptingRequest(dateRequest)}>
            <span className='color-main'>お支払いをする</span>
          </div>
        </div>
      </>;
    }

    if(dateRequest.status == DateRequestStatus.PAYMENT_EXPIRED) {
      actionArea = <>
      <div className='flex w-full justify-evenly py-2' style={{ borderTop: `1px solid ${Colors.Border}` }}>
        <div className='flex items-center'>
          <span className='color-dim'>期限切れ</span>
        </div>
      </div>
    </>;
    }

    contents = <>
    <div className='mx-2 text-sm' style={{ borderBottom: `1px solid ${isMyMessage ? '#fff' : Colors.Border}` }}>
      {chat.message}
    </div>
    <div className="pt-2 pl-2">
        <div className="text-lg">{getDayOfDateText(dateRequest)}</div>
        { dateRequest?.location !== '' &&
          <div>{`場所: ${dateRequest.location}`}</div>
        }
        <div className='pb-2'>
          <span>支払期限: </span>
          <span>{getPaymentExpiryText(dateRequest)}</span>
        </div>
        {actionArea}
      </div>
    </>;
  } else if (chat.messageType == 'backout_report') {
    const dateRequest = chatRoom.dateRequestList?.filter((e) => e.dateRequestId === chat.dateRequestId)[0]!;

    var actionArea = <>
      <div className='flex w-full justify-evenly py-2' style={{ borderTop: `1px solid ${Colors.Border}` }}>

        <div  className='flex items-center' onClick={(e) => openCounterPage(dateRequest)}>
          <span className=''>異議申し立てをする</span>
        </div>
      </div>
    </>;

    contents = <>
    <div className='mx-2 text-sm'>
      {chat.message}
    </div>
    <div className="pt-2 pl-2">
        {actionArea}
      </div>
    </>;
  }

  const body = <>
  { chat.messageType === 'day' &&
    <div className="text-sm flex justify-center mb-2 color-dim text-[0.9rem]">
      {chat.message}
    </div>
  }
  { (chat.messageType === 'text' || chat.messageType === 'complete_payment' || chat.messageType === 'offer' || chat.messageType === 'payment_request' || chat.messageType === 'backout_report') && 
    <>
    { isMyMessage === false && 
    <div className="flex">
      <div className="ml-2">{icon}</div>
      <div className="max-w-[70%] ml-2 mr-1 text-[0.9rem]" style={{ height: "min-content", backgroundColor: bubbleColor, color: textColor, borderRadius: '0px 6px 6px 6px', whiteSpace: "break-spaces" }}>
        { chat.visibleOnly === myAccountId &&
        <div className='color-dim text-sm ml-2 mt-1 mb-1 flex' style={{ borderBottom: `1px solid ${Colors.Border}` }}>
          <img src="images/secret.svg" className='h-[20px] mb-1'></img>
          あなたにだけ表示されてます。
        </div>
        }
        {contents}
      </div>
      <div className="text-xs color-dim">
        {timeString}
      </div>
    </div>
    }
    { isMyMessage && 
    <div className="flex justify-end">
      <div className="max-w-[70%] mr-2 text-[0.9rem]" style={{ backgroundColor: bubbleColor, color: textColor, borderRadius: '6px 0px 6px 6px', whiteSpace: "break-spaces" }}>
        {contents}
      </div>
      <div className="text-xs color-dim mr-2">
        {timeString}
      </div>
    </div>
    }
    </>
  }
</>;

  return <div className="mb-3">
    {body}
  </div>;
}
