import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { fetchAsync, postWithoutSign } from "../services/ApiHandler";
import { LineAuthResponse } from "../model/LineAuthResult";
import { saveApiKey, setIsProfileComplete } from "../services/CookieHandler";
import { AccountInfo } from "../model/AccountInfo";
import { ProgressIndicator } from "../ui_parts/ProgressIndicator";
import Cookies from "js-cookie";

export const LineAuthPage: FC = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [processing, setProcessing] = useState(true);
  const [lineFriendButtonVisible, setLineFriendButtonVisible] = useState(false);
  const lineAuthCode = query.get('code');
  const lineRedirectUrl = `${window.location.origin}/line-auth`;

  useEffect(() => {
    tryLingLogin();
  },[]);

  async function tryLingLogin() {
    if (lineAuthCode === '' || lineAuthCode === null){
      return;
    }

    var body = {
      'code': lineAuthCode,
      'redirectUrl': lineRedirectUrl,
      'utmSource': Cookies.get('utm_source') ?? "",
      'utmCampaign': Cookies.get('utm_campaign') ?? "",
      'utmMedium': Cookies.get('utm_medium') ?? "",
      'utmTerm': Cookies.get('utm_term') ?? "",
      'utmContent': Cookies.get('utm_content') ?? "",
    };

    var response = await postWithoutSign<LineAuthResponse>("LineAuth/LineSignInForWeb", body);

    if (response.code !== 1 && response.code !== 10002) {
      window.location.href = '/sign-in';
      return;
    }

    Cookies.remove('utm_source');
    Cookies.remove('utm_campaign');
    Cookies.remove('utm_medium');
    Cookies.remove('utm_term');
    Cookies.remove('utm_content');

    if(response.data?.isFirend === true){
      const data = response.data!;
      saveApiKey(data.key!, data.apiSecret!, data.accountId!);
      var accountInfo = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
      if (accountInfo!.isProfileComplete == 1) {
        setIsProfileComplete();
        window.location.href = '/home';
      } else {
        window.location.href = "/register-account";
      }
    } else {
      setProcessing(false);
      setLineFriendButtonVisible(true);
    }
    
  }

  return <>
  <div className="flex flex-col items-center">
    { processing &&
    <div className="h-[160px] mt-20">
      Loading....
      <ProgressIndicator />
    </div>
    }
    { lineFriendButtonVisible && 
      <>
        <div className="mt-8 text-center">
          <div>
            <img className="w-[200px]" src="/images/logo-side.png"></img>
          </div>
          <div className="text-sm color-dim mt-4">RapidDateをご利用頂くには<br/>友だち追加が必要です。</div>
          <div className="text-center flex justify-center mt-4">
            <a href="https://lin.ee/EL0NTZi">
              <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" className="h-[40px]" />
            </a>
          </div>
        </div>
      </>
    }
  </div>
  </>
}