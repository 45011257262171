import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { ActivePointStatus } from "../../model/ActivePointStatus";
import { PageHeader } from "../../ui_parts/PageHeader";
import { TextEditor } from "../../ui_parts/TextEditor";
import toast, { Toaster } from "react-hot-toast";

export const BankoutPage: FC = () => {
  const [pointStatus, setPointStatus] = useState<ActivePointStatus | undefined>(undefined);
  const [amount, setAmount] = useState("");
  const [amountVisible, setAmountVisible] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    fetchActivePoint();
  },[]);

  async function fetchActivePoint() {
    const response = await fetchAsync<ActivePointStatus>("PaymentRequest/ActivePoint", null);
    setPointStatus(response);
    setAmount(response.withdrawableMoney!.toString());
  }

  async function sendRequest() {
    if(processing){ return; }

    setProcessing(true);
    const param = { point: amount };
    const response = await postAsync("PaymentRequest/Request", param);
    setProcessing(false);
    if(response.code === 1) {
      toast.success("申請を受け付けました");
      fetchActivePoint();
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  return <>
  <Toaster />
  <PageHeader title="振込申請"></PageHeader>
  { pointStatus &&
    <div className="text-center px-4">
      <div className="text-xl font-bold mb-2">{pointStatus.activeMoney} 円</div>
      <div className="mb-8">振込申請可能額: {pointStatus.withdrawableMoney} 円</div>
      <div className="border-bottom"></div>
      <div className="flex justify-between border-bottom px-2 py-2">
        <span>振込手数料</span>
        <span>{pointStatus.fee}円</span>
      </div>

      <TextEditor onCancel={() => setAmountVisible(false)} title="振込金額" value={amount} isNumber={true} isSingleLine={true}
          onUpdate={(e) => {setAmount(e); setAmountVisible(false); }} visible={amountVisible} />

      <div onClick={() => setAmountVisible(true)} className="flex justify-between border-bottom px-2 py-2">
        <span>振込金額</span>
        <span>{amount}円</span>
      </div>

      <div className="mt-2 color-dim text-sm text-left">
      ・振込先が間違っている場合、再度振込手数料が発生します。<br/>
・年齢確認時に登録された氏名と振込口座名義が一致しない場合、振込申請を行うことはできません。<br/>
・振込口座はご本人名義の口座のみご利用をいただけます<br/>
・振込は以下のスケジュールの通りです。<br/>
  <span className="ml-4">- 毎月15日までの申請分:月末最終営業日まで</span><br/>
  <span className="ml-4">- 毎月月末までの申請分:翌月15日まで</span><br/>
      </div>
      <button disabled={processing} onClick={sendRequest} className="w-full py-2 rounded-[40px] bg-main mb-8 text-white text-center text-lg font-bold mt-4 hover-button">申請</button>
    </div>
  }
  </>;
}