import { FC } from "react";


export const CardCompletePage: FC = () => {

  
  return <>
    <div className="content-div text-center" style={{ font: "SF Pro Text" }}>
      <span className="main-text text-center">年齢確認</span>
      <div className="height-16"></div>
      <div className="border"></div>
      <div className="height-16"></div>
      <div className="flex justify-center">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M66.666 39.9998C66.666 25.2998 54.6993 13.3332 39.9993 13.3332C25.2994 13.3332 13.3327 25.2998 13.3327 39.9998C13.3327 54.6998 25.2994 66.6665 39.9993 66.6665C54.6993 66.6665 66.666 54.6998 66.666 39.9998ZM6.66602 39.9998C6.66602 21.5998 21.5993 6.6665 39.9993 6.6665C58.3993 6.6665 73.3327 21.5998 73.3327 39.9998C73.3327 58.3998 58.3993 73.3332 39.9993 73.3332C21.5993 73.3332 6.66602 58.3998 6.66602 39.9998ZM56.6662 34.9665L39.1662 52.4665C38.4995 53.1332 37.6662 53.4332 36.7995 53.4332C35.9329 53.4332 35.0995 53.0998 34.4329 52.4665L24.2995 42.3332C22.9995 41.0332 22.9995 38.9332 24.2995 37.6332C25.5995 36.3332 27.6995 36.3332 28.9995 37.6332L36.7662 45.3998L51.9329 30.2332C53.2329 28.9332 55.3329 28.9332 56.6329 30.2332C57.9329 31.5332 57.9662 33.6665 56.6662 34.9665Z" fill="#25405A"></path>
        </svg>
      </div>
      <div className="height-16"></div>
      <div className="main-text text-center">クレジットカード登録及び年齢確認が完了しました</div>
      <div className="height-16"></div>
      <div className="small-text">ご協力ありがとございます。</div>
      <div className="height-16"></div>
      <div className="alert-area">
        <div className="flex justify-center">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6673 13.3332V9.33317C22.6673 5.65317 19.6806 2.6665 16.0007 2.6665C12.3207 2.6665 9.33398 5.65317 9.33398 9.33317V13.3332H8.00065C6.62565 13.3332 5.48503 14.3879 5.34782 15.7282C5.33867 15.8175 5.33398 15.9082 5.33398 15.9998V26.6665C5.33398 28.1332 6.53398 29.3332 8.00065 29.3332H24.0007C25.4673 29.3332 26.6673 28.1332 26.6673 26.6665V15.9998C26.6673 14.5332 25.4673 13.3332 24.0007 13.3332H22.6673ZM20.0007 13.3332H12.0007V9.33317C12.0007 7.13317 13.8007 5.33317 16.0007 5.33317C18.2007 5.33317 20.0007 7.13317 20.0007 9.33317V13.3332ZM24.0007 15.9998V26.6665H8.00065V15.9998H24.0007ZM14.6673 24.0265V22.3198C13.8673 21.8532 13.334 21.0132 13.334 20.0265C13.334 18.5598 14.534 17.3598 16.0007 17.3598C17.4673 17.3598 18.6673 18.5598 18.6673 20.0265C18.6673 21.0132 18.134 21.8532 17.334 22.3198V24.0265C17.334 24.7598 16.734 25.3598 16.0007 25.3598C15.2673 25.3598 14.6673 24.7598 14.6673 24.0265Z" fill="#25405A"></path>
          </svg>					
        </div>
        <p className="alert-text mt-1" style={{fontSize: "14px", textAlign: "center" }}>
          お客様情報は厳重に管理しております。
        </p>
        <p className="mt-2 text-left" style={{ fontSize: "12px" }}>
          クレジットカードの認証はテレコムクレジット株式会社の仕組みを使用し、行っております。登録頂いた情報はテレコムクレジット株式会社の高いセキュリティ体制にて管理致します。
        </p>
      </div>
      <div className="height-16"></div>
      <a href="/home" style={{ textDecoration: "none"}}>
        <div className="close-button py-2">
          ホームに戻る
        </div>
      </a>
    </div>
  </>;
}