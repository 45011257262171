import { FC, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { fetchAsync } from "../../services/ApiHandler";
import { AccountWithChatRoom, ChatRoomSummary } from "../../model/ChatRoomSummary";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import dayjs from "dayjs";
import { Colors } from "../../Colors";
import { ChatPage } from "../chat/ChatPage";
import { ProfilePage } from "../ProfilePage";
import { AccountInfo } from "../../model/AccountInfo";
import { NoItemArea } from "../../ui_parts/NoItemArea";

export const ChatListTab: FC = () => {
  const [roomList, setRoomList] = useState(null as AccountWithChatRoom[] | null);
  // const [chatRoom, setChatRoom] = useState(null as AccountWithChatRoom | null);
  // const [account, setAccount] = useState(null as AccountInfo | null);

  useEffect(() => {
    fetchChatRoomList();
  },[]);

  async function fetchChatRoomList() {
    var param = {  isMessaging: 1 };
    var list = await fetchAsync<AccountWithChatRoom[]>("ChatRoom/ChatRoomList", param);
    setRoomList(list);
  }

  function parseDate(date: string): string {
    const day = dayjs(date, "YYYYMMDDHHmmss");
    return day.format('M/D HH:mm');
  }

  function selectChatRoom(room: AccountWithChatRoom) {
    // setChatRoom(room);
    window.location.href = `/chat?inside=1&id=${room.account?.accountId!}`;
  }

  

  // function onBackFromChatRoom() {
  //   setChatRoom(null);
  // }

  function convertTag(tag: string): string {
    if(tag == "no-response") {
      return "未返信";
    } else if(tag == "date-accepted") {
      return "お約束済み";
    } else if(tag == "date-requested") {
      return "お誘い未返信";
    } else {
      return "";
    }
  }

  function getTagColor(tag: string) : string {
    if(tag == "no-response") {
      return "#686868";
    } else if(tag == "date-accepted") {
      return Colors.Main;
    } else if(tag == "date-requested") {
      return "orange";
    } else {
      return "#686868";
    }
  }

  function selectAccount(accountId: string) {
    window.location.href = `/profile?hide-action=1&id=${accountId}`
  }

return <>  
  <div className="mx-4 pt-4">
    <div className="text-xl ml-4 font-bold mb-2">メッセージ</div>
    { roomList === null &&
      <ProgressIndicator></ProgressIndicator>
    }
  { roomList?.length === 0 &&
      <NoItemArea title="現在ルームはありません" description="プロフィールを充実させて
あなたのことを知ってもらいましょう" linkUrl="/edit-profile" linkText="プロフィール画面を開く" />
  }
    { (roomList !== null) && roomList!.map((e) => (
      <div className="flex w-full justify-between mb-2">
        <img onClick={() => selectAccount(e.account!.accountId!) } src={e.account!.profileImageList![0].url} className="w-[80px] h-[70px] rounded-[40px]"></img>
        <div className="flex justify-between w-full" style={{ overflow: "hidden" }} onClick={(c) => selectChatRoom(e)}>
          <div className="ml-3" style={{ overflow: "hidden"}}>
            <div className="font-bold">{e.account!.nickname}</div>
            <div className="text-sm" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{e.chatRoomSummary?.lastMessage}</div>
            <div>
              { e.chatRoomSummary!.tagList!.map((t) => (
                <span className="mr-1 text-sm rounded-[12px] px-2" style={{ border: `1px solid ${getTagColor(t)}`, color: getTagColor(t) }}>{convertTag(t)}</span>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-end w-[80px] min-w-[80px]">
            <div className="text-sm" style={{ color: Colors.Dim}}>{ parseDate(e.chatRoomSummary?.lastMessageDate!) }</div>
            { e.chatRoomSummary!.latestMessageUid! >= e.chatRoomSummary!.myReadId! && 
              <div className="mt-1 bg-main h-[10px] w-[10px] rounded-[6px]">
              </div>
            }
          </div>
        </div>
      </div>
    ))}
  </div>
  </>
}