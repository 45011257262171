import { FC, useState } from "react";
import { PageHeader } from "../../ui_parts/PageHeader";
import { postWithoutSign } from "../../services/ApiHandler";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";

export const EmailInputPage: FC = () => {
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  
  async function register() {
    if(processing) {
      return;
    }

    setProcessing(true);
    const param = { email: email, utmSource: Cookies.get('utm_source') ?? "" };
    const response = await postWithoutSign("account/SendAccountToken", param);
    setProcessing(false);
    if(response.code !== 1) {
      toast.error(response.message!);
      return;
    } else {
      Cookies.set('email_signin', email, { expires: 2 } );
      window.location.href = `/email-confirm`;
    }
  }

  return <>
  <PageHeader title=""></PageHeader>
  <Toaster></Toaster>
  <div className="px-6">
    <div className="text-lg font-bold">メールアドレスを入力してください</div>
    <div className="mt-2">こちらのメールアドレスはデートの予定や重要はお知らせの送信先となります。</div>
    <div className="mt-4">
      <input onChange={(e) => setEmail(e.target.value)} type="text" className="py-3 px-2 border-all w-full rounded-[4px]" placeholder="sample@rapid-date.com" value={email}></input>
    </div>
    <div className="color-dim text-sm mt-1">後から変更することはできません。</div>
    <button disabled={processing} onClick={register} className="text-center w-full rounded-[24px] bg-main text-white font-bold mt-4 py-2 hover-button">次へ</button>
  </div>
  </>
}