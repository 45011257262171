import React, { FC, useEffect, useState } from "react";
import { AccountInfo } from "../model/AccountInfo";
import { Prefecture } from "../model/Prefecture";
import { Colors } from "../Colors";
import { OptionItem } from "../model/OptionItem";
import { fetchAsync, postAsync } from "../services/ApiHandler";
import { SelfIntroduction } from "../model/SelfIntroduction";
import { GoodStatus } from "../model/GoodStatus";
import toast, { Toaster } from "react-hot-toast";
import { AccountWithChatRoom } from "../model/ChatRoomSummary";
import { ChatPage } from "./chat/ChatPage";

export interface PickupDialogProps{
  account: AccountInfo;
  onClose: () => void;
  onSorry: () => void;
  onGood: () => void;
  index: number;
  totalCount: number;
}

export const PickupDialog: FC<PickupDialogProps> = ({ account, onClose, onSorry, onGood, index, totalCount }) => {
  const [selfIntroduction, setSelfIntroduction] = useState(undefined as string | undefined);
  const [imageIndex, setImageIndex] = useState(0);
  const [goodStatus, setGoodStatus] = useState(undefined as GoodStatus | undefined);
  const [processing, setProcesssing] = useState(false);
  
  async function sendGood() {
    if(processing) { return; }

    setProcesssing(true);
    var param = { AccountTo: account?.accountId };
    var response = await postAsync<GoodStatus>("Good/SendGood", param);
    
    if(response.code === 1) {
      toast.success("いいねをおくりました");
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
    
    onGood();
  }

  async function sendSorry() {
    onSorry();
  }

  async function fetchGoodStatus() {
    var param = { TargetAccount: account?.accountId };
    var response = await fetchAsync<GoodStatus>("Good/GoodStatus", param);
    setGoodStatus(response);
  }

  async function fecthSelfIntroduction() {
    var param = { accountId: account?.accountId };
    var response = await fetchAsync<SelfIntroduction>("SelfIntroduction/Introduction", param);
    setSelfIntroduction(response.body)
  }

  async function onClickImage(eve: React.MouseEvent<HTMLImageElement>) {
    const image = eve.target as HTMLImageElement;
    const rect = image.getBoundingClientRect();
    const imgWidth = rect.width;
    const clickX = eve.clientX - rect.left;

      if (clickX < imgWidth / 2) {
        const index = Math.max(0, imageIndex - 1);
        setImageIndex(index);
      } else {
        const index = Math.min(account.profileImageList!.length - 1, imageIndex + 1);
        setImageIndex(index);
      }
  }

  useEffect(() => {
    fecthSelfIntroduction();
    fetchGoodStatus();
  },[]);

  function openChatPage(){
    window.location.href = `/chat?inside=1&id=${account.accountId!}`;
  }

  function buildGoodButton() {
    if(goodStatus === undefined) {
      return <></>;
    }

    var content = <></>;
    const buttonStyle = "ml-4 w-[140px] font-bold rounded-[20px] text-center py-1 px-2 bg-main hover-button";
    if (goodStatus.goodFromMe !== null && goodStatus.goodToMe !== null) {
        content = <button disabled={processing} onClick={() => openChatPage() } className={buttonStyle} style={{ color: '#fff'}}>
        チャットする
        </button>;
    } else if (goodStatus.goodToMe !== null) {
      content = <button disabled={processing} onClick={sendGood} className={buttonStyle} style={{ color: '#fff'}}>
      ありがとう
      </button>;
    } else if (goodStatus.goodFromMe !== null) {
      content = <></>;
    } else if (goodStatus.goodFromMe === null) {
      content = <button disabled={processing} onClick={sendGood} className={buttonStyle} style={{ color: '#fff'}}>
        いいね！
      </button>;
    }

    return <>
      <div className="z-[300] fixed flex bottom-[${bottom}px] w-full justify-center max-w-[450px]" style={{ bottom: "80px" }}>
        <div className="flex">
         <button disabled={processing} onClick={sendSorry} className="w-[140px] font-bold bg-[#b2b2b2] rounded-[20px] text-center py-1 px-2" style={{ color: '#fff' }}>
          スキップ
         </button>
        {content}
      </div>
    </div>
    </>;
  }
  

  return <>
  { (goodStatus) &&
    buildGoodButton()
  }
  <Toaster></Toaster>
  <div id="pickupDialog" className="dialog-overlay">
    <div className='dialog' style={{ width: '100%', maxWidth: '450px', padding: "0px" }}>
      <div id="profile" className={`fixed w-full  max-w-[450px] bg-[#fff] max-h-[100%] min-h-[100%] overflow-scroll`}
        style={{ zIndex:  "0", top: "0px" }}>
        {
        <div>
          
          
          <div id="header" className="text-center py-3 sticky top-[0px] bg-white min-h-[100%]">
            <img onClick={onClose} className="absolute right-4 top-4 w-[20px]" src="/images/close.svg"></img>
            
            <div>
              <div className="text-lg font-bold">本日のピックアップ</div>
              <div className="mt-2 mb-2">
                <span className="bg-main text-white px-4 py-1 font-bold rounded-[24px]">{`いいね消費なし ${index} / ${totalCount} 人`}</span>
              </div>
              
            </div>
          </div>
          
          <div id="contents">
            <img src={account.profileImageList![imageIndex].url} onClick={onClickImage} className="w-full"></img>
            <div className="flex py-2 justify-center">
            { account.profileImageList!.length > 1 && account.profileImageList!.map((e, i) => (
                <>
                { i === imageIndex && 
                <span className="h-[8px] w-[8px] mr-2 rounded-[12px]" style={{ backgroundColor: Colors.Main }}></span>
                }
                { i !== imageIndex && 
                <span className="h-[8px] w-[8px] mr-2 bg-gray-500 rounded-[12px]"></span>
                }
                </>
              ))
            }
            </div>
            <div className="px-4">
              <div className="mt-2 text-xl mb-1">
                <span className=" mr-4">{account.nickname}</span>
              </div>
              { account.gender === 'woman' &&
                <>
                <span className="mr-1 font-bold">{account.datePrice}P</span>
                <span className="text-sm">/ 1デートあたりのポイント</span>
                </>
                }
              <div className="flex items-center">
                <span className="mr-3">{account.age}</span>
                <img src="images/area.svg" className="h-[16px]"></img>
                <span>{ Prefecture.getPrefectureName(account.prefecture) }</span>
              </div>
              { selfIntroduction &&
                <>
                  <div className="mt-2 text-lg">自己紹介</div>
                  <div className="text-sm whitespace-pre-line">{ selfIntroduction }</div>
                </>
              }
              <div className="mt-2 text-lg">基本プロフィール</div>
              <PrpfileRow name="年齢" value={account.age}></PrpfileRow>
              <PrpfileRow name="性別" value={OptionItem.getOptionName(account.gender, OptionItem.genderOptions)}></PrpfileRow>
              <PrpfileRow name="居住地" value={Prefecture.getPrefectureName(account.prefecture)}></PrpfileRow>
              <PrpfileRow name="出身地" value={Prefecture.getPrefectureName(account.birthPrefecture)}></PrpfileRow>
              <PrpfileRow name="血液型" value={OptionItem.getOptionName(account.bloodType, OptionItem.bloodTypes)}></PrpfileRow>
              <PrpfileRow name="16パーソナリティ" value={OptionItem.getOptionName(account.personality, OptionItem.personalityTypes)}></PrpfileRow>
              <PrpfileRow name="年収" value={OptionItem.getOptionName(account.income?.toString(), OptionItem.incomeTypes)}></PrpfileRow>
              <PrpfileRow name="身長" value={account.height === null ? undefined : `${account.height}cm`}></PrpfileRow>
              <PrpfileRow name="体型" value={OptionItem.getOptionName(account.bodyStyle, OptionItem.bodyStyleAllTypes)}></PrpfileRow>
              <PrpfileRow name="タバコ" value={OptionItem.getOptionName(account.smoking, OptionItem.smokingTypes)}></PrpfileRow>
              <PrpfileRow name="お酒" value={OptionItem.getOptionName(account.alcohol, OptionItem.alcoholTypes)}></PrpfileRow>
              <PrpfileRow name="学歴" value={OptionItem.getOptionName(account.school, OptionItem.schoolTypes)}></PrpfileRow>
              <PrpfileRow name="学校" value={account.schoolName}></PrpfileRow>
              <div className="h-[190px]"></div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
  </>
}

export interface PrpfileRowProps {
  name: string;
  value: string | undefined;
}

export const PrpfileRow: FC<PrpfileRowProps> = ({ name, value }) => {
  return <>
  {  value !== undefined && value !== '' && value !== null &&
    <div className="flex w-full justify-between py-2" style={{ borderBottom: `1px solid ${Colors.Border}`}}>
      <span style={{ color: Colors.Dim }}>{ name }</span>
      <span>{ value }</span>
    </div>
  }
  </>
}
