import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../services/ApiHandler";
import { PageHeader } from "../ui_parts/PageHeader";
import { useLocation } from "react-router";
import { AccountInfo } from "../model/AccountInfo";
import { AcccountReportOption } from "../model/AccountReportOption";
import toast, { Toaster } from "react-hot-toast";
import { sleep } from "../services/SleepService";

export const AccountReportPage: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [options, setOptions] = useState<AcccountReportOption[]>([]);
  const [processing, setProcessing] = useState(false);
  const [detail, setDetail] = useState("");
  const [category, setCategory] = useState("");
  const query = new URLSearchParams(useLocation().search);

  const targetAccountId = query.get('id') as string;

  async function fetchOptions() {
    const response = await fetchAsync<AcccountReportOption[]>("AccountReport/CategoryList");
    setOptions(response);
  }

  async function fetchAccountInfo() {
    const param = { accountId: targetAccountId };
    var response = await fetchAsync<AccountInfo>("Recommend/SingleAccount", param);
    setAccount(response);
  }

  async function sendReport(){
    if(processing){
      return;
    }

    setProcessing(true);

    const param = { 
      targetAccountId: targetAccountId,
      category: category,
      body: detail
    };

    var response = await postAsync<AccountInfo>("AccountReport/report", param);
    if (response.code === 1) {
      toast.success("送信しました");
      sleep(1000);
      window.history.back();
    } else {
      setProcessing(false);
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  useEffect(() => {
    fetchOptions();
    fetchAccountInfo();
  },[]);

  return <>
    <Toaster />
    <PageHeader title="通報" />
    { (account !== undefined && options !== undefined) &&
    <div className="px-4 mt-2">
      <div className="text-lg">通報するユーザー</div>
      <div className="flex mt-2 items-center">
        <img src={account.profileImageList![0].url} className="circle-60"></img>
        <div className="ml-2">
          <div>{account.nickname}</div>
          <div>{account.age}</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="text-lg mb-2">通報の種類</div>
        { options.map((e) =>
          <div onClick={() => setCategory(e.code)} className="flex py-1">
            <img src={ e.code === category ? "/images/radio_selected.svg" : "/images/radio_not_selected.svg" } />
            <span className="text-sm ml-2">{e.name}</span>
          </div>
        )}
      </div>
      <div className="mt-4">
        <div className="text-lg mb-2">詳しい通報内容</div>
        <textarea value={detail} onChange={(e) => setDetail(e.target.value)} className="border-all h-[160px] w-full rounded-[4px] py-2 px-2"></textarea>
      </div>
      <div className="mt-2 text-sm px-4 py-4 rounded-[4px]" style={{ backgroundColor: "#E5E5E5" }}>
        違反報告はかならずしも処置を講じる事を保証するものではありません。<br />
      虚偽の嫌がらせ目的および何度も繰り返し同じユーザーに違反報告をした場合、お客様の報告内容に対する信頼が損なわれる場合がございます。<br />
      内容に応じて違反対象者に注意勧告を行う場合があります。ただし、その場合は違反報告があった旨のみとして報告者の情報や具体的な報告内容は通知しません。<br />
      当社はお客様のご登録情報と違反報告の内容を警察に提供および通報する場合があります。<br />
      何らかの被害が及ぶ恐れや実際に何かしらの被害にあわれた場合は、速やかに最寄りの警察に相談してください。<br />
      </div>
      <div className="mt-6 pb-16">
        <button onClick={sendReport} disabled={processing} className="button w-full hover-button">この内容で通報する</button>
      </div>
    </div>
    }

  </>;
}