export function base64ToFile(base64: string, fileName: string): File {
  // Base64データ部分を抽出（先頭の "data:image/png;base64," などを削除）
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }

  // BlobをFileに変換
  return new File([u8arr], fileName, { type: mime });
}
