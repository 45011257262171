import { FC, useEffect, useState } from "react";
import { AppNotification } from "../../model/AppNotification";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { setMaxListeners } from "events";
import { PageHeader } from "../../ui_parts/PageHeader";
import dayjs from "dayjs";
import { HelpCategory } from "../../model/HelpCategory";
import { HelpResponse } from "../../model/HelpResponse";
import { HelpDetail } from "../../model/HelpDetail";


export const HelpPage: FC = () => {
  const [help, setHelp] = useState<HelpResponse | undefined>(undefined);
  
  async function fetchList() {
    const param = { platform : "ios" };
    const response = await fetchAsync<HelpResponse>("Help/CategoryAndDetails", param);
    setHelp(response);
  }

  useEffect(() => {
    fetchList();
  },[]);

  return <>
  <PageHeader title="ヘルプ" />
  <div className="border-bottom"></div>
  { help?.categories?.map((e) =>
    <HelpRow category={e} details={help.details!} ></HelpRow>
    )
  }
  <div className="mt-8 text-center pb-6" style={{ backgroundColor: "#25405A" }}>
    <div className="text-white font-bold pt-4 pb-4">ヘルプで解決しない場合</div>
    <a href="inquiry">
      <div>
        <span className="bg-white py-2 px-8 rounded-[24px] font-bold">お問い合わせ</span>
      </div>
    </a>
  </div>
  </>;
}

// help-row
interface Props {
  category: HelpCategory;
  details: HelpDetail[];
}

const HelpRow: FC<Props> = ({ category, details }) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const items = details.filter((e) => e.categoryId === category.categoryId);

  return <>
    <div className="border-bottom py-2 justify-between px-4 flex" onClick={(e) => setDetailVisible(!detailVisible) }>
      <span>{category.categoryName}</span>
      <img src="/images/direction_right.svg"></img>
    </div>
    <div>
      { detailVisible && items.map((e) => 
        <HelpDetailRow detail={e}></HelpDetailRow>
      )}
    </div>
  </>;
}


// help-row
interface DetailProps {
  detail: HelpDetail;
}

const HelpDetailRow: FC<DetailProps> = ({ detail }) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [body, setBody] = useState<string | undefined>(undefined);

  async function onSelected() {
    setDetailVisible(!detailVisible);
    if(body) {
      return;
    }
    const param = { detailId : detail.helpDetailId };
    const response = await fetchAsync<HelpDetail>("Help/Detail", param);
    setBody(response.body);
  }

  return <>
    <div onClick={() => onSelected()} className="border-bottom py-2 justify-between px-4 flex">
      <span className="ml-2 color-main">{detail.title}</span>
      <img src="/images/direction_right.svg"></img>
    </div>
    <div>
      { (detailVisible && body) &&
        <div className="text-sm px-6 py-2 whitespace-pre-line border-bottom">
          {body}
        </div>
      }
    </div>
  </>;
}
