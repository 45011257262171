import { FC } from "react";

export interface PageHeaderProps {
  title: string
}

export const PageHeader: FC<PageHeaderProps> = ( { title }) => {
  function pageBack(){
    window.history.back();
  }

  return <>
    <div id="header" className="py-2 flex items-center justify-center bg-[#fff] w-full max-w-[450px] relative">
      <img onClick={pageBack} src="/images/icon/icons8-back.svg" className="left-[24px] h-[24px] absolute" />
      <span className="font-bold text-lg py-2 h-[40px]">{title}</span>
    </div>
  </>
}