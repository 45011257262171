import { FC, useEffect, useRef, useState } from "react";
import { AccountInfo } from "../../model/AccountInfo";
import { fetchAsync, postAsync, postFileAsync } from "../../services/ApiHandler";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { PageHeader } from "../../ui_parts/PageHeader";
import { OptionItem } from "../../model/OptionItem";
import { OptionSelector } from "../../ui_parts/OptionSelector";
import { IsNullOrEmpty } from "../../services/StringUtil";
import { Prefecture } from "../../model/Prefecture";
import { TextEditor } from "../../ui_parts/TextEditor";
import { SelfIntroduction } from "../../model/SelfIntroduction";
import { getAccountId } from "../../services/CookieHandler";
import { ImageSelector } from "../../ui_parts/ImageSelector";
import { ProfileImagePath } from "../../model/ProfileImagePath";
import toast, { Toaster } from "react-hot-toast";
import { base64ToFile } from "../../services/ImageService";

export interface EditPrpfileRowProps {
  name: string;
  value: string | undefined;
  options: OptionItem[];
  propName: string;
  onUpdate?: (value: string) => void;
  afterUpdate: () => void;
}

export const EditPrpfileRow: FC<EditPrpfileRowProps> = ({ name, value, options, propName, onUpdate, afterUpdate }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const displayValue = IsNullOrEmpty(value) ? "未設定" : OptionItem.getOptionName(updatedValue, options);

  async function updateValue(propName: string, value: string) {
    if(onUpdate) {
      onUpdate(value);
      return;
    }

    if(IsNullOrEmpty(value)) {
      const param = { PropertyName: propName };
      await postAsync("Profile/ClearProfile", param);
    } else {
      var param = {} as any;
      param[propName] = value;
      await postAsync("Profile/UpdateProfile", param);
    }
    afterUpdate();
  }

  function updateOptionValue(value: string) {
    setUpdatedValue(value);
    updateValue(propName, value);
    setSelectorVisible(false);
  }
  
  return <>
    { selectorVisible &&
    <OptionSelector title={name} value={value ?? ""} optionItems={options} onSelected={(e) => updateOptionValue(e)} onCancel={() => setSelectorVisible(false)} ></OptionSelector>
    }
    <div onClick={() => setSelectorVisible(true)} className="flex justify-between w-full pb-2 pt-2 px-1 border-bottom">
      <span className="color-dim">{name}</span>
      <span className="color-main">{displayValue}</span>
    </div>
  </>
}

export interface TextRowProps {
  name: string;
  value: string | undefined;
  isSingle: boolean;
  propName: string;
  onUpdate: (value: string) => void;
}

export const TextRow: FC<TextRowProps> = ({ name, value, isSingle, propName, onUpdate }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const displayValue = IsNullOrEmpty(value) ? "未設定" : value;

  async function updateValue(propName: string, value: string) {
    if(IsNullOrEmpty(value)) {
      const param = { PropertyName: propName };
      await postAsync("Profile/ClearProfile", param);
      onUpdate(value);
    } else {
      var param = {} as any;
      param[propName] = value;
      await postAsync("Profile/UpdateProfile", param);
      onUpdate(value);
    }
  }

  function updateOptionValue(value: string) {
    setUpdatedValue(value);
    updateValue(propName, value);
    setSelectorVisible(false);
  }
  
  return <>
    {
    <TextEditor onCancel={() => setSelectorVisible(false)} visible={selectorVisible} title={name} value={value ?? ""} isSingleLine={isSingle} onUpdate={(e) => updateOptionValue(e)} ></TextEditor>
    }
    <div onClick={() => setSelectorVisible(true)} className="flex justify-between w-full pb-2 pt-2 px-1 border-bottom">
      <span className="color-dim">{name}</span>
      <span className="color-main">{displayValue}</span>
    </div>
  </>
}

export interface MultiTextRowProps {
  name: string;
  value: string | undefined;
  isSingle: boolean;
  onUpdate: (value: string) => void;
}

export const MultiTextRow: FC<MultiTextRowProps> = ({ name, value, isSingle, onUpdate }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const displayValue = IsNullOrEmpty(value) ? "未設定" : value;

  function updateValue(value: string) {
    setUpdatedValue(value);
    setSelectorVisible(false);
    onUpdate(value);
  }
  
  return <>
    <TextEditor onCancel={() => setSelectorVisible(false)} title={name} value={value ?? ""} isSingleLine={isSingle} onUpdate={(e) => updateValue(e)} visible={selectorVisible} ></TextEditor>
    <div onClick={() => setSelectorVisible(true)} className="flex items-center justify-between w-full pb-2 pt-2 px-1 pr-2 border-bottom">
      <span className="text-sm pr-4" style={{ overflow: "hidden", whiteSpace: "pre", textOverflow: "ellipsis" }}>{displayValue}</span>
      <span className=""><img src="/images/direction_right.svg" /></span>
    </div>
  </>
}


export const EditProfilePage: FC = () => {
  const [account, setAccount] = useState(undefined as AccountInfo | undefined);
  const [version, setVersion] = useState(0);
  const [selfIntroduction, setSelfIntroduction] = useState("");
  const [imageSelectorVisible, setImageSelectorVisible] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(undefined as string | undefined);
  const [isPrimaryImage, setIsPrimaryImage] = useState(undefined as boolean | undefined);

  async function fetchAccountInfo() {
    var response = await fetchAsync<AccountInfo>("Account/AccountInfo", null);
    setAccount(response);
  }

  async function fetchSelfIntroduction() {
    const param = { accountId: getAccountId() };
    var response = await fetchAsync<SelfIntroduction>("SelfIntroduction/Introduction", param);
    setSelfIntroduction(response.body ?? "");
  }

  async function updateShotMessage(value: string) {
    const param = { message: value };
    await postAsync("SelfIntroduction/UpdateShortMessage", param);
    setVersion(version + 1);
  }

  async function updateSelfIntroduction(value: string) {
    const param = { introduction: value };
    await postAsync("SelfIntroduction/UpdateIntroduction", param);
    setVersion(version + 1);
  }

  async function deleteImage(imageId: string){
    setIsPrimaryImage(undefined);
    const param = { imageId: imageId };
    await postAsync("Account/DeleteImage", param);
    fetchAccountInfo();
  }

  async function uploadProfileImage(imageData: string){
    const params = new FormData();
    var file = base64ToFile(imageData, "filename");
    
    params.append("file", file!);
    if(selectedImageId) {
      params.append("imageId", selectedImageId);
    }

    setImageSelectorVisible(false);
    const url = "Account/UploadProfileImage";
    const response = await postFileAsync(url, params);
    fetchAccountInfo();
  }

  function showImageOperationDialog(image: ProfileImagePath | undefined, isPrimary: boolean) {
    setIsPrimaryImage(isPrimary);
    setSelectedImageId(image?.imageId);
  }

  function showImageSelector(){
    setImageSelectorVisible(true);
    setIsPrimaryImage(undefined);
  }

  function hideImageSelector(){
    setImageSelectorVisible(false);
    setIsPrimaryImage(undefined);
  }

  async function updateDatePrice(price: string){
    const param = { price: price };
    var response = await postAsync("Reward/UpdateReward", param);
    if(response.code !== 1) {
      toast.error(response.message ?? "エラーが発生しました");
    }
    fetchAccountInfo();
  }

  useEffect(() => {
    fetchSelfIntroduction();
    fetchAccountInfo();
  },[version]);

  return <>
  <Toaster />
  { account == undefined &&
    <ProgressIndicator />
  }
  { account &&
    <>
      <PageHeader title={`${account.nickname!}`}></PageHeader>
      { imageSelectorVisible &&
        <ImageSelector onCancel={() => setImageSelectorVisible(false)} onSelected={(e) => uploadProfileImage(e)} />
      }
      { isPrimaryImage !== undefined &&
        <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
          <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
            { (selectedImageId === undefined || selectedImageId === null ) ?
              <div className="py-4" onClick={() => showImageSelector()}>写真を追加する</div>
            : <div className="py-4" onClick={() => showImageSelector()}>写真を変更する</div>
            }
            { !isPrimaryImage &&
              <div className="py-4" onClick={() => deleteImage(selectedImageId!)} >削除する</div>
            }
            <div className="py-4" onClick={() => hideImageSelector()}>キャンセル</div>
          </div>
        </div>
      }
      <div className="relative" onClick={() => showImageOperationDialog(account.profileImageList![0], true) }>
        <img className="w-full" src={account.profileImageList![0].url}></img>
        <div className="absolute bottom-[12px] w-full flex justify-center">
          <span className="py-2 px-8 font-bold rounded-[24px]" style={{ backgroundColor: "white" }}>写真を変更する</span>
        </div>
      </div>
      <div className="flex mt-3" style={{ overflow: "hidden", overflowX: "scroll" }}>
        <div className="h-[60px] w-[60px] flex items-center text-sm rounded-[4px] justify-center mr-2 ml-2" style={{ border: "1px solid gray" }} >
          <span onClick={() => showImageSelector()} className="font-bold text-center w-[60px]">写真追加</span>
        </div>
        { account.profileImageList?.map((e, i) => 
          <>
            { i >= 1 &&
              <img onClick={() => showImageOperationDialog(e, false)} src={e.url} className="h-[60px] rounded-[4px] mr-2" />
            }
          </>
        )}
      </div>
      <div className="mx-4 pb-8">
        <div className="text-lg border-bottom pb-1 mt-5">今日の一言</div>
        { account?.gender === 'woman' &&
        <>
        <div className="text-lg border-bottom pb-1 mt-6">デートの1回あたりの報酬</div>
        <EditPrpfileRow onUpdate={(e) => updateDatePrice(e)} name="報酬" value={account?.datePrice?.toString()} options={OptionItem.datePriceList} propName="date" afterUpdate={fetchAccountInfo} />
        <div className="text-sm color-dim">※報酬は一度変更すると一週間変更できません。ご注意ください</div>
        </>
        }
        <MultiTextRow name="今日の一言" isSingle={true} value={account.shortMessage ?? ""} onUpdate={(e) => updateShotMessage(e)} />
        <div className="text-lg border-bottom mt-6">自己紹介</div>
        <MultiTextRow name="自己紹介" isSingle={false} value={selfIntroduction ?? ""} onUpdate={(e) => updateSelfIntroduction(e)} />
        <div className="text-lg border-bottom pb-1 mt-6">基本プロフィール</div>
        <EditPrpfileRow name="年収" value={account?.income?.toString()} options={OptionItem.incomeTypes} propName="income" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="居住地" value={account?.prefecture?.toString()} options={Prefecture.getOptionItems()} propName="prefecture" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="出身地" value={account?.birthPrefecture?.toString()} options={Prefecture.getOptionItems()} propName="birthPrefecture" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="血液型" value={account?.bloodType?.toString()} options={OptionItem.bloodTypes} propName="bloodType" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="身長" value={account?.height?.toString()} options={OptionItem.heightOptions} propName="height" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="16パーソナリティ" value={account?.personality?.toString()} options={OptionItem.personalityTypes} propName="personality" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="体型" value={account?.bodyStyle?.toString()} options={account.gender == "man" ? OptionItem.bodyStyleManTypes : OptionItem.bodyStyleAllTypes} propName="bodyStyle" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="休日" value={account?.holiday?.toString()} options={OptionItem.holidayOptions} propName="holiday" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="タバコ" value={account?.smoking?.toString()} options={OptionItem.smokingTypes} propName="smoking" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="お酒" value={account?.alcohol?.toString()} options={OptionItem.alcoholTypes} propName="alcohol" afterUpdate={fetchAccountInfo} />
        <EditPrpfileRow name="学歴" value={account?.school?.toString()} options={OptionItem.schoolTypes} propName="school" afterUpdate={fetchAccountInfo} />
        <TextRow name="学校名" value={account.schoolName ?? ""} isSingle={true} propName="schoolName" onUpdate={fetchAccountInfo}></TextRow>
      </div>
    </>
  }
  </>
}