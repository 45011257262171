
import { FC, useEffect, useState } from "react";
import { fetchAsync } from "../services/ApiHandler";
import { Bank } from "../model/Bank";

export interface BankSelectorProps {
  onSelected: (bank: Bank) => void;
  onCancel: () => void;
  visible: boolean;
}

export const BankSelector: FC<BankSelectorProps> = ({ visible, onSelected, onCancel }) => {
  const [keyWord, setKeyWord] = useState("");
  const [banks, setBanks] = useState<Bank[]>([]);
  const [mainBanks, setMainBanks] = useState<Bank[]>([]);

  async function getMainBanks() {
    const response = await fetchAsync<Bank[]>("Bank/MainBanks", null);
    setMainBanks(response);
  }

  async function searchBanks() {
    if(keyWord === '') {
      setBanks([]);
      return;
    }

    const param = { text: keyWord };
    const response = await fetchAsync<Bank[]>("Bank/SearchBank", param);
    setBanks(response);
  }

  useEffect(() => {
    getMainBanks();
  },[]);

  useEffect(() => {
    searchBanks();
  },[keyWord]);

  return <> 
  { visible &&
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
      <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
        <div className="flex w-full justify-end mt-2">
          <img onClick={onCancel} src="/images/close.svg"></img>
        </div>
        <div className="pt-2 mt-2 mb-1">
          <input type="text" className="rounded-[24px] px-3 py-2 w-full " style={{ border: "1px solid gray" }} placeholder="銀行名で検索" value={keyWord} onChange={(e) => setKeyWord(e.target.value)}></input>
        </div>
        { banks?.map((e) => 
          <div onClick={() => onSelected(e)} className="py-1 border-bottom">{e.bankName}</div>
        )}
        { mainBanks?.map((e) => 
          <div onClick={() => onSelected(e)} className="py-1 border-bottom">{e.bankName}</div>
        )}
        <div className="pb-2"></div>
      </div>
    </div>
  }
  </>
}