import React, { FC } from 'react';
import logo from './logo.svg';
import './App.css';
import { Router } from './Router';

function App() {
  return (
    <>
{/*    
    <div id="test" style={{ backgroundColor: "white", height: "12px",  position: "fixed", top: "24px",  right: "24px",  width: "12px", borderRadius: "12px" }}></div>
    <div id="test" style={{ backgroundColor: "white", height: "12px",  position: "fixed", top: "24px",  left: "24px",  width: "12px", borderRadius: "12px" }}></div>
    <div id="test" style={{ backgroundColor: "white", height: "12px",  position: "fixed", bottom: "24px",  right: "24px",  width: "12px", borderRadius: "12px" }}></div>
    <div id="test" style={{ backgroundColor: "white", height: "12px",  position: "fixed", bottom: "24px",  left: "24px",  width: "12px", borderRadius: "12px" }}></div>
*/}
     
    <div id="main-body" style={{ minHeight: "100vh", backgroundColor: "white", maxWidth: '450px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Router />
    </div>
    </>
  )
}

export default App;
