
import { FC, useEffect, useState } from "react";
import { fetchAsync, postAsync } from "../services/ApiHandler";
import { Bank } from "../model/Bank";
import { AccountInfo } from "../model/AccountInfo";
import { resolveSoa } from "dns";
import toast, { Toaster } from "react-hot-toast";
import { sleep } from "../services/SleepService";
import { DateRequest } from "../model/DateRequest";

export interface AccountMenuDialogProps {
  onClose: () => void;
  account: AccountInfo;
  visible: boolean;
  dateRequest?: DateRequest;
}

export const AccountMenuDialog: FC<AccountMenuDialogProps> = ({ account, visible, dateRequest, onClose }) => {
  const [keyWord, setKeyWord] = useState("");
  const [banks, setBanks] = useState<Bank[]>([]);
  const [mainBanks, setMainBanks] = useState<Bank[]>([]);

  async function getMainBanks() {
    const response = await fetchAsync<Bank[]>("Bank/MainBanks", null);
    setMainBanks(response);
  }

  async function searchBanks() {
    if(keyWord === '') {
      setBanks([]);
      return;
    }

    const param = { text: keyWord };
    const response = await fetchAsync<Bank[]>("Bank/SearchBank", param);
    setBanks(response);
  }

  useEffect(() => {
    getMainBanks();
  },[]);

  useEffect(() => {
    searchBanks();
  },[keyWord]);

  async function hideAccount() {
    if(!window.confirm("非表示にします。よろしいですか")) return;

    const param = { targetAccountId: account.accountId };
    const response = await postAsync("BlockAndHidden/HideAccount", param);
    if(response.code === 1) {
      toast.success("非表示にしました");
      sleep(1000);
      onClose();
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  async function blockAccount() {
    if(!window.confirm("ブロックします。よろしいですか。一度ブロックすると元に戻せなくなります。")) return;

    const param = { targetAccountId: account.accountId };
    const response = await postAsync("BlockAndHidden/BlockAccount", param);
    if(response.code === 1) {
      toast.success("ブロックしました");
      sleep(1000);
      onClose();
    } else {
      toast.error(response.message ?? "エラーが発生しました");
    }
  }

  return <> 
  { visible &&
    <>
    <Toaster />
    <div id="selector" className="dialog-overlay" style={{zIndex: 300}}>
      <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px', maxWidth: "80%"}}>
        <div className="flex w-full justify-end mt-2">
          <img onClick={onClose} src="/images/close.svg"></img>
        </div>
        { dateRequest !== undefined &&
        <a href={`/backout-report?id=${account.accountId}&date-request-id=${dateRequest.dateRequestId!}`}>
          <div className="py-3">ドタキャン報告</div>
        </a>
        }
        <a href={`/account-report?id=${account.accountId}`}>
          <div className="py-3">通報する</div>
        </a>
        <div className="py-3">
          <div onClick={hideAccount} >非表示にする</div>
          <div className="text-xs color-dim">※あなたから見えなくなります。</div>
        </div>
        <div className="py-3">
          <div onClick={blockAccount} >ブロックする</div>
          <div className="text-xs color-dim">※お相手からもあなたからも見えなくなります。</div>
        </div>
        <div className="pb-4"></div>
      </div>
    </div>
    </>
  }
  </>
}