import axios from 'axios';
import { getApiKey } from './CookieHandler';
import { getIsLoggedIn } from './CookieHandler';
import { redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const loginPage = '/member/sign-in';

export async function postWithoutSign<T>(url:string, param: any): Promise<ApiResponse<T>> {
  const fullPath = `${process.env.REACT_APP_API_URL}/api/${url}`;
  const response = await axios.post(fullPath, param);
  return response.data as ApiResponse<T>;
}

export async function postFileAsync<T>(url:string, param: any): Promise<ApiResponse<T>> {
  if (!getIsLoggedIn()) { redirect(loginPage); }
  const fullPath = `${process.env.REACT_APP_API_URL}/api/${url}`;
  
  const apiKey = getApiKey();
  const expires = (Math.floor(Date.now() / 1000) + 10000).toString();
  const body = "";
  var signature = generateSignature("POST", `/api/${url}`, expires, body, apiKey!.secret!, null);
  
  let headers = {
    'api-key': apiKey.key,
    'api-expires': expires,
    'api-signature': signature,
    'Content-Type': 'multipart/form-data'
 };

  const response = await axios.post(fullPath, param, { headers: headers });
  return response.data as ApiResponse<T>;
}

export async function postAsync<T>(url:string, param: any): Promise<ApiResponse<T>> {
  if (!getIsLoggedIn()) { redirect(loginPage); }
  const fullPath = `${process.env.REACT_APP_API_URL}/api/${url}`;
  
  const apiKey = getApiKey();
  const expires = (Math.floor(Date.now() / 1000) + 10000).toString();
  const body = param === null ? '' : JSON.stringify(param);
  var signature = generateSignature("POST", `/api/${url}`, expires, body, apiKey!.secret!, null);
  
  let headers = {
    'api-key': apiKey.key,
    'api-expires': expires,
    'api-signature': signature,
 };

  const response = await axios.post(fullPath, param, { headers: headers });
  return response.data as ApiResponse<T>;
}

export async function fetchAsync<T>(url:string, params?: any | undefined): Promise<T> {
  if (!getIsLoggedIn()) { redirect(loginPage); }

  const apiKey = getApiKey();
  const expires = (Math.floor(Date.now() / 1000) + 10000).toString();
  var signature = generateSignature("GET", `/api/${url}`, expires, "", apiKey!.secret!, params);
  const fullPath = `${process.env.REACT_APP_API_URL}/api/${url}`;
  let headers = {
     'api-key': apiKey.key,
     'api-expires': expires,
     'api-signature': signature,
  };
  const response = await axios.get(fullPath, { params: params, headers: headers });
  return response.data as T;
}

const encoder = new TextEncoder();

function generateSignature(method: string, url: string, expireString: string, body: string, key: string, param: any) : string {
  var queryParam = "";
  if(param != null) {
    for (const prop in param) {
      var encoded = encodeURI(param[prop]);
      if (queryParam == "") {
        queryParam += "?";
        queryParam += `${prop}=${encoded}`;
      } else {
        queryParam += `&${prop}=${encoded}`;
      }
    }
  }

  var targetString = method + url + queryParam + expireString + body;
  return makeSecretHash(targetString, key).toString();
}

function makeSecretHash(data:string, client_secret: string) {
  return CryptoJS.HmacSHA256(data, client_secret);
}

function bufferToBase64(buf: Uint8Array) : string {
  const data = Array.from(buf);
  var binstr = data.map(b => String.fromCharCode(b)).join("");
  return btoa(binstr);
}

export class ApiResponse<T> {
    code: number | undefined;
    message: string | undefined;
    data: T | undefined;
    isOk() : boolean { return this.code === 1; }
}
