import dayjs, { Dayjs } from 'dayjs';
import { FC, useState, useEffect } from "react";
import { AccountWithChatRoom } from "../../model/ChatRoomSummary";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import 'dayjs/locale/ja';
import { Colors } from '../../Colors';
import toast, { Toaster } from "react-hot-toast";
import { fetchAsync, postAsync } from '../../services/ApiHandler';
import { OptionSelector } from '../../ui_parts/OptionSelector';
import { OptionItem } from '../../model/OptionItem';
import { DatePrice } from '../../model/DatePrice';
import { AccountInfo } from '../../model/AccountInfo';

export interface NewDateRequestDialogProps {
  targetAccount: AccountInfo;
  chatRoomId: string;
  onBack: (isRequestSent: boolean) => void;
  rejectindDateRequestId: string | undefined;
}

export const NewDateRequestPage: FC<NewDateRequestDialogProps> = ({ targetAccount, chatRoomId, onBack, rejectindDateRequestId }) => {
  const [processing, setProcessing] = useState(false);
  const [dayOfDate, setDayOfDate] = useState(null as Dayjs | null);
  const [dateStartTime, setDateStartTime] = useState('' as string);
  const [dateStartMinutes, setDateStartMinutes] = useState('' as string);
  const [location, setLocation] = useState('' as string);
  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
  const [minutesSelectorVisible, setminutesSelectorVisible] = useState(false);

  const [datePrice, setDatePrice] = useState(undefined as DatePrice | undefined);
  const isChange = rejectindDateRequestId !== '';
  var amIMan = targetAccount.gender === 'woman';

  useEffect(() => {
    if(amIMan) {
      fetchPrice();
    }
  }, []);

  async function fetchPrice() {
    var param = { accountId: targetAccount.accountId };
    var response = await fetchAsync<DatePrice>("DateRequest/GetPrice", param);
    setDatePrice(response);
  }

  function onChangeDate(date: Dayjs | null) {
    setDayOfDate(date);
  }

  function pageBack() {
    onBack(false);
  }

  async function sendDateRequest() {
    if(processing) { return; }

    if(dateStartTime !== '' && dateStartMinutes === '') {
      toast.error("待ち合わせ時刻(分)を指定してください");
      return;
    }

    setProcessing(true);

    var body = {
      ChatRoomId: chatRoomId,
      DayOfDate: dayOfDate?.format("YYYYMMDD"),
      DateStartTime: dateStartTime === '' ? null : dateStartTime,
      DateStartMinutes: dateStartMinutes === '' ? null : dateStartMinutes,
      Location: location,
      rejectingDateRequestId: rejectindDateRequestId,
    };

    try{
      const url = isChange ? "DateRequest/UpdateRequest" : "DateRequest/SendRequest";
      const result = await postAsync(url, body);
      setProcessing(false);
      if(result.code !== 1) {
        toast.error(result.message ?? "");
      } else {
        toast.success("デートの提案をおくりました");
        onBack(true);
      }
    } catch(e) {
      console.log(e);
      setProcessing(false);
      toast.error("エラーが発生しました");
    }
  }

  function onDateSelected(date: string) {
    setTimeSelectorVisible(false);
    setDateStartTime(date);
  }

  function onMinutesSelected(date: string) {
    setminutesSelectorVisible(false);
    setDateStartMinutes(date);
  }

  return <>
  <Toaster></Toaster>
  <div id="offerPage" className="dialog-overlay">
    <div className='dialog'>
      <div id="header" className="flex justify-between py-1 top-0 items-center bg-[#fff] w-full max-w-[450px]" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <span className="ml-2">デートの提案{isChange ? ' (変更)' : '' }</span>
        <img onClick={pageBack} src="/images/close-gray.svg" className="right-[40px] h-[24px]" />
      </div>
      <div className='pt-4 bg-white px-1'>
        <div>
          <span className='rounded-[2px] px-2 mr-1' style={{ border: '1px solid red', color: "red" }}>必須</span>
          <span>日付</span>
        </div>
        <div className='mt-2'>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <MobileDatePicker className='w-full px-0' format='YYYY/MM/DD' formatDensity='spacious' onAccept={(e) => onChangeDate(e)} />
          </LocalizationProvider>
        </div>
        <div className='mt-4'>
          <span className='rounded-[2px] px-2 mr-1' style={{ border: '1px solid #1C9175', color: '#1C9175' }}>任意</span>
          <span>待ち合わせ時刻</span>
        </div>
        <div className='mt-2 flex'>
          { timeSelectorVisible && 
          <OptionSelector title='時刻' value={dateStartTime} optionItems={OptionItem.timeOptions} onSelected={(e) => onDateSelected(e)} onCancel={() => setTimeSelectorVisible(false)}  ></OptionSelector>
          }
          <input readOnly value={OptionItem.getOptionName(dateStartTime, OptionItem.timeOptions)} onClick={(e) => setTimeSelectorVisible(true)} className='w-full py-2 px-2 rounded-[3px] mr-4' style={{ border: '1px solid #c4c4c4' }} placeholder='時'></input>


          { minutesSelectorVisible && 
          <OptionSelector title='時刻(分)' value={dateStartMinutes} optionItems={OptionItem.minutesOptions} onSelected={(e) => onMinutesSelected(e)} onCancel={() => setminutesSelectorVisible(false)}  ></OptionSelector>
          }
          <input readOnly value={OptionItem.getOptionName(dateStartMinutes, OptionItem.minutesOptions)} className='w-full py-2 px-2 rounded-[3px]' onClick={(e) => setminutesSelectorVisible(true)} style={{ border: '1px solid #c4c4c4' }} placeholder='分'></input>
        </div>
        <div className='mt-4'>
          <span className='rounded-[2px] px-2 mr-1' style={{ border: '1px solid #1C9175', color: '#1C9175' }}>任意</span>
          <span>待ち合わせ場所</span>
        </div>
        <div className='mt-2 flex'>
          <input value={location} onChange={(e) => setLocation(e.target.value)} className='w-full py-2 px-2 rounded-[3px]' style={{ border: '1px solid #c4c4c4' }} placeholder='〇〇駅 など'></input>
        </div>
        { amIMan &&
        <div className='mt-4'>
          <div className='flex justify-between'>
            <span>必要ポイント</span>
            <div>
              <span className='text-lg mr-1'>{datePrice?.point}</span>
              <span className='text-sm color-dim'>P</span>
            </div>
          </div>
          <div className='text-sm color-dim text-right'>税込: {datePrice?.withTaxPrice} 円</div>
          <div className='text-sm color-dim text-right'>※1ポイント = 1.2 円 で換算されます</div>
        </div>
        }
        <button disabled={processing} onClick={sendDateRequest} className='bg-main text-white font-bold w-full text-center rounded-[24px] py-2 mt-4 hover-button'>
          デートの提案を送る
        </button>
        { amIMan &&
        <div className='mt-4 text-sm px-2 py-2 rounded-[2px]' style={{ backgroundColor: Colors.WarningArea, color: Colors.WarningText }}>
          お相手が承諾するまで決済は行われません。<br/>
          お相手の方がデートの提案を承諾した時点でご登録頂いている決済手段にて決済が行われます。
        </div>
        }
      </div>
    </div>
  </div>
</>
}