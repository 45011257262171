import dayjs, { Dayjs } from 'dayjs';
import { FC, useState, useEffect } from "react";
import 'dayjs/locale/ja';
import { Colors } from '../../Colors';
import toast, { Toaster } from "react-hot-toast";
import { fetchAsync, postAsync } from '../../services/ApiHandler';
import { DateRequest } from '../../model/DateRequest';
import { getWeekString } from '../../services/DateUtil';
import { DatePoint } from '../../model/DatePoint';
import { ProgressIndicator } from '../../ui_parts/ProgressIndicator';
import { AccountInfo } from '../../model/AccountInfo';
import { TranResponse } from '../../model/TranResponse';
import { TranStatus } from '../../model/TranStatus';

export interface AcceptDateRequestManDialogProps {
  targetAccount: AccountInfo;
  request: DateRequest;
  onBack: (isAccepted: boolean) => void;
}

export const AcceptDateRequestManPage: FC<AcceptDateRequestManDialogProps> = ({ targetAccount, request, onBack }) => {
  const [datePoint, setDatePoint] = useState(undefined as DatePoint | undefined);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    fetchPoint();
  }, []);

  async function fetchPoint() {
    var param = { accountId: targetAccount.accountId };
    var response = await fetchAsync<DatePoint>("DatePoint/GetPoint", param);
    setDatePoint(response);
  }

  async function acceptdDateRequest() {
    if(processing) { return; }
   
    setProcessing(true);
    
    var body = {
      dateRequestId: request.dateRequestId,
      deltaPoint: getShortagePoint()
    };

    try{
      const url = "DateRequest/PurchaseAndAccept";
      const response = await postAsync<TranResponse>(url, body);

      if(response.code === 1) {
        // 購入完了通知は非同期で行われるためポーリングする
        const tranParam = { tranId: response.data!.tranId };
        for(var i = 0; i < 15; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));

          var tranResponse = await fetchAsync<TranStatus>("subscription/GetTranStatus", tranParam);
          if(tranResponse.status === 'complete') {
            toast.success("確定しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            onBack(true);
            return;
          }

          if(tranResponse.status === 'failed') {
            toast.error("購入に失敗しました");
            await new Promise(resolve => setTimeout(resolve, 2000));
            setProcessing(false);
            onBack(true);
            break;
          }
        }
        setProcessing(false);
        
      } else {
        setProcessing(false);
        toast.error(response.message ?? 'エラーが発生しました');
      }
    } catch(e) {
      console.log(e);
      setProcessing(false);
      toast.error("エラーが発生しました");
    }
  }

  function pageBack() {
    onBack(false);
  }

  function getShortagePoint() : number {
    const diff = request.datePoint! - datePoint!.point!
    return Math.max(0, diff);
  }

  const timeText = request.dateStartTime !== null ? `${request.dateStartTime}:${request.dateStartMinutes}` : ``;
  const dayOfdate = dayjs(request.dayOfDate);
  const dayText = dayOfdate.format("M/D");
  const dayString = `${dayText}(${getWeekString(dayOfdate)}) ${timeText}`;


  return <>
  <Toaster></Toaster>
  { datePoint === undefined &&
    <ProgressIndicator></ProgressIndicator>
  }
  { processing &&
    <div id="offerAcceptDialog" className="dialog-overlay">
      <div className='dialog'>
        <div className="text-center mb-6">
          <div>処理中です</div>
          <div>しばらくおまちください...</div>
          <div className='color-dim text-sm'>この処理は30秒ほどかかることがあります</div>
          <ProgressIndicator></ProgressIndicator>
        </div>
      </div>
    </div>
  }
  { (processing === false && datePoint !== undefined) &&
  <div id="offerPage" className="dialog-overlay">
    <div className='dialog'>
      <div id="header" className="flex justify-between pt-1 pb-2 top-0 items-center bg-[#fff] w-full max-w-[450px]" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <span className="ml-2">デートの承諾</span>
        <img onClick={pageBack} src="/images/close-gray.svg" className="right-[40px] h-[24px]" />
      </div>
      <div className='pt-4 bg-white px-1'>
        <div className='flex justify-between'>
          <span>日時</span>
          <span>{ dayString }</span>
        </div>
        { request.location !== null && request.location !== '' &&
          <div className='flex justify-between mt-2'>
            <span>待ち合わせ場所</span>
            <span>{request.location}</span>
          </div>
        }
        <div className='mt-2'>
          <div className='flex justify-between items-end'>
            <span>ポイント</span>
            <div>
              <span className='text-lg mr-1'>{request.datePoint}</span>
              <span className='text-sm color-dim'>P</span>
            </div>
          </div>
        </div>
        <div className='mt-2'>
          <div className='flex justify-between items-end'>
            <span>所有ポイント</span>
            <div>
              <span className='text-lg mr-1'>{datePoint?.point}</span>
              <span className='text-sm color-dim'>P</span>
            </div>
          </div>
        </div>
        <div className='mt-2'>
          <div className='flex justify-between items-end'>
            <span>不足ポイント</span>
            <div>
              <span className='text-lg mr-1'>{getShortagePoint()}</span>
              <span className='text-sm color-dim'>P</span>
            </div>
          </div>
          <div className='text-sm color-dim text-right'>税込: {getShortagePoint() * 1.2} 円</div>
          <div className='text-sm color-dim text-right'>※1ポイント = 1.2 円 で換算されます</div>
        </div>
        <div onClick={acceptdDateRequest} className='bg-main text-white font-bold w-full text-center rounded-[24px] py-2 mt-4'>
          デートを確定する
        </div>
        <div className='mt-4 text-sm px-2 py-2 rounded-[2px]' style={{ backgroundColor: Colors.WarningArea, color: Colors.WarningText }}>
デート予定日の2日前の0時以降のお客様都合によるキャンセルが発生した場合、ポイントは消費されます。<br/>
また、お客様がデートに無断で行かなかった場合もポイントは消費されます。<br/>
<br/>
本サービスで販売する商品・サービスについては、当社が別途定める場合を除き、購入手続き完了後の返品又はキャンセルをお受けいたしません。<br/>
なお、サービスに欠陥・不良がある場合は、利用規約の定めに従って対応します。<br/>
        </div>
      </div>
    </div>
  </div>
  }
</>
}