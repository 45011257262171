
import { FC, useState } from "react";
import { Colors } from "../Colors";

export interface TextEditorProps {
  title: string;
  value: string;
  isSingleLine: boolean;
  isNumber?:boolean;
  onUpdate: (value: string) => void;
  onCancel: () => void;
  visible: boolean;
}

export const TextEditor: FC<TextEditorProps> = ({ title, value, isSingleLine, isNumber, onUpdate, visible, onCancel }) => {
  const [newValue, setNewValue] = useState(value as string);

  return <> 
    { visible &&
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
      <div className='dialog bg-white overflow-auto max-h-[450px] min-w-[200px]' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
        <div className="sticky top-0 justify-between bg-white pt-4">
          <span className="text-lg">{title}</span>
        </div>
        { isSingleLine &&
          <input onChange={(e) => setNewValue(e.target.value)} className="text-sm w-full py-2 px-2 mt-2" style={{ borderBottom: `1px solid ${Colors.Main}` }} type={ isNumber ? "number" : "text"} value={newValue}></input>
        }
        { !isSingleLine &&
          <textarea onChange={(e) => setNewValue(e.target.value)} placeholder="" className="text-sm w-full py-2 px-2 mt-2 border-all h-[200px]" style={{resize: "none"}} value={newValue}></textarea>
        }
        <div className="mt-2"></div>
        <div className="sticky flex bottom-0 justify-end bg-white pb-4 items-center">
          <span onClick={() => onCancel()} className="color-dim text-sm">キャンセル</span>
          <span onClick={(e) => onUpdate(newValue)} className="ml-8">決定</span>
        </div>
      </div>
    </div>
    }
  </>
}