import { Duration } from "@mui/material";
import React, { useState, createRef, useEffect } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";

export interface ImageSelectorProps {
  onSelected: (value: string) => void;
  onCancel: () => void;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({ onSelected, onCancel }) => {
  const [image, setImage] = useState(undefined as string | undefined);
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef<ReactCropperElement>();
  
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      // setCropData();
      onSelected(data);
    }
  };

  function selectImageFile() {
    window.document.getElementById("image-input")?.click();
  }

  return (
    <div id="selector" className="dialog-overlay" style={{zIndex: 200}}>
      <div className='dialog bg-white overflow-auto h-[90vh] max-h-[90vh] min-w-[200px] border-all text-center' style={{ paddingTop: '0px', paddingBottom: '0px'}}>
        <div>
          <div className="mt-2 flex justify-end w-rull mr-1">
            <img onClick={onCancel} src="/images/close.svg" ></img>
          </div>
          <div style={{ width: "100%" }} className="flex justify-center mt-2">
            
            <input id="image-input" type="file" onChange={onChange} accept=".jpg, .jpeg, .png, .heic" style={{ display: "none" }} />
            { image === undefined ?
              <div  onClick={(e) => selectImageFile()} className="rounded-[8px] border-all flex justify-center items-center" style={{ height:"300px", width: "300px" }}>
                <span>画像を選択</span>
              </div>
            :
            <>
            <div>
              <Cropper
                aspectRatio={1}
                ref={cropperRef}
                style={{ height:"70vh", width: "100%" }}
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={true}
                responsive={true}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
                // zoomTo={0.2}
              />
              <div onClick={getCropData} className="mt-4 bg-main text-white rounded-[24px] py-1 font-bold">確定</div>
            </div>
            </>
            }
          </div>
        </div>
        
      </div>
    </div>
  );
};
