import { FC, useEffect, useState } from "react";
import { Subscription } from "../../model/Subscription";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { PageHeader } from "../../ui_parts/PageHeader";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import dayjs from "dayjs";

export const SubscriptionStatusPage: FC = () => {
  const [processing, setProcessing] = useState(false);
  const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);

  async function fetchSubscription() {
    const response = await fetchAsync<Subscription>("subscription/GetActiveSubscription", null);
    setSubscription(response);
  }

  useEffect(() => {
    fetchSubscription();
  },[]);

  async function deactivate() {
    setProcessing(true);
    await postAsync("subscription/StopRenewal", null);
    fetchSubscription();
    setProcessing(false);
  }

  async function activate() {
    setProcessing(true);
    await postAsync("subscription/Activate", null);
    fetchSubscription();
    setProcessing(false);
  }

  function formatDate(dateString: string){
    const date = dayjs(dateString);
    return date.format("YYYY/M/D");
  }

  return <>
    <PageHeader title="サブスクリプション"></PageHeader>
    { subscription === undefined &&
      <ProgressIndicator />
    }
    { (subscription !== undefined && subscription?.isActive === undefined) && 
      <div className="mt-8 text-center">
        サブスクリプション未加入
      </div>
    }
    { (subscription !== undefined && subscription?.isActive !== undefined) && 
      <div className="mt-8">
        <div className="px-4">
          <div className="border-bottom"></div>
          <div className="flex justify-between py-2 border-bottom">
            <div className="color-dim">開始日</div>
            <div>{formatDate(subscription.startDate!)}</div>
          </div>
          <div className="flex justify-between py-2 border-bottom">
            <div className="color-dim">終了日</div>
            <div>{formatDate(subscription.endDate!)}</div>
          </div>
          <div className="flex justify-between py-2 border-bottom">
            <div className="color-dim">自動更新</div>
            <div>{subscription.isActive === 1 ? "有効" : "無効"}</div>
          </div>
          { subscription.isActive === 1 &&
            <div className="text-center mt-6">
              <button className="color-dim text-sm hover-button" disabled={processing} onClick={deactivate}>自動更新を無効化する</button>
            </div>
          }
          { subscription.isActive === 0 &&
            <div className="text-center mt-6 flex justify-center">
              <button className="button hover-button" disabled={processing} onClick={activate}>自動更新を有効化する</button>
            </div>
          }
        </div>
      </div>
    }
  </>
}