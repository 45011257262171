import { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { fetchAsync, postWithoutSign } from "../services/ApiHandler";
import { LineAuthResponse } from "../model/LineAuthResult";
import { saveApiKey, setIsProfileComplete } from "../services/CookieHandler";
import { AccountInfo } from "../model/AccountInfo";
import { GoogleOAuthProvider, useGoogleLogin, } from "@react-oauth/google";
import Cookies from "js-cookie";

export const SignInPage: FC = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const lineAuthCode = query.get('code');

  const lineRedirectUrl = `${window.location.origin}/line-auth`;

  function saveUtmToCookie(){
    Cookies.set('utm_source',  query.get('utm_source') ?? "");
    Cookies.set('utm_campaign',  query.get('utm_campaign') ?? "");
    Cookies.set('utm_medium',  query.get('utm_medium') ?? "");
    Cookies.set('utm_term',  query.get('utm_term') ?? "");
    Cookies.set('utm_content',  query.get('utm_content') ?? "");
  }

  useEffect(() => {
    saveUtmToCookie();
  },[]);

  function openLineAuthPage() {

    const baseUrl = "https://access.line.me/oauth2/v2.1/authorize?prompt=consent&response_type=code&client_id=2004487793&scope=openid email profile&state=12345abcde&nonce=09876xyz&bot_prompt=normal";
    var url = `${baseUrl}&redirect_uri=${lineRedirectUrl}`;
    window.location.href = url;
  }

  return <>
  <div className="flex flex-col items-center">
  <div className="h-[160px]"></div>
    <img src="/images/logo-side.png" className="mt-8 w-[60%]"></img>
    <div className="h-[160px]"></div>
    <div className="text-sm">
      認証をしても利用状況は公開されません。
    </div>
    <div className="login-button mt-3" onClick={openLineAuthPage}>
      <img src="/images/icon/icon-line.png" className="w-[20px] h-[20px]"></img>
      <span className="ml-2">Lineでサインアップ</span>
    </div>
    <div className="color-main mt-4 text-sm font-bold" style={{ maxWidth: "320px" }}>
      サインアップ時に画面上に表示される「友だち追加」にチェックをいれるとスムーズに登録できます。
    </div>
    <div className="mt-4 text-xs" style={{ maxWidth: "320px" }}>
      サインアップをタップすることで、<a className="underline" href="https://rapid-date.com/terms.html">利用規約</a>・<a className="underline" href="https://rapid-date.com/privacy-policy.html">プライバシーポリシー</a>へ同意したこととなります。
    </div>

  </div>
  </>

}