export class Colors {
  static readonly Main: string = '#0ABAB5';
  static readonly TextBlack: string = '#25405A';
  static readonly Dim: string = '#7C8C9C';
  static readonly Border: string = '#e3e3e3';
  static readonly White: string = '#ffffff';
  static readonly WarningArea: string = '#FEF6F7';
  static readonly WarningText: string = '#EF4F57';
  
}
