import { FC, useEffect, useState } from "react";
import { Colors } from "../../Colors";
import { fetchAsync } from "../../services/ApiHandler";
import { AccountInfo } from "../../model/AccountInfo";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { ProfilePage } from "../ProfilePage";
import { SelfIntroduction } from "../../model/SelfIntroduction";
import { Prefecture } from "../../model/Prefecture";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { tab } from "@testing-library/user-event/dist/tab";
import { GoodListResponse } from "../../model/GoodListResponse";
import { GoodWithAccount } from "../../model/GoodWithAccount";
import { NoItemArea } from "../../ui_parts/NoItemArea";

export const GoodFromMeTab: FC = () => {
  const [accountList, setAccountList] = useState(null as GoodWithAccount[] | null);
  
  useEffect(() => {
    fetchGoodList();
  },[]);

  async function fetchGoodList() {
    var param = {
      isFromMe: 1,
      isToMe: 0,
    }
    var response = await fetchAsync<GoodListResponse>("Good/GoodList", param);
    setAccountList(response.goodListFromMe!);
  }


  function selectAccount(selectedAccount: AccountInfo) {
    //　dialogを出してる間はスクロールを切る
    document.body.style.overflow = 'hidden'; 
    window.location.href = `/profile?id=${selectedAccount.accountId}`
  }
  
  return <>
    <div className="flex flex-wrap w-full px-2 justify-between box-border" style={{ }}>
    { accountList === null &&
      <ProgressIndicator />
    }
    { accountList?.length === 0 &&
        <NoItemArea title="送ったいいねはありません" description="いいねを送ってアプローチしてみましょう！" linkUrl="/home" linkText="ホーム画面を開く" />
    }
    { accountList !== null && accountList.map((e) => (
      <div onClick={() => selectAccount(e.account!)} style={{ padding: "4px", boxSizing: "border-box", flex: '0 0 50%'  }}>
        <img src={e.account!.profileImageList![0].url} className="box-border" style={{ borderRadius: "12px" }}></img>
        <div className="flex mt-1 text-xs">
          <span className="mr-2">{ Prefecture.getPrefectureName(e.account!.prefecture) }</span>
          <span>{e.account!.age}</span>
        </div>
      </div>
    ))}
    </div>
    <div className="h-[100px]"></div>
  </>
}