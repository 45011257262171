import { FC, useEffect, useState } from "react";
import { fetchAsync } from "../../services/ApiHandler";
import { BankAccount } from "../../model/BankAccount";
import { PageHeader } from "../../ui_parts/PageHeader";
import { AccountInfo } from "../../model/AccountInfo";
import { ProgressIndicator } from "../../ui_parts/ProgressIndicator";
import { ActivePointStatus } from "../../model/ActivePointStatus";

export const PaymentRequestPage: FC = () => {
  const [bankAccount, setBankAccount] = useState(undefined as BankAccount | undefined);
  const [pointStatus, setPointStatus] = useState<ActivePointStatus | undefined>(undefined);

  async function fetchBankAccount() {
    var response = await fetchAsync<BankAccount>("BankAccount/BankAccount", null);
    setBankAccount(response);
  }

  async function fetchActivePoint() {
    const response = await fetchAsync<ActivePointStatus>("PaymentRequest/ActivePoint", null);
    setPointStatus(response);
  }

  useEffect(() => {
    fetchBankAccount();
    fetchActivePoint();

    const handlePopState = () => {
      console.log('browser back');
      fetchBankAccount();
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  },[]);

  return <>
  <PageHeader title="振込申請"></PageHeader>
    { (bankAccount === undefined || pointStatus === undefined) ?
      <ProgressIndicator></ProgressIndicator> : 
      <>
      <div className="text-center">
        <div className="">所有ポイント</div>
        <div className="text-xl font-bold mt-2">{pointStatus.activeMoney}P</div>
        { pointStatus?.hasBankAccount &&
          <a href="/bankout" className="mb-4">
            <div className="py-2 rounded-[40px] bg-main mx-4 text-white text-lg font-bold mt-4">振込申請</div>
          </a>
        }
        <a href="/bank-account">
          <div className="py-2 rounded-[40px] bg-main mx-4 text-white text-lg font-bold mt-4">口座情報登録</div>
        </a>
      </div>

      </>
    }
    
    
  </>;
}