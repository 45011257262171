import { FC, useState } from "react";
import { Colors } from "../../Colors";
import { SearchInnnerTab } from "./SearchInnerTab";
import { ProfilePage } from "../ProfilePage";
import { AccountInfo } from "../../model/AccountInfo";
import { InnerTab } from "../../ui_parts/Tab";

export const SearchTab: FC = () => {
  const [selectedTab, setSelectedTab] = useState('recommend');
  
  function onSelect(name: string) {
    setSelectedTab(name);
  }

  return <>
  <div className="flex fixed pt-4 box-border px-4 bg-[#fff] w-full max-w-[450px]">
    <InnerTab title="おすすめ" onClick={(e) => onSelect("recommend")} name="recommend" selectedItem={selectedTab} />
    <InnerTab title="新規ユーザー" onClick={(e) => onSelect("new")} name="new" selectedItem={selectedTab} />
  </div>
    { selectedTab === 'recommend' &&
        <>
        <div className="h-[56px]"></div>
        <SearchInnnerTab tabType="recommend"></SearchInnnerTab>
        </>
    }
    { selectedTab === 'new' &&
        <>
        <div className="h-[56px]"></div>
        <SearchInnnerTab tabType="new"></SearchInnnerTab>
        </>
    }
  </>
}