import { FC } from "react";

export const ProgressIndicator: FC = () => {
  return <>
    <div className="flex w-full justify-center pt-10">
        {/* <img src="/images/loading.gif" className="w-[32px]"></img> */}
        {/* <img src="/images/rhombus-loader.gif" className="w-[40px]"></img> */}
        <span className="loader"></span>
    </div>
  </>
}