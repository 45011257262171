import { FC, useEffect, useState } from "react";
import { AppNotification } from "../../model/AppNotification";
import { fetchAsync, postAsync } from "../../services/ApiHandler";
import { setMaxListeners } from "events";
import { PageHeader } from "../../ui_parts/PageHeader";
import dayjs from "dayjs";


interface Props {
  notification: AppNotification;
}

const NotificationRow: FC<Props> = ({notification}) => {
  const [detailVisible, setDetailVisible] = useState(false);

  function open(){
    const param = { messageId: notification.messageId };
    postAsync("Notification/Open", param);
  }

  function parseDate(date: string): string {
    const day = dayjs(date, "YYYYMMDDHHmmss");
    return day.format('YYYY/M/D HH:mm');
  }

  function toggle() {
    setDetailVisible(!detailVisible);
    if(!notification.isOpened){
      open();
    }
  }
  
  return <>
      <div className="border-bottom py-2 justify-between px-4">
        <div onClick={() => toggle()  }>
          <div onClick={() => toggle } className="mb-1">{notification.title}</div>
          <div className="color-dim text-sm">{parseDate(notification.dateTime!)}</div>
        </div>
        { detailVisible &&
        <div className="text-sm mt-4">
          { notification.body }
        </div>
        }
    </div>
  </>;
}

export const NotificationListPage: FC = () => {
  const [list, setList] = useState<AppNotification[] | undefined>(undefined);
  
  async function fetchList() {
    const response = await fetchAsync<AppNotification[]>("Notification/GetList", null);
    setList(response);
  }

  useEffect(() => {
    fetchList();
  },[]);

  function parseDate(date: string): string {
    const day = dayjs(date, "YYYYMMDDHHmmss");
    return day.format('YYYY/M/D HH:mm');
  }

  return <>
  <PageHeader title="お知らせ" />
  { list?.map((e) =>
    <NotificationRow notification={e}></NotificationRow>
    )
  }
  </>;
}