import dayjs, { Dayjs } from 'dayjs';
import { FC, useState, useEffect } from "react";
import 'dayjs/locale/ja';
import { Colors } from '../../Colors';
import { DateRequest } from '../../model/DateRequest';
import { getWeekString } from '../../services/DateUtil';
import { AccountInfo } from '../../model/AccountInfo';
import toast, { Toaster } from "react-hot-toast";
import { postAsync } from '../../services/ApiHandler';
import { useNextMonthDisabled } from '@mui/x-date-pickers/internals';
import { ProgressIndicator } from '../../ui_parts/ProgressIndicator';
import { esES } from '@mui/x-date-pickers/locales';

export interface AcceptDateRequestWomanDialogProps {
  targetAccount: AccountInfo;
  request: DateRequest;
  onBack: (isAccepted: boolean) => void;
}

export const AcceptDateRequestWomanPage: FC<AcceptDateRequestWomanDialogProps> = ({ targetAccount, request, onBack }) => {
  var isSending = false;
  const [dayOfDate, setDayOfDate] = useState(null as Dayjs | null);
  const [dateStartTime, setDateStartTime] = useState('' as string);
  const [dateStartMinutes, setDateStartMinutes] = useState('' as string);
  const [location, setLocation] = useState('' as string);
  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
  const [minutesSelectorVisible, setminutesSelectorVisible] = useState(false);
  const [processing, setProcessing] = useState(false);

  function onChangeDate(date: Dayjs | null) {
    setDayOfDate(date);
  }

  function pageBack() {
    onBack(false);
  }

  function onDateSelected(date: string) {
    setTimeSelectorVisible(false);
    setDateStartTime(date);
  }

  function onMinutesSelected(date: string) {
    setminutesSelectorVisible(false);
    setDateStartMinutes(date);
  }

 async function acceptDate() {
    if(processing){
      return;
    }
    setProcessing(true);
    const param = { DateRequestId: request.dateRequestId };
    var response = await postAsync("DateRequest/AcceptRequestForWoman", param);
    if(response.code === 1) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      toast.success("確定しました");
      onBack(true);
    } else {
      toast.error(response.message ?? "エラーが発生しました");
      setProcessing(false);
    }
  }

  const timeText = request.dateStartTime !== null ? `${request.dateStartTime}:${request.dateStartMinutes}` : ``;
  const dayOfdate = dayjs(request.dayOfDate);
  const dayText = dayOfdate.format("M/D");
  const dayString = `${dayText}(${getWeekString(dayOfdate)}) ${timeText}`;

  return <>
  <Toaster></Toaster>
  <div id="offerPage" className="dialog-overlay">
    <div className='dialog'>
      { processing &&
        <>
        <div className='text-center mb-6 mt-2'>
          <div>処理中です....</div>
          <ProgressIndicator></ProgressIndicator>
        </div>
        </>
      }
      { !processing && 
        <>
        <div id="header" className="flex justify-between pt-1 pb-2 top-0 items-center bg-[#fff] w-full max-w-[450px]" style={{ borderBottom: '1px solid #e3e3e3' }}>
          <span className="ml-2">デートの承諾</span>
          <img onClick={pageBack} src="/images/close-gray.svg" className="right-[40px] h-[24px]" />
        </div>
        <div className='pt-4 bg-white px-1'>
          <div className='flex justify-between'>
            <span>日時</span>
            <span>{ dayString }</span>
          </div>
          { request.location !== null && request.location !== '' &&
            <div className='flex justify-between mt-2'>
              <span>待ち合わせ場所</span>
              <span>{request.location}</span>
            </div>
          }
          <div onClick={acceptDate} className='bg-main text-white font-bold w-full text-center rounded-[24px] py-2 mt-4'>
            デートを承諾する
          </div>
        </div>
        </>
      }
    </div>
  </div>
</>
}